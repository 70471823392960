import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "pages/depot-dashboard/assetStockCount/tabs/Header";
import ConfirmationModal from "../../../../components/Common/modals/ConfirmationModal";
import CustomButton from "../../../../components/Common/buttons/CustomButton";
import { cancelUserAbsence, getUsersList } from "../../../../utils/api";
import { defaultNgiErrorMethod, successMessage } from "../../../../components/Common/responses/message";
import { AUTH_USER, USER_ROLES } from "../../../../utils/constants";
import SetUnavailableEmployeeModal from "./SetUnavailableEmployeeModal";
import NGITable from "../../../../components/Common/NGITable";
import { usersListColumns } from "./usersListColumns";

const useStyles = makeStyles({
  container: {},
  vehicleList: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  vehicleItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    width: "49%",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  truckNumber: {
    fontWeight: 700,
    borderRadius: 3,
    border: "2px solid #000000",
    backgroundColor: "#FFD66D",
    padding: "3px 10px",
  },
  assignedTo: {
    marginLeft: 20,
    color: "#8C8C8C",
    fontWeight: 300,
    fontSize: 13,

    "&>span": {
      color: "#000",
      fontWeight: 600,
    },
  },
  actions: {
    padding: 15,
    borderLeft: "1px solid #d5d5d5cc",
  },
  removeBtn: {
    cursor: "pointer",
  },
});

export const TeamAvailability = ({ title, desc }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeModal = useCallback(() => setIsOpen(false), []);

  const loadData = useCallback(() => {
    setIsLoading(true);
    getUsersList({
      status: "Unavailable",
      depot: AUTH_USER?.depotName,
      pageSize: 3000,
    })
      .then(({ data }) => {
        setIsLoading(false);
        setUsersList(data?.result?.items);
      })
      .catch(e => defaultNgiErrorMethod(e));
  }, []);

  const onCancel = useCallback(
    reason => {
      setIsLoading(true);
      cancelUserAbsence(selectedUser.id, "email", reason)
        .then(() => {
          setTimeout(() => {
            setIsLoading(false);
            successMessage("User leave was canceled successfully");
            closeConfirmRemove();
            loadData();
          }, 2000);
        })
        .catch(e => {
          setIsLoading(false);
          closeConfirmRemove();
          defaultNgiErrorMethod(e);
        });
    },
    [selectedUser]
  );

  const closeConfirmRemove = useCallback(() => setSelectedUser(null), []);
  const openConfirmRemove = useCallback(user => setSelectedUser(user), []);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className={classes.container}>
      <Header
        desc={desc}
        title={title}
        btn={
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            onClick={openModal}
            type="primary"
            size="small"
            text="SET LEAVE"
          />
        }
      />
      <div>
        <br />
        <NGITable
          isLoading={isLoading}
          columns={usersListColumns(openConfirmRemove)}
          noDataTitle=" "
          noDataDesc="No Unavailable Employees in the depot."
          data={{
            result: {
              items: usersList,
            },
          }}
        />

        {selectedUser && (
          <ConfirmationModal
            withComment
            isCommentRequired
            description={`Are you sure you want to cancel ${selectedUser.name || selectedUser.email} leave?`}
            onCancel={closeConfirmRemove}
            onConfirm={onCancel}
          />
        )}
      </div>

      {isOpen && <SetUnavailableEmployeeModal handleCancel={closeModal} onSuccess={loadData} />}
    </div>
  );
};

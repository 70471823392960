import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    fontSize: 18,
    fontWeight: 600,

    "&>span": {
      color: "#000000",
      fontSize: 13,
      fontWeight: 300,
    },
  },
});

export const Header = ({ title, desc, btn }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        {title}
        <span>{desc}</span>
      </div>
      {btn}
    </div>
  );
};

import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import infoRed from "assets/images/svg/InfoRed.svg";
import classnames from "classnames";
import CustomButton from "components/Common/buttons/CustomButton";
import { Row } from "antd";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { cancelUserAbsence } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { textFormatter } from "components/Common/formatter";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
    borderRadius: 5,
    border: "1px solid rgba(255, 0, 0, 0.5)",
    padding: 10,
    backgroundColor: "rgba(255, 255, 255, 1)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "600",
    fontSize: 14,
    marginBottom: 1,
  },
  section: {
    marginLeft: 10,
  },
  substitutesSection: {
    marginLeft: 10,
    marginRight: 10,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    "&> p": {
      marginBottom: 0,
      marginRight: 5,
      color: "grey",
    },
  },
  substituteItem: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    "&> p": {
      textTransform: "lowercase",
      marginBottom: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    alignItems: "center",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 1,
    "&> span": {
      fontWeight: "600",
    },
  },
  priority: {
    borderRadius: 5,
    textTransform: "uppercase",
    padding: "4px 6px 4px 6px",
    marginLeft: 10,
    color: "white",
    fontSize: 10,
    fontWeight: 700,
  },
  textCenter: {
    textAlign: "center",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  High: {
    backgroundColor: "rgba(255, 46, 0, 1)",
  },
  Medium: {
    backgroundColor: "rgba(240, 139, 57, 1)",
  },
  Low: {
    backgroundColor: "rgba(0, 133, 255, 1)",
  },
});

const LeavesBar = ({ leaves, updateUser }) => {
  const classes = useStyles();
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const activeLeave = useMemo(
    () =>
      leaves?.find(item => {
        const diff = moment(item.endDate).diff(moment(), "days");
        return diff >= 0 && item.status === "Active";
      }),
    [leaves]
  );

  const openConfirmationModal = useCallback(() => setOpened(true), []);
  const closeConfirmationModal = useCallback(() => setOpened(false), []);

  const onCancel = useCallback(
    reason => {
      setLoading(true);
      cancelUserAbsence(activeLeave.id, undefined, reason)
        .then(() => {
          setLoading(false);
          successMessage("User leave was canceled successfully");
          closeConfirmationModal();
          updateUser();
        })
        .catch(e => {
          setLoading(false);
          closeConfirmationModal();
          defaultNgiErrorMethod(e);
        });
    },
    [activeLeave]
  );

  return activeLeave ? (
    <div className={classes.root}>
      <Row>
        <img src={infoRed} width={24} alt="info" />
        <div className={classes.section}>
          <p className={classes.title}>{activeLeave.reason}</p>
          <p className={classes.subtitle}>
            <span>{`${moment(activeLeave.startDate).format("DD/MM/YYYY")}`}</span>
            {" to "}
            <span>{`${moment(activeLeave.endDate).format("DD/MM/YYYY")}`}</span>
          </p>
        </div>
      </Row>
      <div className={classes.substitutesSection}>
        <p>SUBSTITUTE(S):</p>
        {Object.keys(activeLeave?.substitutes).length
          ? Object.keys(activeLeave?.substitutes).map(key => (
              <div key={key} className={classes.substituteItem}>
                <p>{activeLeave?.substitutes[key].userEmail}</p>
                <div className={classnames(classes.priority, classes[key])}>{key}</div>
              </div>
            ))
          : textFormatter()}
      </div>

      <CustomButton onClick={openConfirmationModal} type="primary" size="small" text="Cancel Leave" />

      {opened && (
        <ConfirmationModal
          description="Are you sure you want to cancel active user leave?"
          onCancel={closeConfirmationModal}
          onConfirm={onCancel}
          withComment
          isCommentRequired
          loading={loading}
        />
      )}
    </div>
  ) : (
    <div className={classnames(classes.root, classes.justifyCenter)}>
      <Row justify={"center"}>
        <p className={classnames(classes.title, classes.textCenter)}>No absence data for the current user</p>
      </Row>
    </div>
  );
};

export default LeavesBar;

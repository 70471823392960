export const searchOptions = [
  {
    value: "name",
    title: "Name",
  },
  {
    value: "supplier",
    title: "Supplier",
  },
  {
    value: "batteryModelNumber",
    title: "Battery Model Number",
  },
  {
    value: "status",
    title: "Status",
  },
];

export const STATUS = ["active", "inactive"];
export const SWITCH_STATUS_MAP = { active: "inactive", inactive: "active" };

export const STATUS_COLOR_MAP = {
  active: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  inactive: {
    color: "#FF0000",
    backgroundColor: "#FFE2E2",
  },
};

export const VALIDATION_RULES = [{ required: true, message: "Please enter value" }];

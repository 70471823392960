import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Row } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { uniq } from "lodash";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "../../../../../store/dictionaries/selectors";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const ShipmentRecipient = ({ form, validateForm }) => {
  const classes = useStyles();
  const [selectedTenant, setSelectedTenant] = useState(null);

  const {
    list: { depot },
  } = useSelector(DictionariesSelector);

  const onTenantChange = useCallback(
    value => {
      setSelectedTenant(value);
      form.setFieldValue("toDepot", null);
      validateForm();
    },
    [form]
  );

  //
  // useEffect(() => {
  //   const name = data ? data.name : `${AUTH_USER?.depotName}_${moment().format("DDMMMYYYY")}`;
  //   setSelectedType(data?.assetsGroup);
  //   form.setFieldsValue({ name, group: data?.assetsGroup, condition: data?.assetsCondition });
  // }, [data]);

  const tenantList = useMemo(() => Object.keys(depot), [depot]);

  return (
    <Form onChange={validateForm} form={form} name="shipmentInfo" layout="inline" className={classes.formContainer}>
      <div>
        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Receiving Tenant</Title>
            <Form.Item name="toTenant" rules={[{ required: true, message: "Please select value" }]}>
              <NGISelect onChange={onTenantChange} showSearch placeholder={"Select value"}>
                {tenantList?.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Receiving Station</Title>
            <Form.Item rules={[{ required: true, message: "Please select value" }]} name="toDepot">
              <NGISelect onChange={validateForm} showSearch placeholder={"Select value"}>
                {depot[selectedTenant] &&
                  uniq(depot[selectedTenant])
                    ?.sort()
                    ?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Consignee</Title>
            <Form.Item rules={[{ required: true, message: "Please enter value" }]} name="consigneeName">
              <NGIInput placeholder="Consignee" />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default ShipmentRecipient;

import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { history } from "store";

const useStyles = makeStyles({
  button: {
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: 30,
    backgroundColor: "#F5F5F5",
    padding: "40px 20px",
    width: 260,
    height: 220,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    margin: "10px 0",
  },
  desc: {
    fontSize: 11,
    fontWeight: 300,
    color: "#444444",
    textAlign: "center",
  },
  badge: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    height: 26,
    width: 26,
    backgroundColor: "#FF0000",
    color: "#FFF",
    top: 10,
    right: 10,
    borderRadius: "50%",
    fontWeight: 700,
  },
});

export const DepotButton = ({ icon, name, decs, badgeCount, link, onClick }) => {
  const classes = useStyles();

  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
      return;
    }
    history.push(link);
  }, [link]);

  return (
    <div onClick={handleClick} className={classes.button}>
      {!!badgeCount && <div className={classes.badge}>{badgeCount}</div>}
      <img src={icon} alt={name} />
      <span className={classes.title}>{name}</span>
      <div className={classes.divider} />
      <span className={classes.desc}>{decs}</span>
    </div>
  );
};

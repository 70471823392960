import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { batteryListColumns } from "./BatteryListColumns";
import BatterySearch from "./BatterySearch";
import { fetchBatteriesList, markBatteryAsFaulty, updateBatteryState } from "../../../utils/api";
import CustomButton from "../../../components/Common/buttons/CustomButton";
// import NGIDropdownButton from "../../../components/Common/buttons/NGIDropdownButton";
// import lost from "assets/images/svg/lost.svg";
// import found from "assets/images/svg/found.svg";
// import faulty from "assets/images/svg/faulty.svg";
import ConfirmationModal from "../../../components/Common/modals/ConfirmationModal";
import { isEmpty } from "lodash";
import { getDictionary } from "../../../store/dictionaries/actions";
import { DICTIONARY_TYPES } from "../../../store/dictionaries/constants";
import { useDispatch } from "react-redux";
import NewBatteryModal from "./NewBatteries/NewBatteryModal";

const BatteryList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [list, setList] = useState(null);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.BATTERIES);

  const closeModal = useCallback(() => setActiveItem(null), []);
  const openModal = useCallback((id, type) => setActiveItem(`${id}.${type}`), []);

  const closeCreateModal = useCallback(() => setIsOpen(false), []);
  const openCreateModal = useCallback(() => setIsOpen(true), []);

  const onConfirmMark = useCallback(() => {
    const [itemId, markType] = activeItem.split(".");
    setLoader(true);
    if (markType === "faulty") {
      markBatteryAsFaulty(itemId)
        .then(() => {
          setLoader(false);
          successMessage("Battery marked as faulty successfully");
          handleSearch();
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    } else {
      const type = markType === "found" ? "Available" : "Lost";
      updateBatteryState(itemId, type)
        .then(() => {
          setLoader(false);
          successMessage(`Battery marked as ${type} successfully`);
          handleSearch();
        })
        .catch(e => {
          setLoader(false);
          defaultNgiErrorMethod(e);
        });
    }
    setActiveItem(null);
  }, [activeItem]);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
    dispatch(getDictionary(DICTIONARY_TYPES.DEPOT));
    dispatch(getDictionary(DICTIONARY_TYPES.TENANT));
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Templates were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    fetchBatteriesList(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => setSelectedRowKeys(rows),
  };

  const columns = useMemo(() => batteryListColumns(openModal), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                onClick={openCreateModal}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="ADD BATTERIES"
              />
              {/*{!!selectedRowKeys.length && (*/}
              {/*  <NGIDropdownButton*/}
              {/*    toggleEl={<CustomButton type="primary" text="MARK AS" />}*/}
              {/*    menuItems={[*/}
              {/*      {*/}
              {/*        icon: lost,*/}
              {/*        label: "Mark as Lost",*/}
              {/*        callback: () => {},*/}
              {/*      },*/}
              {/*      {*/}
              {/*        icon: found,*/}
              {/*        label: "Mark as Found",*/}
              {/*        callback: () => {},*/}
              {/*      },*/}
              {/*      {*/}
              {/*        icon: faulty,*/}
              {/*        label: "Mark as Faulty",*/}
              {/*        callback: () => {},*/}
              {/*      },*/}
              {/*    ]}*/}
              {/*  />*/}
              {/*)}*/}
            </div>

            <BatterySearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.BATTERIES}
              // rowSelection={rowSelection}
              // rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
        {activeItem && (
          <ConfirmationModal
            description={`Are you sure you want to mark the item as ${activeItem.split(".")[1]}?`}
            onCancel={closeModal}
            onConfirm={onConfirmMark}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Confirm"
          />
        )}
        {isOpen && <NewBatteryModal onClose={closeCreateModal} updateList={handleSearch} />}
      </div>
    </>
  );
};

export default BatteryList;

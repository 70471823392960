import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Row, Spin } from "antd";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { debounce } from "lodash";
import { attachDetachLoadBayAssets, retrieveAllAssets } from "utils/api";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import removeIcon from "assets/images/svg/Close.svg";

const useStyles = makeStyles({
  root: {
    paddingTop: 20,
  },
  select: {
    width: "100%",
  },
  groupTitle: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: 16,
    fontWeight: 600,
    textTransform: "uppercase",
    padding: "10px 0",
  },
  addedListContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxHeight: 600,
    overflow: "auto",
    padding: "5px 2px",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "49.5%",
    backgroundColor: "#00000008",
    borderRadius: 5,
    padding: 12,
    marginTop: 6,
  },
  removeIcon: {
    cursor: "pointer",
    height: 20,
  },
  qrCode: {
    display: "flex",
    flexDirection: "column",
  },
  error: {
    fontSize: 9,
    color: "#FF0000",
  },
});

const LOADED_STATE = { loaded: false, error: "" };

const AssetsList = ({ data, addedList, setAddedList, statusList, setStatusList }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState([]);

  const search = useCallback(value => {
    if (value) {
      setList([]);
      setIsLoading(true);
      retrieveAllAssets({
        qrCode: value,
      })
        .then(res => {
          const {
            data: { result },
          } = res;
          setList(result?.items || null);
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    }
  }, []);

  const onSelect = useCallback(
    value => {
      const foundItem = addedList.find(item => item === value);
      if (!foundItem) {
        setAddedList(prevState => [...prevState, value]);
        setItemStatus(value, LOADED_STATE);
        setList([]);
        attachDetachLoadBayAssets(data?.id, {
          assets: [value],
        })
          .then(() => {
            setItemStatus(value, { ...LOADED_STATE, loaded: true });
          })
          .catch(error => {
            const msg = error?.response?.data?.errors[0]?.desc;
            setItemStatus(value, { loaded: true, error: msg });
          });
      } else {
        warnMessage("Already added!");
        setList([]);
      }
    },
    [data, addedList]
  );

  const setItemStatus = useCallback(
    (id, state) =>
      setStatusList(prevState => {
        prevState[id] = state;
        return { ...prevState };
      }),
    []
  );

  const onRemove = useCallback(
    e => {
      const id = e.currentTarget.dataset.id;
      if (!statusList[id].error) {
        setItemStatus(id, LOADED_STATE);
        attachDetachLoadBayAssets(data?.id, {
          isDetach: true,
          assets: [id],
        })
          .then(() => {
            setAddedList(prevState => prevState.filter(item => item !== id));
            setItemStatus(id, LOADED_STATE);
          })
          .catch(() => {
            setItemStatus(id, LOADED_STATE);
          });
      } else {
        setAddedList(prevState => prevState.filter(item => item !== id));
        setItemStatus(id, LOADED_STATE);
      }
    },
    [statusList]
  );

  const debouncedSearch = useMemo(() => debounce(search, 1000), []);

  return (
    <div className={classes.root}>
      <Row className={classes.formItem}>
        <Col span={24}>
          <NGISelect
            allowClear
            showSearch
            className={classes.select}
            loading={isLoading}
            placeholder="Search for an asset by QR Code"
            filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
            onSearch={debouncedSearch}
            onSelect={onSelect}
            value={null}
            notFoundContent={isLoading ? <Spin size="small" /> : null}
          >
            {list.map(item => (
              <Option key={item.id} value={item.qrCode}>
                {item.qrCode || item.id}
              </Option>
            ))}
          </NGISelect>
        </Col>
      </Row>
      <div className={classes.groupTitle}>
        <span>{`${data?.group || data?.assetsGroup}s`}</span>
        <span>{addedList.length}</span>
      </div>
      <div className={classes.addedListContainer}>
        {addedList.map(item => (
          <div className={classes.listItem} key={item}>
            <span className={classes.qrCode}>
              {item}
              {statusList[item]?.error && <span className={classes.error}>{statusList[item]?.error}</span>}
            </span>
            {statusList[item]?.loaded ? (
              <img onClick={onRemove} className={classes.removeIcon} data-id={item} src={removeIcon} alt="remove" />
            ) : (
              <Spin size="small" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssetsList;

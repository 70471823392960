import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { DepotDashboardDetails } from "pages/depot-dashboard/DepotDashboardDetails";
import useDepotHasOC from "../../customHooks/useDepotHasOC";
import { Dashboard } from "../dashboard";
import { fetchDepotDetails } from "../../utils/api";
import { AUTH_USER } from "../../utils/constants";
import { updateStatusSuccess } from "../../store/utils/actions";
import { useDispatch } from "react-redux";

export const DepotDashboard = () => {
  const hasFeature = useDepotHasOC();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchDepotDetails({ name: AUTH_USER?.depotName }).then(async ({ data }) => {
      dispatch(updateStatusSuccess(data?.result?.operationalStatus));
    });
  }, []);

  return (
    <div className="page-content">
      {hasFeature ? (
        <Container fluid>
          <DepotDashboardDetails />
        </Container>
      ) : (
        <Dashboard />
      )}
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { defaultNgiErrorMethod, errorMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import { retrieveCalibrationTemplates, startCalibration, uploadMetersToS3Bucket } from "utils/api";
import { Option } from "antd/lib/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import closeIcon from "assets/images/svg/closeSmall.svg";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  modalResult: {
    maxWidth: "500px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  withSlider: {
    "& .ant-form-item": {
      margin: 0,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  success: {
    borderColor: "green",
    fontWeight: 600,
    fontSize: 16,
    "&>input": {
      color: "green",
    },
    "&>span": {
      color: "green",
    },
  },
  error: {
    borderColor: "red",
    fontWeight: 600,
    fontSize: 16,
    "&>input": {
      color: "red",
    },
    "&>span": {
      color: "red",
    },
  },
  collapseList: {
    backgroundColor: "#FFF",
  },
  xyList: {
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
  },
  xyContainer: {
    border: "1px solid #0000000D",
    display: "flex",
    borderRadius: 5,
  },
  index: {
    backgroundColor: "#0000000F",
    textAlign: "center",
    color: "#00000033",
    fontWeight: 600,
    padding: 7,
    width: 42,
    borderRadius: "4px 0 0 4px",
  },
  value: {
    borderLeft: "1px solid #0000000D",
    padding: 7,
    width: 100,

    "&>span": {
      fontWeight: 100,
    },
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  textBold: {
    color: "#666666",
    fontWeight: 600,
  },
  subText: {
    color: "#666666",
    fontWeight: 300,
  },
});

const NewCalibrationModal = ({ onClose }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState(false);
  const [showResult, setShowResult] = useState(false);

  useEffect(() => {
    retrieveCalibrationTemplates({ pageSize: 1000, status: "Active" }).then(res => {
      const {
        result: { items },
      } = res?.data;
      setTemplates(items);
    });
  }, []);

  const onSave = useCallback(
    async values => {
      try {
        setIsLoading(true);
        const { templateName } = values;
        startCalibration(templateName)
          .then(res => {
            uploadMetersToS3Bucket(res.data.result.url, uploadedFile).then(() => {
              setShowResult(true);
            });
            setIsLoading(false);
          })
          .catch(err => {
            setIsLoading(false);
            defaultNgiErrorMethod(err);
          });
      } catch (e) {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      }
    },
    [uploadedFile]
  );

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);

  const removeFile = useCallback(() => {
    setUploadedFile(null);
  }, []);

  const readUploadFile = useCallback(e => {
    setIsLoading(true);
    const file = e.file;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });
        const formattedResult = json.map(row => ({ ...row }));
        const noError = formattedResult.every(item => item || null);

        !noError && errorMessage("Please upload the correct ‘*.csv’ file");

        if (noError) setError(false);
        else setError(true);

        setUploadedFile(file);
        setIsLoading(false);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  return (
    <Modal
      title={
        !showResult ? (
          <div className={classes.infoContainer}>
            Meter Calibration
            <span>Add Meters via *.CSV upload</span>
          </div>
        ) : (
          "Meter Calibration Initiated"
        )
      }
      className={showResult ? classes.modalResult : classes.modal}
      open
      onCancel={openConfirmCloseModal}
      closable={false}
      footer={[]}
    >
      {!showResult ? (
        <Spin spinning={isLoading}>
          <Form name="newCalibration" form={form} onFinish={onSave}>
            <Row className={classes.formItem}>
              <Col span={24}>
                <Title level={5}>Calibration Template*</Title>
                <NGIFormItem name="templateName" rules={VALIDATION_RULES}>
                  <NGISelect showSearch placeholder={"Select value"}>
                    {templates.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>
            <Col span={24}>
              <Title level={5}>Meters *.CSV File*</Title>
              <NGIFormItem name="file" rules={[{ required: true, message: "Please upload file" }]}>
                {uploadedFile ? (
                  <div className={classes.fileContainer}>
                    <div className={classes.fileTitle}>{`${uploadedFile.name}`}</div>
                    <div onClick={removeFile} className={classes.removeBtn}>
                      <img src={closeIcon} alt="remove" />
                    </div>
                  </div>
                ) : (
                  <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                    <img className="upload-bg" src={uploadArea} alt="upload" />
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                      Support for a single upload. Strictly prohibit from uploading company data or other band files
                    </p>
                  </Dragger>
                )}
              </NGIFormItem>
            </Col>

            <Divider />
            <br />
            <Space>
              <CustomButton color="outlined" onClick={onClose} text="Cancel" size="small" />
              <CustomButton
                type="primary"
                htmlType="submit"
                onClick={openConfirmationModal}
                text="CALIBRATE"
                size="small"
                disabled={error}
              />
            </Space>
          </Form>
        </Spin>
      ) : (
        <div>
          <p className={classes.textBold}>
            The meter calibration process has been successfully initiated. You may now close this popup. Please note
            that the calibration can take up to 24 hours to complete.
          </p>
          <p className={classes.subText}>
            To monitor the progress, you can check the status in the "List of Calibrations" section. Once the results
            are ready, we will notify you via email.
          </p>
          <i className={classes.subText}>Thank you for your patience!</i>

          <Divider />
          <Space>
            <CustomButton color="outlined" onClick={onClose} text="Close" size="small" />
          </Space>
        </div>
      )}

      {isConfirmCloseOpen && (
        <ConfirmationModal
          description="The calibration process is still In Progress. Are you sure you want to stop the process?"
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to start calibration process?"
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default NewCalibrationModal;

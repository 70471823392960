import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { sensorCalibrationListColumns } from "./SensorCalibrationListColumns";
import SensorCalibrationSearch from "./SensorCalibrationSearch";
import { retrieveCalibrationTemplates, updateTemplateStatus } from "utils/api";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { isEmpty } from "lodash";
import CustomButton from "components/Common/buttons/CustomButton";
import NewTemplateModal from "./NewTemplate/NewTemplateModal";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import { useDispatch } from "react-redux";

const SensorCalibrationList = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [duplicateItem, setDuplicateItem] = useState(null);
  const [list, setList] = useState(null);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.CALIBRATION_TEMPLATES);

  const closeModal = useCallback(() => setActiveItem(null), []);
  const onArchive = useCallback(id => setActiveItem(id), []);

  const closeCreateTemplate = useCallback(() => {
    setDuplicateItem(null);
    setIsOpen(false);
  }, []);
  const openCreateTemplate = useCallback(() => setIsOpen(true), []);

  const onDuplicate = useCallback(item => {
    setDuplicateItem(item);
    openCreateTemplate();
  }, []);

  const onConfirmArchive = useCallback(() => {
    setLoader(true);
    updateTemplateStatus(activeItem, "Archived")
      .then(() => {
        setLoader(false);
        successMessage("Template marked as archived successfully");
        handleSearch();
      })
      .catch(e => {
        setLoader(false);
        defaultNgiErrorMethod(e);
      });
    setActiveItem(null);
  }, [activeItem]);

  useEffect(() => {
    dispatch(getDictionary(DICTIONARY_TYPES.SENSOR_CALIBRATION_LOOKUP_TABLES));
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Templates were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveCalibrationTemplates(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => sensorCalibrationListColumns(onDuplicate, onArchive), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                roles={[
                  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.METER_ENGINEER_LEADERSHIP,
                ]}
                onClick={openCreateTemplate}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="ADD TEMPLATE"
              />
            </div>

            <SensorCalibrationSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.CALIBRATION_TEMPLATES}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
        {activeItem && (
          <ConfirmationModal
            description="Are you sure you want to archive the item?"
            onCancel={closeModal}
            onConfirm={onConfirmArchive}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Confirm"
          />
        )}
        {isOpen && <NewTemplateModal onClose={closeCreateTemplate} updateList={handleSearch} data={duplicateItem} />}
      </div>
    </>
  );
};

export default SensorCalibrationList;

import { textFormatter } from "../../../../components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import closeIcon from "../../../../assets/images/svg/closeIconWhite.svg";

export const usersListColumns = onCancel => [
  {
    title: "User Name",
    dataIndex: "name",
    render: textFormatter,
  },
  {
    title: "Role",
    dataIndex: "roles",
    render: cellContent => cellContent.join(", "),
  },
  {
    title: "Email",
    dataIndex: "email",
    render: textFormatter,
  },
  {
    title: "Phone",
    dataIndex: "phone",
    render: textFormatter,
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="Cancel Leave"
        icon={closeIcon}
        handleClick={() => {
          onCancel(row);
        }}
      />
    ),
  },
];

export const searchOptions = [
  {
    value: "template",
    title: "Template",
  },
  {
    value: "batchNumber",
    title: "Batch Number",
  },
  {
    value: "date",
    title: "Registered Date",
  },
  {
    value: "state",
    title: "State",
  },
  {
    value: "barcode",
    title: "Barcode",
  },
];

import { useCallback, useEffect, useState } from "react";

const useDepotHasOC = () => {
  const [hasFeature, setHasFeature] = useState(false);

  const checkFeature = useCallback(() => {
    const depotData = JSON.parse(localStorage["b2c:userDepotData"] || "{}");
    setHasFeature(depotData?.allowOpenClose || false);
  }, []);

  useEffect(() => {
    checkFeature();
  }, []);

  return hasFeature;
};

export default useDepotHasOC;

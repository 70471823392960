import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { createBatteries, downloadFileBlobCsv, fetchReportFile, fetchTemplatesList } from "utils/api";
import Title from "antd/lib/typography/Title";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/es/mentions";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { defaultNgiErrorMethod, errorMessage, successMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIInput from "components/Common/inputs/NGIInput";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import Dragger from "antd/lib/upload/Dragger";
import uploadArea from "assets/images/svg/uploadArea.svg";
import * as xlsx from "xlsx";
import closeIcon from "assets/images/svg/closeSmall.svg";
import { isEmpty, uniq } from "lodash";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "../../../../store/dictionaries/selectors";
import classnames from "classnames";
import successIcon from "assets/images/svg/success-btn.svg";
import failedIcon from "assets/images/svg/failed-btn.svg";
import CustomLink from "../../../../components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  modalResult: {
    maxWidth: "500px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  result: {
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  failedResult: {
    border: "1px solid #FF00004D",
  },
  successResult: {
    border: "1px solid #17C3474D",
  },
  successText: {
    fontWeight: 700,
    color: "#17C347",
  },
  failedText: {
    fontWeight: 700,
    color: "#FF0000",
  },
  text: {
    marginBottom: 30,
    color: "#666666",
    fontSize: 13,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",

    "&>img": {
      marginRight: 10,
    },
  },
});

const NewBatteryModal = ({ onClose, updateList }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [uploadedData, setUploadedData] = useState([]);
  const [file, setFile] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTenant, setSelectedTenant] = useState(null);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [stats, setStats] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const {
    list: { depot, tenant },
  } = useSelector(DictionariesSelector);

  const mappedTenant = useMemo(
    () =>
      Object.keys(tenant).reduce((a, item) => {
        a[item] = tenant[item][0];
        return a;
      }, {}),
    [tenant]
  );

  const onSave = useCallback(
    async values => {
      try {
        if (!isEmpty(uploadedData)) {
          setIsLoading(true);

          const preparedData = {
            ...values,
            tenant: mappedTenant[selectedTenant],
            items: uploadedData,
          };
          createBatteries(preparedData)
            .then(({ data }) => {
              successMessage("Created successfully");
              setIsLoading(false);
              setStats(data.result);
            })
            .catch(err => {
              setIsLoading(false);
              defaultNgiErrorMethod(err);
            });
        }
      } catch (e) {
        setIsLoading(false);
        defaultNgiErrorMethod(e);
      }
    },
    [uploadedData, mappedTenant, selectedTenant]
  );

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const readUploadFile = useCallback(e => {
    const file = e.file;
    setFile(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        const data = e.target.result;
        const workbook = xlsx.read(data);
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet, { raw: false });

        const formattedResult = json.map(row => row.serial);
        const noError = formattedResult.every(item => item || null);
        !noError && errorMessage("Please upload the correct ‘*.csv’ file.");
        if (noError) {
          setUploadedData(formattedResult);
        } else {
          setUploadedData([]);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);

  const onCloseResult = useCallback(() => {
    onClose();
    updateList();
  }, []);

  const removeFile = useCallback(() => {
    setFile(null);
    setUploadedData([]);
  }, []);
  const getTemplates = useCallback(async () => {
    setIsLoading(true);
    await fetchTemplatesList({ pageSize: 1000, status: "active" })
      .then(res => {
        const {
          result: { items },
        } = res?.data;
        setTemplates(items);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        setTemplates(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const handleChange = useCallback(
    value => {
      setSelectedTemplate(value);
      // const foundTemplate = templates.find(item => item.id === value);
      // form.setFieldValue("mGasBatchNumber", foundTemplate?.mGasBatchNumber);
    },
    [form]
  );
  const handleChangeTenant = useCallback(
    value => {
      setSelectedTenant(value);
      form.setFieldValue("depot", null);
    },
    [form]
  );

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  const onFormChange = useCallback(() => {
    const { template, mGasBatchNumber, supplierBatchNumber, tenant, depot } = form.getFieldsValue();
    if (template && mGasBatchNumber && supplierBatchNumber && tenant && depot) setDisabled(false);
    else setDisabled(true);
  }, [form]);

  const onReportDownload = useCallback(fileName => {
    setIsLoading(true);
    fetchReportFile(fileName)
      .then(res => {
        const {
          result: { url },
        } = res.data;

        downloadFileBlobCsv(url)
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(e => {
            defaultNgiErrorMethod(e);
          });
        setIsLoading(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getTemplates();
  }, []);

  return (
    <Modal
      title={
        !stats ? (
          <div className={classes.infoContainer}>
            Add Batteries
            <span>Add Batteries via *.CSV upload</span>
          </div>
        ) : (
          "Battery Registration"
        )
      }
      className={!stats ? classes.modal : classes.modalResult}
      open
      onCancel={isLoading ? openConfirmCloseModal : onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        {!stats ? (
          <Form onFieldsChange={onFormChange} name="newBatteries" form={form} onFinish={onSave}>
            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Available Template*</Title>
                <NGIFormItem rules={[{ required: true, message: "Please select template" }]} name="template">
                  <NGISelect onChange={handleChange} showSearch className={classes.select} placeholder="Select value">
                    {templates?.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>mGas Batch Number*</Title>
                <NGIFormItem name="mGasBatchNumber" rules={VALIDATION_RULES}>
                  <NGIInput placeholder="Enter mGas Batch Number" />
                </NGIFormItem>
              </Col>
            </Row>
            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Supplier Batch Number*</Title>
                <NGIFormItem name="supplierBatchNumber" rules={VALIDATION_RULES}>
                  <NGIInput placeholder="Enter Supplier Batch Number" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Tenant*</Title>
                <NGIFormItem rules={[{ required: true, message: "Please select tenant" }]} name="tenant">
                  <NGISelect
                    onChange={handleChangeTenant}
                    showSearch
                    className={classes.select}
                    placeholder="Select value"
                  >
                    {Object.keys(mappedTenant)?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Depot*</Title>
                <NGIFormItem rules={[{ required: true, message: "Please select depot" }]} name="depot">
                  <NGISelect
                    disabled={!selectedTenant}
                    showSearch
                    className={classes.select}
                    placeholder="Select value"
                  >
                    {selectedTenant &&
                      depot[selectedTenant] &&
                      uniq(depot[selectedTenant])
                        ?.sort()
                        ?.map(item => (
                          <Option key={item} value={item}>
                            {item}
                          </Option>
                        ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Title level={5}>Batteries *.CSV File*</Title>
                {file ? (
                  <div className={classes.fileContainer}>
                    <div className={classes.fileTitle}>{`${file.name} (${uploadedData.length})`}</div>
                    <div onClick={removeFile} className={classes.removeBtn}>
                      <img src={closeIcon} alt="remove" />
                    </div>
                  </div>
                ) : (
                  <>
                    <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
                      <img className="upload-bg" src={uploadArea} alt="upload" />
                      <p className="ant-upload-text">Click or drag file to this area to upload</p>
                      <p className="ant-upload-hint">
                        Support for a single upload. Strictly prohibit from uploading company data or other band files
                      </p>
                    </Dragger>
                  </>
                )}
              </Col>
            </Row>

            <Divider />
            <br />
            <Space>
              <CustomButton color="outlined" onClick={onClose} text="Cancel" size="small" />
              <CustomButton
                type="primary"
                htmlType="submit"
                onClick={openConfirmationModal}
                text="ADD BATTERIES"
                size="small"
                disabled={disabled || !file}
              />
            </Space>
          </Form>
        ) : (
          <div className={classes.resultContainer}>
            <div className={classes.text}>
              A total of <span className={classes.successText}>{stats.registeredItemsCount}</span> batteries were
              successfully registered, while <span className={classes.failedText}>{stats.failedItemsCount}</span> could
              not be processed. You can download the list of batteries below for further review.
            </div>
            <div className={classes.buttons}>
              <div className={classnames(classes.result, classes.successResult)}>
                <div className={classes.titleContainer}>
                  <img src={successIcon} alt="" />
                  <b>{stats.registeredItemsCount} Batteries</b>
                </div>
                {!!stats.registeredItemsCount && (
                  <CustomLink
                    underlined
                    onClick={() => {
                      onReportDownload(stats.registeredUrl);
                    }}
                  >
                    Download *.CSV
                  </CustomLink>
                )}
              </div>
              <div className={classnames(classes.result, classes.failedResult)}>
                <div className={classes.titleContainer}>
                  <img src={failedIcon} alt="" />
                  <b>{stats.failedItemsCount} Batteries</b>
                </div>
                {!!stats.failedItemsCount && (
                  <CustomLink
                    underlined
                    onClick={() => {
                      onReportDownload(stats.failedUrl);
                    }}
                  >
                    Download *.CSV
                  </CustomLink>
                )}
              </div>
            </div>
            <br />
            <Space>
              <CustomButton type="primary" onClick={onCloseResult} text="CLOSE" size="small" />
            </Space>
          </div>
        )}
      </Spin>
      {isConfirmCloseOpen && (
        <ConfirmationModal
          description="The registration process is still In Progress. Please do not close the dialog."
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to add batteries?"
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default NewBatteryModal;

import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Header } from "pages/depot-dashboard/assetStockCount/tabs/Header";
import closeIcon from "../../../../assets/images/svg/closeSmall.svg";
import truckIcon from "../../../../assets/images/svg/Truck.svg";
import ConfirmationModal from "../../../../components/Common/modals/ConfirmationModal";
import TitleWithDivider from "../../../../components/Common/components/TitleWithDivider";
import CustomButton from "../../../../components/Common/buttons/CustomButton";
import AddVehicleModal from "./AddVehicleModal";
import { assignUserToVehicle, getUsersList, retrieveVehicles } from "../../../../utils/api";
import { defaultNgiErrorMethod, successMessage } from "../../../../components/Common/responses/message";
import { AUTH_USER, USER_ROLES } from "../../../../utils/constants";
import { extractPromisesResult, userHasOneOfRoles } from "../../../../utils/helpers/functions";
import { Spin } from "antd";

const useStyles = makeStyles({
  container: {},
  vehicleList: {
    display: "flex",
    flexWrap: "wrap",
    gap: 10,
  },
  vehicleItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    borderRadius: 5,
    width: "49%",
  },
  infoContainer: {
    display: "flex",
    alignItems: "center",
    padding: 10,
  },
  truckNumber: {
    fontWeight: 700,
    borderRadius: 3,
    border: "2px solid #000000",
    backgroundColor: "#FFD66D",
    padding: "3px 10px",
  },
  assignedTo: {
    marginLeft: 20,
    color: "#8C8C8C",
    fontWeight: 300,
    fontSize: 13,

    "&>span": {
      color: "#000",
      fontWeight: 600,
    },
  },
  actions: {
    padding: 15,
    borderLeft: "1px solid #d5d5d5cc",
  },
  removeBtn: {
    cursor: "pointer",
  },
  noData: {
    width: "100%",
    textAlign: "center",
    color: "#0000004d",
  },
});

export const VehicleAssignement = ({ title, desc, assignedList, setAssignedList }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [agentList, setAgentList] = useState([]);

  const onDelete = useCallback(licencePlate => {
    setIsLoading(true);
    assignUserToVehicle(licencePlate)
      .then(() => {
        setTimeout(() => {
          successMessage("User was unassigned successfully");
          setIsLoading(false);
          loadData();
        }, 1000);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
      });
  }, []);

  const loadData = useCallback(() => {
    setIsLoading(true);
    Promise.allSettled([
      retrieveVehicles({ depot: AUTH_USER?.depotName, pageSize: 200, isAssigned: true }),
      retrieveVehicles({ depot: AUTH_USER?.depotName, pageSize: 200 }),
      getUsersList({
        role: [USER_ROLES.MTSR, USER_ROLES.LT, USER_ROLES.LOGISTICS].join(),
        depot: AUTH_USER?.depotName,
        pageSize: 1000,
      }),
    ])
      .then(data => {
        setIsLoading(false);
        const [assignedVehicleList, vehicleList, agentList] = extractPromisesResult(data);
        setAssignedList(assignedVehicleList?.data?.result?.items);
        setVehicleList(vehicleList?.data?.result?.items);
        setAgentList(agentList?.data?.result?.items);
      })
      .catch(e => defaultNgiErrorMethod(e));
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const openAddModal = useCallback(() => setIsOpen(true), []);
  const closeAddModal = useCallback(() => setIsOpen(false), []);

  return (
    <div className={classes.container}>
      <Header
        desc={desc}
        title={title}
        btn={
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            onClick={openAddModal}
            iconImg={truckIcon}
            type="primary"
            size="small"
            text="LINK VEHICLE"
          />
        }
      />

      <TitleWithDivider title="List Of Vehicles" />
      <Spin spinning={isLoading}>
        <div className={classes.vehicleList}>
          {assignedList?.length ? (
            assignedList?.map(item => <VehicleItem key={item.assignedTo} data={item} onDelete={onDelete} />)
          ) : (
            <div className={classes.noData}>Please assign vehicles to employees.</div>
          )}
        </div>
        {isOpen && (
          <AddVehicleModal agentList={agentList} vehicleList={vehicleList} onClose={closeAddModal} update={loadData} />
        )}
      </Spin>
    </div>
  );
};

const VehicleItem = ({ data, onDelete }) => {
  const classes = useStyles();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const { assignedTo, licencePlate } = data;

  const handleDelete = useCallback(() => {
    onDelete(licencePlate);
  }, [onDelete, licencePlate]);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);

  return (
    <div className={classes.vehicleItem}>
      <div className={classes.infoContainer}>
        <div className={classes.truckNumber}>{licencePlate}</div>
        <div className={classes.assignedTo}>
          Assigned To: <span>{assignedTo}</span>
        </div>
      </div>
      {userHasOneOfRoles([
        USER_ROLES.SALES_SUPPORT,
        USER_ROLES.OPERATION_MANAGERS,
        USER_ROLES.A_SM,
        USER_ROLES.TSS,
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.FINANCE,
      ]) && (
        <div className={classes.actions}>
          <img className={classes.removeBtn} onClick={openConfirmationModal} src={closeIcon} alt="remove" />
        </div>
      )}

      {isConfirmationOpen && (
        <ConfirmationModal
          description={
            <span>
              Are you sure you want to unlink vehicle <b>{licencePlate}</b> and user <b>{assignedTo}</b>?
            </span>
          }
          onCancel={closeConfirmationModal}
          onConfirm={handleDelete}
        />
      )}
    </div>
  );
};

export const searchOptions = [
  {
    value: "status",
    title: "Status",
  },
  {
    value: "date",
    title: "Calibration Started",
  },
];

export const STATUS_COLOR_MAP = {
  "In Progress": {
    color: "#0085FF",
    backgroundColor: "#E7F5FF",
  },
  Succeeded: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  Failed: {
    color: "#FF0000",
    backgroundColor: "#FFE2E2",
  },
};

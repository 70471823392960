import React, { useCallback, useState } from "react";
import { CardBody } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Collapse, Row } from "antd";
import moment from "moment";
import classnames from "classnames";
import CustomButton from "../../../../components/Common/buttons/CustomButton";
import ConfirmationModal from "../../../../components/Common/modals/ConfirmationModal";
import { cancelUserAbsence } from "../../../../utils/api";
import { defaultNgiErrorMethod, successMessage } from "../../../../components/Common/responses/message";

const { Panel } = Collapse;

const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    "&> p": {
      whiteSpace: "nowrap",
      color: "rgba(140, 140, 140, 1)",
      margin: 0,
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "300",
    },
    "&> span": {
      flex: 1,
      whiteSpace: "nowrap",
      margin: 0,
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "600",
    },
  },
  flex: {
    flex: 1,
  },
  substituteItem: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    "&> p": {
      textTransform: "lowercase",
      marginBottom: 0,
      fontSize: 12,
      fontWeight: "500",
    },
    alignItems: "center",
  },
  priority: {
    borderRadius: 5,
    textTransform: "uppercase",
    padding: "4px 6px 4px 6px",
    marginLeft: 10,
    color: "white",
    fontSize: 10,
    fontWeight: 700,
  },
  High: {
    backgroundColor: "rgba(255, 46, 0, 1)",
  },
  Medium: {
    backgroundColor: "rgba(240, 139, 57, 1)",
  },
  Low: {
    backgroundColor: "rgba(0, 133, 255, 1)",
  },
  substitutes: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: 10,
    margin: "5px -16px -16px -16px ",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&> p": {
      margin: 0,
      color: "rgba(140, 140, 140, 1)",
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "300",
    },
  },
  cancelReason: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 10,
    margin: "15px -16px -16px -16px ",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    "&> p": {
      margin: 0,
      color: "rgba(140, 140, 140, 1)",
      paddingRight: 5,
      fontSize: 14,
      fontWeight: "300",
    },
  },
  title: {
    fontWeight: "600",
    fontSize: 14,
    marginBottom: 1,
  },
  statusContainer: {
    flex: 0.4,
  },
  status: {
    padding: "5px 10px",
    fontWeight: 600,
    fontSize: 11,
    textTransform: "uppercase",
    width: 100,
    textAlign: "center",
  },
  Completed: {
    backgroundColor: "#17C34733",
    color: "#17C347",
  },
  Cancelled: {
    backgroundColor: "#F08B3933",
    color: "#F08B39",
  },
  Planned: {
    color: "#0088ea",
    backgroundColor: "#e6f5ff",
  },
  Active: {
    backgroundColor: "#FF404033",
    color: "#FF4040",
  },
});

const Leaves = ({ leaves, setLoader, updateUser }) => {
  const classes = useStyles();
  const [activeId, setActiveId] = useState(null);

  const openConfirmationModal = useCallback(e => {
    const { id } = e?.currentTarget?.dataset;
    e?.stopPropagation();
    setActiveId(id);
  }, []);

  const closeConfirmationModal = useCallback(() => setActiveId(null), []);

  const onCancel = useCallback(
    reason => {
      setLoader(true);
      cancelUserAbsence(activeId, undefined, reason)
        .then(() => {
          setLoader(false);
          successMessage("User leave was canceled successfully");
          closeConfirmationModal();
          updateUser();
        })
        .catch(e => {
          setLoader(false);
          closeConfirmationModal();
          defaultNgiErrorMethod(e);
        });
    },
    [activeId]
  );

  return (
    <CardBody>
      {leaves?.length ? (
        <Collapse className={classes.collapseList} expandIconPosition={"right"}>
          {leaves?.map(item => (
            <Panel
              header={
                <div className={classes.header}>
                  <div className={classnames(classes.container, classes.statusContainer)}>
                    <div className={classnames(classes.status, classes[item.status])}>{item.status}</div>
                  </div>
                  <div className={classes.container}>
                    <p>Date(s)</p>
                    <span>{`${moment(item.startDate).format("DD MMM")} - ${moment(item.endDate).format(
                      "DD MMM"
                    )}`}</span>
                  </div>
                  <div className={classes.container}>
                    <p>Type</p>
                    <span>{item.reason}</span>
                  </div>
                  <div className={classes.container}>
                    <p>Total Days</p>
                    <span>{moment(item.endDate).diff(item.startDate, "day") + 1}</span>
                    {item.status === "Planned" && (
                      <CustomButton
                        data-id={item.id}
                        onClick={openConfirmationModal}
                        type="primary"
                        size="small"
                        text="Cancel"
                      />
                    )}
                  </div>
                </div>
              }
              key={item.id}
              style={{ padding: 0, margin: 0 }}
            >
              <Row>
                <div className={classnames(classes.container, classes.flex)}>
                  <p>Created at</p>
                  <span>{moment(item.createdAt).format("DD MMM, YYYY")}</span>
                  <p>by</p>
                  <span>{item.createdBy}</span>
                </div>
                <div className={classnames(classes.container, classes.flex)}>
                  <p>Updated at</p>
                  <span>{moment(item.updatedAt).format("DD MMM, YYYY")}</span>
                  <p>by</p>
                  <span>{item.updatedBy}</span>
                </div>
              </Row>
              {!!Object.keys(item.substitutes).length && (
                <Row className={classes.substitutes}>
                  <p>SUBSTITUTE(S):</p>
                  <Row>
                    {Object.keys(item.substitutes).map(key => (
                      <div key={key} className={classes.substituteItem}>
                        <p>{item.substitutes[key].userEmail}</p>
                        <div className={classnames(classes.priority, classes[key])}>{key}</div>
                      </div>
                    ))}
                  </Row>
                </Row>
              )}
              {item?.cancelReason && (
                <Row className={classes.cancelReason}>
                  <p>CANCEL REASON:</p>
                  {item?.cancelReason}
                </Row>
              )}
            </Panel>
          ))}
        </Collapse>
      ) : (
        <p className={classes.title}>No leave history data for the current user</p>
      )}
      {activeId && (
        <ConfirmationModal
          description="Are you sure you want to cancel planned user leave?"
          onCancel={closeConfirmationModal}
          onConfirm={onCancel}
          withComment
          isCommentRequired
        />
      )}
    </CardBody>
  );
};

export default Leaves;

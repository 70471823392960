import React, { useCallback, useMemo, useState } from "react";
import { Modal, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import { downloadFileBlobCsv, retrieveDownloadUrlByType } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import classnames from "classnames";
import successIcon from "assets/images/svg/success-btn.svg";
import failedIcon from "assets/images/svg/failed-btn.svg";
import moment from "moment";
import CustomLink from "components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  modalResult: {
    maxWidth: "500px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  addNewAsset: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  result: {
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  failedResult: {
    border: "1px solid #FF00004D",
  },
  successResult: {
    border: "1px solid #17C3474D",
  },
  successText: {
    fontWeight: 700,
    color: "#17C347",
  },
  failedText: {
    fontWeight: 700,
    color: "#FF0000",
  },
  text: {
    marginBottom: 30,
    color: "#666666",
    fontSize: 13,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",

    "&>img": {
      marginRight: 10,
    },
  },
});

const ReportResultModal = ({ onClose, stats }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const onCloseResult = useCallback(() => {
    onClose();
  }, []);

  const onReportDownload = useCallback(fileName => {
    setIsLoading(true);
    retrieveDownloadUrlByType("calibrationOutput", fileName)
      .then(res => {
        const {
          result: { url },
        } = res.data;

        downloadFileBlobCsv(url)
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(e => {
            defaultNgiErrorMethod(e);
          });
        setIsLoading(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setIsLoading(false);
      });
  }, []);

  const failedCount = useMemo(
    () =>
      (stats?.counts?.failedToTrigger || 0) +
      (stats?.counts?.ineligible || 0) +
      (stats?.counts?.failed || 0) +
      (stats?.counts?.timedOut || 0) +
      (stats?.counts?.canceled || 0) +
      (stats?.counts?.rejected || 0),
    [stats]
  );

  return (
    <Modal
      title={"Meter Calibration Report"}
      className={classes.modalResult}
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.resultContainer}>
          <div className={classes.text}>
            Report completed on <b>{moment(stats?.updatedAt).format("DD MMMM YYYY [at] HH:mm")}</b>
            <br />
            <br />A total of <span className={classes.successText}>{stats?.counts?.succeeded || 0}</span> meters were
            successfully calibrated, while <span className={classes.failedText}>{failedCount || 0}</span> could not be
            processed. You can download the list of meters below for further review.
          </div>
          <div className={classes.buttons}>
            <div className={classnames(classes.result, classes.successResult)}>
              <div className={classes.titleContainer}>
                <img src={successIcon} alt="" />
                <b>{stats?.counts?.succeeded} Meters</b>
              </div>
              {!!stats?.counts?.succeeded && (
                <CustomLink
                  underlined
                  onClick={() => {
                    onReportDownload(stats.succeededItemsFileName);
                  }}
                >
                  Download *.CSV
                </CustomLink>
              )}
            </div>
            <div className={classnames(classes.result, classes.failedResult)}>
              <div className={classes.titleContainer}>
                <img src={failedIcon} alt="" />
                <b>{failedCount} Meters</b>
              </div>
              {!!failedCount && (
                <CustomLink
                  underlined
                  onClick={() => {
                    onReportDownload(stats.failedItemsFileName);
                  }}
                >
                  Download *.CSV
                </CustomLink>
              )}
            </div>
          </div>
          <br />
          <Space>
            <CustomButton type="primary" onClick={onCloseResult} text="CLOSE" size="small" />
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default ReportResultModal;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Row } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import NGIDatePicker from "../../../../../components/Common/inputs/NGIDatePicker";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const CarrierInfo = ({ validateForm, form }) => {
  const classes = useStyles();

  return (
    <Form onChange={validateForm} form={form} name="carrierInfo" layout="inline" className={classes.formContainer}>
      <div>
        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Carrier</Title>
            <Form.Item rules={[{ required: true, message: "Please enter value" }]} name="carrierName">
              <NGIInput placeholder="Carrier" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Tracking number</Title>
            <Form.Item rules={[{ required: true, message: "Please enter value" }]} name="trackingNumber">
              <NGIInput placeholder="Tracking number" />
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Expected Arrival Date</Title>
            <Form.Item rules={[{ required: true, message: "Please select value" }]} name="expectedArrivalDate">
              <NGIDatePicker disabledPasDates allowClear dateFormat={"YYYY-MM-DD"} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default CarrierInfo;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import { batteryReportsListColumns } from "./BatteryReportsListColumns";
import BatteryReportsSearch from "./BatteryReportsSearch";
import { downloadFileBlobCsv, fetchReportFile, fetchReportList } from "../../../utils/api";

const BatteryReportsList = () => {
  const [isLoading, setLoader] = useState(false);
  const [list, setList] = useState({});
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.BATTERY_TEMPLATES);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Reports were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    fetchReportList(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const onReportDownload = useCallback(fileName => {
    setLoader(true);
    fetchReportFile(fileName)
      .then(res => {
        const {
          result: { url },
        } = res.data;

        downloadFileBlobCsv(url)
          .then(blob => {
            const url = window.URL.createObjectURL(new Blob([blob.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${fileName}`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          })
          .catch(e => {
            defaultNgiErrorMethod(e);
          });
        setLoader(false);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  const columns = useMemo(() => batteryReportsListColumns(onReportDownload), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <BatteryReportsSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.BATTERY_TEMPLATES}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
      </div>
    </>
  );
};

export default BatteryReportsList;

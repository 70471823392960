import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "antd";
import { Header } from "pages/depot-dashboard/assetStockCount/tabs/Header";
import { assetsCountMapping } from "pages/depot-dashboard/variables";
import { disableEnterMinus, disablePaste } from "utils/helpers/functions";

const useStyles = makeStyles({
  container: {},
  assetList: {
    marginTop: 40,

    "& > div + div": {
      marginTop: 8,
    },
  },
  assetItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#F5F5F5",
    padding: 15,
    borderRadius: 5,
  },
  infoContainer: {
    display: "flex",
  },
  nameContainer: {
    fontSize: 15,
    fontWeight: 500,

    "&>div": {
      fontSize: 12,
      fontWeight: 400,
      color: "#8C8C8C",
    },
  },
  icon: {
    height: 42,
    width: 42,
    marginRight: 20,
  },
  countsList: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 20,
    justifyContent: "end",
  },
  countInputContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: 300,
    color: "#444444",
    width: 200,
    justifyContent: "end",
  },
  inputTitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: 120,
    overflow: "hidden",
  },
  countInput: {
    height: 40,
    width: 70,
    marginLeft: 10,
  },
});

export const CountingAssets = ({ title, desc, setData }) => {
  const classes = useStyles();

  const onFieldChange = useCallback((rowName, fieldName, filedValue) => {
    setData(prevData => ({
      ...prevData,
      countingTab: {
        ...(prevData.countingTab || {}),
        [rowName]: {
          ...(prevData.countingTab[rowName] || {}),
          [fieldName]: Number(filedValue || 0),
        },
      },
    }));
  }, []);

  return (
    <div className={classes.container}>
      <Header desc={desc} title={title} />

      <div className={classes.assetList}>
        {assetsCountMapping.map(item => (
          <AssetItem key={item.name} data={item} onFieldChange={onFieldChange} />
        ))}
      </div>
    </div>
  );
};

const AssetItem = ({ data, onFieldChange }) => {
  const classes = useStyles();
  const { name, desc, icon, items } = data;

  const handleOnChangeField = useCallback(
    e => {
      const fieldName = e.currentTarget.dataset.name;
      const filedValue = e.currentTarget.value;
      if (parseInt(filedValue) < 0) {
        return;
      }
      onFieldChange(name, fieldName, filedValue);
    },
    [onFieldChange, name]
  );

  return (
    <div className={classes.assetItem}>
      <div className={classes.infoContainer}>
        <img className={classes.icon} src={icon} alt={`icon-${name}`} />
        <div className={classes.nameContainer}>
          {name}
          <div>{desc}</div>
        </div>
      </div>
      <div className={classes.countsList}>
        {items.map(item => (
          <div key={item} className={classes.countInputContainer}>
            <span title={item} className={classes.inputTitle}>
              {item}
            </span>
            <Input
              min={0}
              onPaste={disablePaste}
              onKeyDown={disableEnterMinus}
              placeholder="0"
              type="number"
              data-name={item}
              onChange={handleOnChangeField}
              className={classes.countInput}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

import React, { useCallback, useState } from "react";
import { Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { openDepot } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { USER_DEPOT_DATA, USER_ROLES } from "utils/constants";
import { updateStatusSuccess } from "store/utils/actions";
import { useDispatch } from "react-redux";

const useStyles = makeStyles({
  modal: {
    maxWidth: "600px!important",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  actions: {
    padding: 10,
    borderLeft: "1px solid #d5d5d5cc",
  },
});

const CloseDepotModal = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isLoading, setLoader] = useState(false);

  const onSubmit = useCallback(() => {
    setLoader(true);
    openDepot(USER_DEPOT_DATA?.id, {
      operationalStatus: "Closed",
    })
      .then(({ data }) => {
        const { operationalStatus } = data.result;
        dispatch(updateStatusSuccess(operationalStatus));

        setTimeout(() => {
          successMessage(`Depot ${operationalStatus} successfully`);
          setLoader(false);
          onClose();
        }, 1000);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  return (
    <Modal
      title={<div className={classes.titleWithDescription}>Depot Close</div>}
      open
      onCancel={onClose}
      closable={false}
      className={classes.modal}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <ul>
            <li>
              <b>Ensure the notification is sent out before closing.</b> This is mandatory.
            </li>
            <li>Confirm all team members on-site have returned assets to the depot.</li>
            <li>
              After the depot closes, all open routines will be forcibly closed within an hour, and employees with
              assets in their custody will have their routines suspended.
            </li>
          </ul>
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            onClick={onSubmit}
            type="error"
            text="Close Depot"
            size="small"
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default CloseDepotModal;

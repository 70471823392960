import React, { useCallback, useState } from "react";
import { Col, Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "../../../store/dictionaries/selectors";
import { Option } from "antd/es/mentions";
import NGISelect from "../../../components/Common/inputs/NGISelect";
import uploadArea from "../../../assets/images/svg/uploadArea.svg";
import Dragger from "antd/lib/upload/Dragger";
import { toBase64, uuidv4 } from "../../../utils/helpers/functions";
import closeIcon from "../../../assets/images/svg/closeSmall.svg";

const useStyles = makeStyles({
  root: {
    width: "600px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    whiteSpace: "pre-wrap",
    padding: "0 10px",
  },
  reason: {
    marginBottom: 15,
    width: "100%",
  },
  fileContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 15,
    border: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "5px 10px",
    marginTop: 10,
  },
  removeBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 32,
    width: 32,
    borderRadius: 50,
    background: "rgba(255, 255, 255, 1)",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  fileTitle: {
    fontWeight: 600,
    fontSize: 12,
  },
});

const MarkLostModal = ({ onConfirm, onCancel, loading = false }) => {
  const classes = useStyles();
  const [selectedReason, setSelectedReason] = useState();
  const [file, setFile] = useState(null);
  const {
    list: { lostAssetReason },
  } = useSelector(DictionariesSelector);

  const handleConfirm = useCallback(() => {
    onConfirm(selectedReason, file);
  }, [selectedReason, file]);

  const readUploadFile = useCallback(async e => {
    const file = e.file;
    if (file) {
      const base64Data = await toBase64(file);
      setFile({
        _id: uuidv4(),
        name: file.name,
        size: (file.size / 1000).toFixed(2),
        base64: base64Data,
        fileType: file.type,
      });
    }
  }, []);

  const removeFile = useCallback(() => setFile(null), []);

  return (
    <Modal className={classes.root} open={true} closable={false} title="Confirmation" onCancel={onCancel} footer={[]}>
      <Spin spinning={loading}>
        <p className={classes.description}>
          Are you sure you want to mark all items from the selected list as lost? This will change the status of the
          assets to lost.
        </p>

        <NGISelect
          className={classes.reason}
          showSearch
          value={selectedReason}
          onChange={setSelectedReason}
          placeholder="Please select reason"
        >
          {lostAssetReason &&
            lostAssetReason?.map(item => (
              <Option key={item.item} value={item}>
                {item.item}
              </Option>
            ))}
        </NGISelect>

        <Col span={24}>
          {file ? (
            <div className={classes.fileContainer}>
              <div className={classes.fileTitle}>{file.name}</div>
              <div onClick={removeFile} className={classes.removeBtn}>
                <img src={closeIcon} alt="remove" />
              </div>
            </div>
          ) : (
            <Dragger showUploadList={false} customRequest={readUploadFile} name="file">
              <img className="upload-bg" src={uploadArea} alt="upload" />
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">
                Support for a single upload. Strictly prohibit from uploading company data or other band files
              </p>
            </Dragger>
          )}
        </Col>
        <br />

        <Space>
          <CustomButton onClick={onCancel} size="small" type="primary" color="outlined" text="No" />
          <CustomButton disabled={!selectedReason} onClick={handleConfirm} size="small" type="primary" text="Yes" />
        </Space>
      </Spin>
    </Modal>
  );
};

export default MarkLostModal;

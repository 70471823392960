import React, { useCallback, useEffect, useState } from "react";
import { Col, Divider, Form, Modal, Row, Select, Space, Spin } from "antd";
import NGIForm from "components/Common/form/NGIForm";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import NGISelect from "components/Common/inputs/NGISelect";
import { Option } from "antd/lib/mentions";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { customDateFormatWithoutTime } from "components/Common/formatter";
import NGIDatePicker from "components/Common/inputs/NGIDatePicker";
import removeIcon from "assets/images/svg/Close.svg";
import { getUsersList, setUserUnavailability } from "utils/api";
import { uuidv4 } from "utils/helpers/functions";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import { defaultNgiErrorMethod, errorMessage, successMessage, warnMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIInput from "components/Common/inputs/NGIInput";

const reasonList = ["Sickness", "Vacation", "Education/Training", "Relocation", "Other"];
const mandatoryRoles = [USER_ROLES.MTSR, USER_ROLES.TECHNICIANS, USER_ROLES.TSS];

const useStyles = makeStyles({
  reasonRow: {
    alignItems: "center",
    justifyContent: "space-between",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  title: {
    fontSize: "13px!important",
  },
  addNew: {
    backgroundColor: "rgba(245, 245, 245, 1)",
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed rgba(159, 209, 255, 1)",
    borderRadius: 50,
    fontWeight: 600,
    marginTop: 15,

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  actionSection: {
    marginTop: 20,
  },
  rowContainer: {
    borderRadius: 5,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "rgba(250, 250, 250, 1)",
    marginBottom: 5,
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  container: {
    display: "flex",
    width: "100%",
    "& .ant-select:not(.ant-select-customize-input) .ant-select-selector": {
      border: 0,
      boxShadow: "none!important",
    },
  },
  actionContainer: {
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 80,

    "& img": {
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  priorityContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "50%",
    borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
  },
  subtitle: { marginInline: 5, color: "grey" },
  select: {
    "&>.ant-select-selector": {
      backgroundColor: "transparent!important",
    },
  },
});

const SetUnavailableEmployeeModal = ({ handleCancel, onSuccess }) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [isLoading, setLoader] = useState(false);
  const [substitutes, setSubstitutes] = useState([]);
  const [userList, setUserList] = useState([]);
  const [priorityList] = useState(["High", "Medium", "Low"]);
  const [confirmData, setConfirmData] = useState(null);
  const [confirmRemove, setConfirmRemove] = useState(null);
  const [reason, setReason] = useState("");

  const [form] = Form.useForm();

  const isHaveOneOfRoles = useCallback(
    (roles, allowedRoles) => roles?.some(role => allowedRoles?.some(r => r === role)),
    []
  );

  const openConfirmModal = useCallback(data => setConfirmData(data), []);
  const closeConfirmModal = useCallback(() => setConfirmData(null), []);

  const openConfirmRemove = useCallback(e => {
    const { id } = e.currentTarget.dataset;
    setConfirmRemove(id);
  }, []);
  const closeConfirmRemove = useCallback(() => setConfirmRemove(null), []);

  const handleSave = useCallback(
    values => {
      if (isHaveOneOfRoles(user.roles, mandatoryRoles) && !substitutes.length) {
        warnMessage("Provide one more substitute.");
        return;
      }
      const normalisedSubstitutes = {};

      substitutes.forEach(item => {
        normalisedSubstitutes[item.priority] = { userEmail: item.userEmail, userName: item.userName };
      });

      const data = {
        reason: values.reason,
        ...(values.comments && { comments: values.comments }),
        startDate: customDateFormatWithoutTime(values.startDate),
        endDate: customDateFormatWithoutTime(values.endDate),
        substitutes: normalisedSubstitutes,
      };

      openConfirmModal(data);
    },
    [substitutes, user]
  );

  const onSetUserUnavailability = useCallback(() => {
    setLoader(true);
    closeConfirmModal();
    const { employee } = form.getFieldsValue();
    setUserUnavailability(employee, confirmData)
      .then(() => {
        setTimeout(() => {
          onSuccess();
          setLoader(false);
          handleCancel();
          successMessage(`User unavailability is set successfully`);
        }, 3000);
      })
      .catch(err => {
        setLoader(false);
        if (err?.response?.data?.errors[0]?.code === "USER_ALREADY_HAS_VACATION_AT_SPECIFIED_PERIOD") {
          errorMessage("User already has vacation at specified period");
        } else {
          defaultNgiErrorMethod(err);
        }
      });
  }, [confirmData, user]);

  const onChangeSubstitute = useCallback(
    ({ id, value }) => {
      const user = userList.find(item => item.email === value);
      setSubstitutes(prevState =>
        prevState.map(item =>
          item._id === id ? { ...item, userEmail: user?.email || "", userName: user?.name || "" } : item
        )
      );
    },
    [userList]
  );

  const onChangePriority = useCallback(({ id, value }) => {
    setSubstitutes(prevState => prevState.map(item => (item._id === id ? { ...item, priority: value } : item)));
  }, []);

  const onAddSubstitute = useCallback(() => {
    setSubstitutes(prevState => [...prevState, { _id: uuidv4(), priority: "" }]);
  }, []);

  const onRemoveSubstitute = useCallback(() => {
    setSubstitutes(prevState => prevState.filter(item => item._id !== confirmRemove));
    closeConfirmRemove();
  }, [confirmRemove]);

  const onReasonChange = useCallback(value => setReason(value), []);
  const onUserChange = useCallback(
    value => {
      const foundUser = userList.find(item => item.email === value);
      setUser(foundUser);
    },
    [userList]
  );

  useEffect(() => {
    getUsersList({
      // status: "Unavailable",
      depot: AUTH_USER?.depotName,
      pageSize: 3000,
    }).then(({ data }) => setUserList(data?.result?.items));
  }, []);

  const validateStartDate = useCallback(
    () => form.getFieldValue("startDate") && form.validateFields(["startDate"]),
    [form]
  );

  return (
    <Modal
      open={true}
      title={
        <div className={classes.titleWithDescription}>
          Set Unavailable Employee
          <span>Please select the unavailability reason and substitutes</span>
        </div>
      }
      closable={false}
      onCancel={handleCancel}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="setUserUnavailability" form={form} onFinish={handleSave}>
          <Row className={classes.reasonRow}>
            <Col span={24}>
              <Title level={5}>Employee</Title>
              <NGIFormItem name="employee" rules={[{ required: true, message: "Please select employee" }]}>
                <NGISelect
                  loading={!userList?.length}
                  placeholder="Please select User"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                  onChange={onUserChange}
                >
                  {userList.map(item => (
                    <Option key={item.email} value={item.email}>
                      {item.name || item.email}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          </Row>
          <TitleWithDivider title="Unavailability Type" />
          <Row className={classes.reasonRow}>
            <Col span={7}>
              <Title className={classes.title}>Reason</Title>
              <NGIFormItem name="reason" rules={[{ required: true, message: "Please select Reason" }]}>
                <NGISelect
                  loading={!reasonList?.length}
                  placeholder="Please select Reason"
                  filterOption={(input, option) => (option?.children ?? "").toLowerCase().includes(input.toLowerCase())}
                  onChange={onReasonChange}
                >
                  {reasonList.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
            <Col span={7}>
              <Title className={classes.title}>Start Date</Title>
              <NGIFormItem
                name="startDate"
                rules={[
                  { required: true, message: "Please select Start Date" },
                  {
                    message: "Start Date cannot be grater than End Date",
                    validator: (_, value) => {
                      const endDate = form
                        .getFieldValue("endDate")
                        ?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                      const startDate = value.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                      if (endDate && startDate > endDate) {
                        return Promise.reject("Start Date cannot be grater than End Date");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <NGIDatePicker placeholder="Start Date" allowClear />
              </NGIFormItem>
            </Col>
            <Col span={7}>
              <Title className={classes.title}>End Date</Title>
              <NGIFormItem name="endDate" rules={[{ required: true, message: "Please select End Date" }]}>
                <NGIDatePicker
                  placeholder="End Date"
                  onChange={validateStartDate}
                  disableDates={current =>
                    form.getFieldValue("startDate")?.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }) > current
                  }
                  allowClear
                />
              </NGIFormItem>
            </Col>
          </Row>
          {reason === "Other" && (
            <Col span={24}>
              <Title level={5}>Comments</Title>
              <NGIFormItem name="comments" rules={[{ required: true, message: "Please enter comments" }]}>
                <NGIInput />
              </NGIFormItem>
            </Col>
          )}
          <br />
          <Col span={24}>
            <TitleWithDivider title="Task Substitute(s)" />
            <div>
              {substitutes.map(item => (
                <div className={classes.rowContainer} key={item._id}>
                  <div className={classes.container}>
                    <NGIFormItem
                      name={`substitute-${item._id}`}
                      style={{ width: "50%", margin: "12px 0px 12px 0px" }}
                      rules={[{ required: true, message: "Please select substitute" }]}
                    >
                      <Select
                        onChange={value => onChangeSubstitute({ value, id: item._id })}
                        tokenSeparators={[","]}
                        placeholder="Select Substitute"
                        allowClear
                        showSearch
                        className={classes.select}
                      >
                        {userList.map(item => (
                          <Option key={item.email} value={item.email}>
                            {item.name}
                          </Option>
                        ))}
                      </Select>
                    </NGIFormItem>
                    <div className={classes.priorityContainer}>
                      <span className={classes.subtitle}>Priority:</span>
                      <NGIFormItem
                        name={`priority-${item._id}`}
                        style={{ margin: "12px 0px 12px 0px", width: "100%" }}
                        rules={[{ required: true, message: "Please select priority" }]}
                      >
                        <Select
                          onChange={value => onChangePriority({ id: item._id, value })}
                          tokenSeparators={[","]}
                          placeholder="Select Priority"
                          allowClear
                          showSearch
                          className={classes.select}
                        >
                          {priorityList.map(item => (
                            <Option key={item} value={item}>
                              {item}
                            </Option>
                          ))}
                        </Select>
                      </NGIFormItem>
                    </div>
                  </div>
                  <div className={classes.actionContainer}>
                    <img data-id={item._id} onClick={openConfirmRemove} src={removeIcon} alt="remove" />
                  </div>
                </div>
              ))}
            </div>
            <div className={classes.addNew} onClick={onAddSubstitute}>
              + Add New Substitute
            </div>
          </Col>
          <Divider />
          <Space className={classes.actionSection}>
            <CustomButton onClick={handleCancel} type="primary" color="outlined" size="small" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" size="small" text="Save" />
          </Space>
          {confirmData && (
            <ConfirmationModal
              description="Are you sure you want to set user unavailability?"
              onCancel={closeConfirmModal}
              onConfirm={onSetUserUnavailability}
              loading={isLoading}
            />
          )}
          {confirmRemove && (
            <ConfirmationModal
              description="Are you sure you want to remove substitute?"
              onCancel={closeConfirmRemove}
              onConfirm={onRemoveSubstitute}
            />
          )}
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default SetUnavailableEmployeeModal;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useHistory, useParams } from "react-router";
import { cancelOrder, deleteOrder, getOrderDetails, receiveOrder } from "utils/api";
import { Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NotFoundContainer from "components/Common/NotFoundContainer";
import { customDateFormatWithoutTime, statusFormatterWithMap } from "../../../../components/Common/formatter";
import { IMG_MAP, STATUS_COLOR_MAP } from "../constants";
import { Box } from "@mui/material";
import NGIDropdownButton from "../../../../components/Common/buttons/NGIDropdownButton";
import menuIcon from "assets/images/svg/MenuVertical.svg";
import { useDispatch, useSelector } from "react-redux";
import { DictionariesSelector } from "../../../../store/dictionaries/selectors";
import { getDictionary } from "../../../../store/dictionaries/actions";
import { DICTIONARY_TYPES } from "../../../../store/dictionaries/constants";
import right from "assets/images/svg/right-icon.svg";
import CustomButton from "../../../../components/Common/buttons/CustomButton";
import NewOrderModal from "../newOrder/NewOrderModal";
import classnames from "classnames";
import ConfirmationModal from "../../../../components/Common/modals/ConfirmationModal";
import moment from "moment/moment";
import { USER_ROLES } from "../../../../utils/constants";
import CustomLink from "../../../../components/Common/buttons/CustomLink";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    borderRadius: 15,
    border: "1px solid #0000001A",
  },
  cardBody: {
    padding: 0,
  },
  header: {
    display: "flex",
    padding: "20px 30px",
    borderBottom: "1px solid #0000001A",
  },
  headerContainer: {
    fontSize: 16,
    marginRight: 30,

    "&>span:first-child": {
      color: "#8C8C8C",
      fontWeight: 100,
      marginRight: 5,
    },
    "&>span:nth-child(2)": {
      fontWeight: 700,
      marginRight: 10,
    },
  },
  menuBtn: {
    margin: -20,
    display: "flex",
    alignItems: "center",
    borderLeft: "1px solid #0000001A",
  },
  imgBtn: {
    height: 20,
    cursor: "pointer",
    marginLeft: 5,
  },
  generalInfo: {
    display: "flex",
    position: "relative",
  },
  section: {
    width: "50%",
    padding: 30,
  },
  divider: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "&:before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      width: 1,
      backgroundColor: "#0000001A",
    },
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    width: 30,
    height: 30,
    backgroundColor: "#E6E6E6",
    borderRadius: "50%",
  },
  dataColumn: {
    fontSize: 13,

    "&>div:first-child": {
      fontWeight: 300,
      color: "#8C8C8C",
    },
    "&>div:nth-child(2)": {
      fontWeight: 700,
    },
  },
  marginBottom: {
    marginBottom: 20,
  },
  detailsContainer: {
    padding: 30,
    borderTop: "1px solid #0000001A",
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 30,
    borderTop: "1px solid #0000001A",
  },
  detailsTitle: {
    fontSize: 13,
    color: "#8C8C8C",
    fontWeight: 100,
    marginBottom: 10,
    marginRight: 5,
  },
  noDataContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    flexDirection: "column",
    borderRadius: 5,
    border: "1px dashed rgba(0, 0, 0, 0.30)",
    background: "rgba(0, 0, 0, 0.03)",

    "&>span": {
      color: "#8C8C8C",
      fontWeight: 300,
      fontSize: 13,
    },
  },
  addButton: {
    marginTop: 20,
    width: 200,
  },

  addedListContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    maxHeight: 600,
    overflow: "auto",
    padding: "5px 2px",
  },
  listItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#00000008",
    borderRadius: 5,
    padding: 12,
    marginTop: 6,
  },
  typeName: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    "&>img": {
      marginRight: 5,
      width: 20,
    },
  },
  qty: {
    width: "20%",
    textAlign: "end",
  },
  groupTitle: {
    display: "flex",
    fontSize: 16,
    fontWeight: 600,
    padding: "10px 0",
  },
  carrierData: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  receivedMsg: {
    backgroundColor: "#D8F2DF",
    padding: "10px 30px",
  },
  headerButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  headerBtn: {
    marginLeft: 5,
  },
});

const OrderDetails = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isReceiveConfirmationOpen, setIsReceiveConfirmationOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [isCancelOpen, setIsCancelOpen] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [step, setStep] = useState(null);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setStep(null);
  }, []);
  const openModal = useCallback(() => setModalOpen(true), []);

  const closeReceiveModal = useCallback(() => setIsReceiveConfirmationOpen(false), []);
  const openReceiveModal = useCallback(() => setIsReceiveConfirmationOpen(true), []);

  const closeDeleteModal = useCallback(() => setIsDeleteOpen(false), []);
  const openDeleteModal = useCallback(() => setIsDeleteOpen(true), []);

  const closeCancelModal = useCallback(() => setIsCancelOpen(false), []);
  const openCancelModal = useCallback(() => setIsCancelOpen(true), []);

  const openAddModal = useCallback(() => {
    setStep(1);
    setModalOpen(true);
  }, []);

  const {
    list: { tenant },
  } = useSelector(DictionariesSelector);

  const getData = useCallback(() => {
    setLoading(true);
    getOrderDetails(id)
      .then(res => {
        if (res.data.result) {
          setData(res.data.result);
          setLoading(false);
        } else {
          setIsNotFound(true);
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [id]);

  const onReceive = useCallback(() => {
    setLoading(true);
    closeReceiveModal();
    receiveOrder(id)
      .then(() => {
        setLoading(false);
        successMessage("Order shipped successfully");
        goBack();
      })
      .catch(err => {
        setLoading(false);
        defaultNgiErrorMethod(err);
      });
  }, [id]);

  const onDelete = useCallback(
    reason => {
      setLoading(true);
      closeDeleteModal();
      deleteOrder(id, reason)
        .then(() => {
          setLoading(false);
          successMessage("Order removed successfully");
          goBack();
        })
        .catch(err => {
          setLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [id]
  );

  const onCancel = useCallback(
    reason => {
      setLoading(true);
      closeCancelModal();
      cancelOrder(id, reason)
        .then(() => {
          setLoading(false);
          successMessage("Order cancelled successfully");
          goBack();
        })
        .catch(err => {
          setLoading(false);
          defaultNgiErrorMethod(err);
        });
    },
    [id]
  );

  useEffect(() => {
    getData();
    dispatch(getDictionary(DICTIONARY_TYPES.TENANT));
  }, []);

  const tenantMap = useMemo(
    () =>
      Object.keys(tenant).reduce((a, item) => {
        a[tenant[item][0]] = item;
        return a;
      }, {}),
    [tenant]
  );

  const goBack = useCallback(() => history.push("/multi-tenant/shipments"), []);
  const qtySum = useMemo(() => data?.items?.reduce((count, obj) => count + obj.quantity, 0), [data]);

  return (
    <div className="page-content">
      <Container fluid>
        <NotFoundContainer isNotFound={isNotFound}>
          <Spin spinning={isLoading}>
            <div className={classes.root}>
              <Card className={classes.container}>
                <CardBody className={classes.cardBody}>
                  <div className={classes.header}>
                    <div className={classes.headerContainer}>
                      <span>ID</span>
                      <span>{data?.id}</span>
                      <span>{statusFormatterWithMap(data?.status, STATUS_COLOR_MAP)}</span>
                    </div>
                    <Box flex={1} />
                    <div className={classes.headerContainer}>
                      <span>CREATED</span>
                      <span>{customDateFormatWithoutTime(data?.createdAt)}</span>
                    </div>
                    <div className={classes.menuBtn}>
                      <NGIDropdownButton
                        position="left"
                        toggleEl={<img className={classes.imgBtn} src={menuIcon} alt="action-icon" />}
                        menuItems={[
                          ...(data?.status === "Open" || data?.status === "Cancelled"
                            ? [
                                {
                                  label: "Remove Order",
                                  callback: openDeleteModal,
                                  roles: [
                                    USER_ROLES.SYSTEM_FULL_ADMINS,
                                    USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                                    USER_ROLES.METER_ENGINEER_LEADERSHIP,
                                    USER_ROLES.IT_OPERATIONS,
                                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                    USER_ROLES.WAREHOUSE,
                                  ],
                                },
                              ]
                            : []),
                          ...(data?.status === "Open" || data?.status === "Packed"
                            ? [
                                {
                                  label: "Cancel Order",
                                  callback: openCancelModal,
                                  roles: [
                                    USER_ROLES.SYSTEM_FULL_ADMINS,
                                    USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                                    USER_ROLES.METER_ENGINEER_LEADERSHIP,
                                    USER_ROLES.IT_OPERATIONS,
                                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                    USER_ROLES.WAREHOUSE,
                                    USER_ROLES.LOGISTICS,
                                  ],
                                },
                              ]
                            : []),
                        ]}
                      />
                    </div>
                  </div>
                  {data?.status === "Received" && (
                    <div className={classes.receivedMsg}>
                      Shipment received by <b>{data?.updatedBy}</b> on{" "}
                      <b>{moment(data?.updatedAt).format("DD MMMM, YYYY [at] hh:mmA")}</b>
                    </div>
                  )}

                  <div className={classes.generalInfo}>
                    <div className={classes.section}>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>From</div>
                      </div>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>Shipping Tenant</div>
                        <div>{tenantMap[data?.fromTenant]}</div>
                      </div>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>Shipping Station</div>
                        <div>{data?.fromDepot}</div>
                      </div>
                    </div>
                    <div className={classes.divider}>
                      <div className={classes.iconContainer}>
                        <img src={right} alt="right icon" />
                      </div>
                    </div>
                    <div className={classes.section}>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>To</div>
                      </div>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>Receiving Tenant</div>
                        <div>{tenantMap[data?.toTenant]}</div>
                      </div>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>Receiving Station</div>
                        <div>{data?.toDepot}</div>
                      </div>
                      <div className={classnames(classes.dataColumn, classes.marginBottom)}>
                        <div>Consignee</div>
                        <div>{data?.consigneeName}</div>
                      </div>
                    </div>
                  </div>
                  {data?.status !== "Removed" && data?.status !== "Cancelled" ? (
                    <>
                      <div className={classes.detailsContainer}>
                        <div className={classes.headerButton}>
                          <div className={classes.detailsTitle}>Asset Details</div>
                          <>
                            /
                            <CustomLink
                              className={classes.headerBtn}
                              roles={[
                                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                                USER_ROLES.METER_ENGINEER_LEADERSHIP,
                                USER_ROLES.IT_OPERATIONS,
                                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                USER_ROLES.WAREHOUSE,
                                USER_ROLES.LOGISTICS,
                              ]}
                              underlined
                              target="_blank"
                              onClick={openAddModal}
                            >
                              Add Assets
                            </CustomLink>
                          </>
                        </div>

                        {!data?.items?.length ? (
                          <div className={classes.noDataContainer}>
                            <span>No assets added to the shipment</span>
                            <CustomButton
                              onClick={openModal}
                              className={classes.addButton}
                              type="primary"
                              size="small"
                              text="ADD ASSETS"
                              roles={[
                                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                                USER_ROLES.METER_ENGINEER_LEADERSHIP,
                                USER_ROLES.IT_OPERATIONS,
                                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                USER_ROLES.WAREHOUSE,
                                USER_ROLES.LOGISTICS,
                              ]}
                            />
                          </div>
                        ) : (
                          <div className={classes.addedListContainer}>
                            <div className={classes.groupTitle}>
                              <span></span>
                              <span>{qtySum} Pcs</span>
                            </div>
                            {data?.items?.map(item => (
                              <div className={classes.listItem} key={item.id}>
                                <div className={classes.typeName}>
                                  <img src={IMG_MAP[item.type]} alt="asset-type" />
                                  <span>{item?.qrCode || item?.id}</span>
                                </div>
                                <span className={classes.qty}>x{item.quantity}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className={classes.detailsContainer}>
                        <div className={classes.detailsTitle}>Carrier & Tracking</div>

                        {data?.status !== "InTransit" && data?.status !== "Received" && (
                          <div className={classes.noDataContainer}>
                            <span>No carrier & tracking added (Add assets first)</span>
                            <CustomButton
                              disabled={data?.status === "Open"}
                              onClick={openModal}
                              className={classes.addButton}
                              type="primary"
                              size="small"
                              text="ADD CARRIER & TRACKING"
                              roles={[
                                USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                                USER_ROLES.METER_ENGINEER_LEADERSHIP,
                                USER_ROLES.IT_OPERATIONS,
                                USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                                USER_ROLES.WAREHOUSE,
                                USER_ROLES.LOGISTICS,
                              ]}
                            />
                          </div>
                        )}

                        {(data?.status === "InTransit" || data?.status === "Received") && (
                          <div className={classes.carrierData}>
                            <div className={classes.dataColumn}>
                              <div>Carrier Company</div>
                              <div>{data?.carrierName}</div>
                            </div>
                            <div className={classes.dataColumn}>
                              <div>Expected Arrival Date</div>
                              <div>{data?.expectedArrivalDate}</div>
                            </div>
                            <div className={classes.dataColumn}>
                              <div>Tracking Number</div>
                              <div>{data?.trackingNumber}</div>
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className={classes.detailsContainer}>
                      <div className={classes.detailsTitle}>Cancellation Reason</div>
                      {data?.deleteReason || data?.cancelReason}
                    </div>
                  )}

                  <div className={classes.actionsContainer}>
                    <CustomButton onClick={goBack} type="primary" color="outlined" size="small" text="GO BACK" />
                    {data?.status === "InTransit" && (
                      <CustomButton
                        roles={[USER_ROLES.PRODUCT_TECHNICIAN_LEAD, USER_ROLES.WAREHOUSE]}
                        onClick={openReceiveModal}
                        type="primary"
                        size="small"
                        text="RECEIVE SHIPMENT"
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
              {modalOpen && <NewOrderModal step={step} onClose={closeModal} update={getData} data={data} />}
              {isReceiveConfirmationOpen && (
                <ConfirmationModal
                  title="Confirm Receival"
                  onCancel={closeReceiveModal}
                  onConfirm={onReceive}
                  description="Are you sure you want to proceed with receiving this shipment? Please confirm to continue."
                />
              )}
              {isDeleteOpen && (
                <ConfirmationModal
                  title="Confirm Remove"
                  onCancel={closeDeleteModal}
                  onConfirm={onDelete}
                  isCommentRequired
                  withComment
                  description="Are you sure you want to remove this shipment? Please confirm to continue."
                />
              )}
              {isCancelOpen && (
                <ConfirmationModal
                  title="Confirm Cancel"
                  onCancel={closeCancelModal}
                  onConfirm={onCancel}
                  isCommentRequired
                  withComment
                  description="Are you sure you want to cancel this shipment? Please confirm to continue."
                />
              )}
            </div>
          </Spin>
        </NotFoundContainer>
      </Container>
    </div>
  );
};

export default OrderDetails;

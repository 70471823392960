export const searchOptions = [
  {
    value: "date",
    title: "Created At",
  },
  {
    value: "id",
    title: "Report ID",
  },
  {
    value: "depot",
    title: "Depot",
  },
  {
    value: "template",
    title: "Template",
  },
];

export const STATUS = ["active", "inactive"];
export const SWITCH_STATUS_MAP = { active: "inactive", inactive: "active" };

export const STATUS_COLOR_MAP = {
  active: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  inactive: {
    color: "#FF0000",
    backgroundColor: "#FFE2E2",
  },
};

import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Col, Form, Row } from "antd";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import { VALIDATION_RULES } from "pages/depot-management/depots/constants";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { AUTH_USER } from "utils/constants";
import moment from "moment";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const ASSET_CONDITION = {
  Cylinder: ["Full", "Ready for Filling"],
  Meter: [
    "Ready for Market",
    "Healthy",
    "Faulty",
    "Awaiting Testing From Market",
    "Verified",
    "Awaiting Configuration",
  ],
};

const LBInformation = ({ data, form, isEdit }) => {
  const classes = useStyles();
  const [selectedType, setSelectedType] = useState(form.getFieldValue("group"));
  const onTypeChange = useCallback(
    value => {
      setSelectedType(value);
      form.setFieldValue("condition", null);
    },
    [form]
  );

  useEffect(() => {
    const name = data ? data.name : `${AUTH_USER?.depotName}_${moment().format("DDMMMYYYY")}`;
    setSelectedType(data?.assetsGroup);
    form.setFieldsValue({ name, group: data?.assetsGroup, condition: data?.assetsCondition });
  }, [data]);

  return (
    <Form form={form} name="lbInfo" layout="inline" className={classes.formContainer}>
      <div>
        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Loading Bay Name</Title>
            <Form.Item name="name">
              <NGIInput disabled placeholder="Loading Bay Name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Title level={5}>Asset Type</Title>
            <Form.Item name="group" rules={VALIDATION_RULES}>
              <NGISelect
                disabled={isEdit && data?.status !== "Draft"}
                onChange={onTypeChange}
                showSearch
                placeholder={"Select value"}
              >
                {["Cylinder", "Meter"].map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>

        <Row className={classes.formItem}>
          <Col span={12}>
            <Title level={5}>Asset Condition</Title>
            <Form.Item name="condition" rules={VALIDATION_RULES}>
              <NGISelect disabled={isEdit && data?.status !== "Draft"} showSearch placeholder={"Select value"}>
                {ASSET_CONDITION[selectedType]?.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </NGISelect>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default LBInformation;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, Container } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Divider, Space, Steps } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { history } from "store";
import { CountingAssets } from "pages/depot-dashboard/assetStockCount/tabs/CountingAssets";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import { getMappingObject } from "pages/depot-dashboard/utils";
import { VehicleAssignement } from "./tabs/VehicleAssignement";
import { TeamAvailability } from "./tabs/TeamAvailability";
import moment from "moment";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import OpenDepotModal from "./tabs/OpenDepotModal";
import { useSelector } from "react-redux";
import { UtilsSelector } from "store/utils/selectors";
import NGIPrompt from "components/Common/NGIPrompt";
import { getUsersList } from "utils/api";

const useStyles = makeStyles({
  stepsContainer: {
    padding: "20px 30px",
    backgroundColor: "#FFF",
    borderBottom: "1px solid #0000000D",

    "& .ant-steps-item-container": {
      display: "flex",
      alignItems: "center",
    },
  },
  container: {
    borderRadius: 30,
  },
  content: {
    padding: 40,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    padding: 30,
    paddingTop: 10,
  },
});

export const AssetStockCount = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [current, setCurrent] = useState(0);
  const [assignedList, setAssignedList] = useState([]);
  const [isReportOpen, setIsOpenReport] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [userList, setUserList] = useState([]);
  const { depotStatus } = useSelector(UtilsSelector);

  const [tabsData, setTabsData] = useState({
    countingTab: getMappingObject(),
  });

  const tabs = useMemo(
    () => [
      {
        title: "Counting Assets",
        description: "Input all requested asset counts",
        key: "countingAssets",
        children: <CountingAssets title="Counting Assets" desc={AUTH_USER?.depotName} setData={setTabsData} />,
      },
      {
        title: "Depot Fleet",
        description: "Assign vehicles to LT’s",
        key: "depotFleet",
        children: (
          <VehicleAssignement
            title="Vehicle Availability"
            desc={AUTH_USER?.depotName}
            assignedList={assignedList}
            setAssignedList={setAssignedList}
          />
        ),
      },
      {
        title: "Team Availability",
        description: "Set unavailable employees",
        key: "teamAvailability",
        children: <TeamAvailability title="Unavailable Employees" desc={moment().format("DD MMM. YYYY")} />,
      },
    ],
    [tabsData, assignedList, setAssignedList]
  );

  const handleCancel = useCallback(() => {
    setIsCancel(true);
    history.goBack();
  }, []);

  const openConfirmModal = useCallback(() => setIsOpen(true), []);
  const closeConfirmModal = useCallback(() => setIsOpen(false), []);

  const openReportModal = useCallback(() => setIsOpenReport(true), []);
  const closeReportModal = useCallback(() => setIsOpenReport(false), []);

  const handleNext = useCallback(() => {
    setCurrent(prevState => prevState + 1);
  }, [tabsData, current]);

  const isDisabled = useMemo(() => {
    if (current === 1) return !assignedList.length;

    return false;
  }, [current, assignedList]);

  useEffect(() => {
    getUsersList({
      role: [
        USER_ROLES.SALES_SUPPORT,
        USER_ROLES.OPERATION_MANAGERS,
        USER_ROLES.A_SM,
        USER_ROLES.TSS,
        USER_ROLES.SYSTEM_FULL_ADMINS,
        USER_ROLES.IT_OPERATIONS_LEADERSHIP,
        USER_ROLES.FINANCE,
        USER_ROLES.DEPOT_CLERK,
        USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
        USER_ROLES.WAREHOUSE,
        USER_ROLES.LOGISTICS,
      ].join(),
      depot: AUTH_USER?.depotName,
      pageSize: 1000,
    }).then(res => {
      setUserList(res?.data?.result?.items);
    });
  }, []);

  return (
    <>
      <NGIPrompt show={!isCancel} />
      <div className="page-content">
        <div className={classes.stepsContainer}>
          <Steps current={current} items={tabs} />
        </div>
        <Container fluid>
          <Card className={classes.container}>
            <div className={classes.content}>{tabs[current].children}</div>
            <Divider />
            <div className={classes.actions}>
              <Space>
                <CustomButton onClick={openConfirmModal} type="primary" size="small" color="outlined" text="Cancel" />
                {current !== 2 ? (
                  <CustomButton
                    roles={[
                      USER_ROLES.SALES_SUPPORT,
                      USER_ROLES.OPERATION_MANAGERS,
                      USER_ROLES.A_SM,
                      USER_ROLES.TSS,
                      USER_ROLES.SYSTEM_FULL_ADMINS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.FINANCE,
                    ]}
                    disabled={isDisabled}
                    onClick={handleNext}
                    type="primary"
                    text="Next Step"
                    size="small"
                  />
                ) : (
                  <CustomButton
                    roles={[
                      USER_ROLES.SALES_SUPPORT,
                      USER_ROLES.OPERATION_MANAGERS,
                      USER_ROLES.A_SM,
                      USER_ROLES.TSS,
                      USER_ROLES.SYSTEM_FULL_ADMINS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.FINANCE,
                    ]}
                    type={depotStatus === "Closed" ? "primary" : "error"}
                    onClick={openReportModal}
                    text={depotStatus === "Closed" ? "OPEN DEPOT" : "CLOSE DEPOT"}
                    size="small"
                  />
                )}
              </Space>
            </div>
          </Card>

          {isReportOpen && (
            <OpenDepotModal agentList={userList} data={tabsData} onClose={closeReportModal} setIsCancel={setIsCancel} />
          )}

          {isOpen && (
            <ConfirmationModal
              onCancel={closeConfirmModal}
              onConfirm={handleCancel}
              description={`Are you sure you want to cancel the ${
                depotStatus === "Closed" ? "opening" : "closing"
              } depot?`}
            />
          )}
        </Container>
      </div>
    </>
  );
};

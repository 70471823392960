import { customDateFormatWithTime, statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import React from "react";
import viewReportIcon from "assets/images/svg/view-report.svg";
import { STATUS_COLOR_MAP } from "./constants";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import ButtonGroup from "components/Common/buttons/ButtonGroup";

export const calibrationReportsListColumns = onViewReport => [
  {
    title: "Report ID",
    dataIndex: "id",
    render: textFormatter,
  },
  {
    title: "Template",
    dataIndex: "templateName",
    render: textFormatter,
  },
  {
    title: "Meters Calibrated",
    dataIndex: "counts",
    render: value => textFormatter(value.succeeded),
  },
  {
    title: "Calibrated By",
    dataIndex: "createdBy",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Calibration Started",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(cellContent && customDateFormatWithTime(cellContent)),
  },
  {
    title: "Status",
    dataIndex: "status",
    width: "150px",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroupContainer>
        <ButtonGroup
          tooltipTitle="View Report Result"
          icon={viewReportIcon}
          handleClick={() => {
            onViewReport(row);
          }}
        />
      </ButtonGroupContainer>
    ),
  },
];

import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import Header from "components/VerticalLayout/Header";

const AuthMiddleWare = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  currentPage,
  isAuthorized,
  hasAccess,
  depotClosed,
  depotOCAccess,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      if (isAuthProtected && !localStorage.getItem("b2c:authUser")) {
        return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />;
      }
      if (depotClosed && depotOCAccess) {
        return <Redirect to={{ pathname: "/no-access" }} />;
      }

      return (
        <>
          {isAuthorized && hasAccess && <Header currentPage={props.match.path} />}
          <Layout>
            <Component {...props} />
          </Layout>
        </>
      );
    }}
  />
);

AuthMiddleWare.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default AuthMiddleWare;

import React, { useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TitleWithDivider from "components/Common/components/TitleWithDivider";
import { camelCaseTextFormatter, customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import { GoogleMap, Marker } from "@react-google-maps/api";
import { Modal, Spin } from "antd";
import taskLocationIcon from "../../../../assets/images/svg/taskLocation.svg";
import customerLocationIcon from "../../../../assets/images/svg/customerLocation.svg";
import { DEFAULT_CENTER } from "pages/depot-management/depots/constants";
import { retrieveAssetPhotos, retrieveTaskDocuments } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import CustomLink from "components/Common/buttons/CustomLink";
import classnames from "classnames";
import { getRepossessionReasons } from "pages/operations/taskDetails/utils";

const useStyles = makeStyles({
  root: {},
  column: {
    width: "50%",
  },
  error: {
    backgroundColor: "rgba(255, 0, 0, 0.15)",
    padding: 10,
    borderRadius: 5,
    marginBottom: 20,

    "&:span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  rowData: {
    display: "flex",
  },
  filesContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    width: "100%",
    color: "#0085FF",
    fontWeight: "500",
  },
  title: {
    fontSize: 13,
    fontWeight: 300,
    marginBottom: 20,
    width: "40%",
  },
  data: {
    fontSize: 13,
    fontWeight: 600,
    marginBottom: 20,
    width: "60%",
    textAlign: "end",
  },
  id: {
    width: "60%",
  },
  withId: {
    width: "20%!important",
  },
  containerWrapper: {
    display: "flex",

    "&>:first-child": {
      paddingRight: 20,
    },
  },
  locationIcon: {
    width: 8,
    marginRight: 4,
    marginTop: -2,
  },
  imgPreview: {
    width: "100%",
    maxHeight: "70vh",
    objectFit: "contain",
  },
  img: {
    marginRight: 5,
    marginBottom: 5,
    "& img": {
      height: 60,
      borderRadius: 6,
      cursor: "pointer",
    },
  },
  rowImgs: {},
  images: {
    display: "flex",
    flexWrap: "wrap",
  },
  link: {
    color: "rgba(0, 133, 255, 1) !important",

    "&:hover": {
      textDecoration: "underline!important",
    },
  },
});

const containerStyle = {
  width: "100%",
  height: "400px",
};

const GeneralInformationTab = ({ task, isLoaded }) => {
  const classes = useStyles();
  const {
    priority,
    assignedBy,
    updatedBy,
    updatedAt,
    closeReason,
    comments,
    customer,
    location: taskLocation,
    investigationSummaryReport,
    customerStatementComments,
    category,
    assets,
    documents,
    parentTask,
    assignedTo,
    assignedToName,
    approvalReason,
    initiateReasons,
    routineId,
  } = task;
  const { status, phone, name, id, location } = customer || {};
  const [previewUrl, setPreviewUrl] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const isHaveLostAssets = useMemo(
    () => !!assets?.find(asset => asset.status === "Lost" || asset.status === "Missing"),
    [assets]
  );

  const onPreviewImg = useCallback(e => {
    const url = e.currentTarget.dataset.url;
    const id = url.split("Thumbnails/")[1];
    setIsOpen(true);
    retrieveAssetPhotos(null, id)
      .then(res => {
        if (res.data.result) {
          setPreviewUrl(res.data.result[0].url);
        } else {
          onClose();
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsOpen(false);
      });
  }, []);

  const onPreviewDocs = useCallback(e => {
    const fileName = e.currentTarget.dataset.file;

    setIsOpen(true);
    retrieveTaskDocuments(task.id, fileName)
      .then(res => {
        if (res.data.result) {
          setPreviewUrl(res.data.result[0].url);
        } else {
          onClose();
        }
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
        setIsOpen(false);
      });
  }, []);

  const onClose = useCallback(() => {
    setPreviewUrl("");
    setIsOpen(false);
  }, []);

  return (
    <div className={classes.root}>
      <TitleWithDivider title="General Information" />
      <div className={classes.containerWrapper}>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Priority</div>
            <div className={classes.data}>{textFormatter(priority)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Assigned By</div>
            <div className={classes.data}>
              <CustomLink underlined target="_blank" to={`/user/${assignedBy}`}>
                {textFormatter(assignedBy)}
              </CustomLink>
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Updated By</div>
            <div className={classes.data}>
              <CustomLink underlined target="_blank" to={`/user/${updatedBy}`}>
                {textFormatter(updatedBy)}
              </CustomLink>
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Assigned To</div>
            <div className={classes.data}>
              <CustomLink underlined target="_blank" to={`/user/${assignedTo}`}>
                {textFormatter(assignedTo)}
              </CustomLink>
            </div>
          </div>
          {approvalReason && (
            <div className={classes.rowData}>
              <div className={classes.title}>Approval Reason</div>
              <div className={classes.data}>{textFormatter(approvalReason)}</div>
            </div>
          )}
          {initiateReasons && (
            <div className={classes.rowData}>
              <div className={classes.title}>Initiate Reasons</div>
              <div className={classes.data}>{textFormatter(initiateReasons)}</div>
            </div>
          )}
          {!!task?.reason && (
            <div className={classes.rowData}>
              <div className={classes.title}>Reason</div>
              <div className={classes.data}>{camelCaseTextFormatter(task?.reason)}</div>
            </div>
          )}
        </div>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Assigned To Name</div>
            <div className={classes.data}>{textFormatter(assignedToName)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Comments</div>
            <div className={classes.data}>{textFormatter(comments?.replace(`"\\n----------\\n"`, "\n"))}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Updated At</div>
            <div className={classes.data}>{textFormatter(customDateFormatWithTime(updatedAt))}</div>
          </div>
          {!!closeReason && (
            <div className={classes.rowData}>
              <div className={classes.title}>Closure Reason</div>
              <div className={classes.data}>
                {category === "Repossession"
                  ? getRepossessionReasons(closeReason)
                  : textFormatter(closeReason?.map((item, i) => (i === closeReason?.length - 1 ? item : `${item}, `)))}
              </div>
            </div>
          )}
          {category === "Investigation" && (
            <div className={classes.rowData}>
              <div className={classes.title}>Investigation Summary Report </div>
              <div className={classes.data}>{textFormatter(investigationSummaryReport)}</div>
            </div>
          )}
        </div>
      </div>

      {category === "Investigation" && assets && (
        <>
          <TitleWithDivider title="Assets Information" />
          <div className={classes.containerWrapper}>
            <div className={classes.column}>
              {assets &&
                assets?.map(item => (
                  <div key={item.id} className={classes.rowData}>
                    <div className={classnames(classes.title, classes.withId)}>{item.type}</div>
                    <div className={classes.id}>
                      <CustomLink className={classes.link} target="_blank" to={`/warehouse/assets/${item.id}`}>
                        {item.id}
                      </CustomLink>
                    </div>
                    <div className={classnames(classes.data, classes.withId)}>{item.status}</div>
                  </div>
                ))}
            </div>
          </div>
        </>
      )}

      <TitleWithDivider title="Customer Information" />
      {category === "Asset Collection" && isHaveLostAssets && (
        <div className={classes.error}>
          <span>Customer was flagged for the lost asset.</span>
        </div>
      )}
      <div className={classes.containerWrapper}>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Customer Name</div>
            <div className={classes.data}>{textFormatter(name)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Customer ID</div>
            <div className={classes.data}>
              {id ? (
                <CustomLink underlined target="_blank" to={`/customers/customer/${id}`}>
                  {id}
                </CustomLink>
              ) : (
                textFormatter(id)
              )}
            </div>
          </div>
          {category === "Investigation" && (
            <div className={classes.rowData}>
              <div className={classes.title}>Record Customer Statement</div>
              <div className={classes.data}>{textFormatter(customerStatementComments)}</div>
            </div>
          )}

          <div className={classes.rowData}>
            <div className={classes.title}>Customer Location</div>
            <div className={classes.data}>
              {location ? (
                <>
                  <img className={classes.locationIcon} src={customerLocationIcon} alt="customer location" />
                  {location?.lat},{location?.lon}
                </>
              ) : (
                textFormatter("")
              )}
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Completed Task Location</div>
            <div className={classes.data}>
              {taskLocation ? (
                <>
                  <img className={classes.locationIcon} src={taskLocationIcon} alt="task location" />
                  {taskLocation?.lat},{taskLocation?.lon}
                </>
              ) : (
                textFormatter("")
              )}
            </div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Routine ID</div>
            <div className={classes.data}>
              {routineId ? (
                <CustomLink underlined target="_blank" to={`/operations/routine/${routineId}`}>
                  {routineId}
                </CustomLink>
              ) : (
                textFormatter(routineId)
              )}
            </div>
          </div>
          <div className={classes.rowData}>
            {isLoaded ? (
              <GoogleMap
                options={{ keyboardShortcuts: false }}
                mapContainerStyle={containerStyle}
                center={
                  location
                    ? {
                        lat: location.lat,
                        lng: location.lon,
                      }
                    : DEFAULT_CENTER
                }
                zoom={9}
              >
                <Marker
                  options={{
                    icon: taskLocationIcon,
                  }}
                  position={{
                    lat: taskLocation?.lat,
                    lng: taskLocation?.lon,
                  }}
                />
                <Marker
                  options={{
                    icon: customerLocationIcon,
                  }}
                  position={{
                    lat: location?.lat,
                    lng: location?.lon,
                  }}
                />
              </GoogleMap>
            ) : (
              <Spin spinning={true} />
            )}
          </div>
        </div>
        <div className={classes.column}>
          <div className={classes.rowData}>
            <div className={classes.title}>Status</div>
            <div className={classes.data}>{textFormatter(status)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Phone Number</div>
            <div className={classes.data}>{textFormatter(phone)}</div>
          </div>
          <div className={classes.rowData}>
            <div className={classes.title}>Uploaded Files</div>
            <div className={classes.filesContainer}>
              {!!documents?.length
                ? documents.map(item => (
                    <div key={item}>
                      <a data-file={item} onClick={onPreviewDocs}>
                        {item}
                      </a>
                    </div>
                  ))
                : "No files"}
            </div>
          </div>
          {parentTask && (
            <div className={classes.rowData}>
              <div className={classes.title}>Parent Task</div>
              <div className={classes.data}>
                <CustomLink target="_blank" className={classes.link} to={`/operations/task/${parentTask}`}>
                  View Details
                </CustomLink>
              </div>
            </div>
          )}
          <div className={classes.rowImgs}>
            <div className={classes.title}>Photos</div>
            <div className={classes.images}>
              {task?.thumbnails
                ? task?.thumbnails.map(item => (
                    <div key={item.url} className={classes.img}>
                      <img data-url={item?.fileName} onClick={onPreviewImg} src={item?.url} alt="preview" />
                    </div>
                  ))
                : "No photos"}
            </div>
          </div>
        </div>
      </div>

      {isOpen && (
        <Modal title="Photo preview" open={isOpen} onCancel={onClose} footer={[]}>
          <Spin spinning={!previewUrl}>
            {previewUrl && <img className={classes.imgPreview} src={previewUrl} alt="preview" />}
          </Spin>
        </Modal>
      )}
    </div>
  );
};

export default GeneralInformationTab;

import React, { useCallback, useEffect, useState } from "react";
import {
  allowedStates,
  allowedStatus,
  routineAssetsColumns,
  SUCCESS_LOST_MESSAGE,
} from "pages/operations/routineDetails/constants";
import NGITable from "components/Common/NGITable";
import CustomButton from "components/Common/buttons/CustomButton";
import NGITooltip from "components/Common/utils/NGITooltip";
import { Spin } from "antd";
import { retrieveAssetUploadUrl, setLostAssets, uploadPictureToS3Bucket } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import { USER_ROLES } from "utils/constants";
import { base64toBuffer, userHasOneOfRoles } from "utils/helpers/functions";
import MarkLostModal from "../../../warehouse/assets/MarkLostModal";

const AssetsTab = ({ routine: { assets = [], status }, updateDetails, isLoading: defaultLoading }) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [isLoading, setLoader] = useState(false);

  const hideRoles = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.A_DSM,
    USER_ROLES.D_SM,
    USER_ROLES.A_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.SALES_SUPPORT,
    USER_ROLES.GENERAL,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.FINANCE,
  ];

  useEffect(() => {
    setLoader(defaultLoading);
  }, [defaultLoading]);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => {
      setSelectedRowKeys(rows);
    },
    renderCell: (checked, record, index, node) => {
      if (!allowedStates.includes(record.state)) {
        return <NGITooltip title="Incorrect status for marking as Lost">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (!allowedStates.includes(record.state)) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const resetSelectedRows = useCallback(() => setSelectedRowKeys([]), []);

  const openModal = useCallback(() => {
    setRowList(selectedRowKeys);
    setShowModal(true);
  }, [selectedRowKeys]);

  const closeModal = useCallback(() => {
    setRowList([]);
    setShowModal(false);
  }, [selectedRowKeys]);

  const handleSubmit = useCallback(
    async (reason, file) => {
      let fileUrl = null;
      setLoader(true);
      if (file) {
        await retrieveAssetUploadUrl("lostAssetDoc", file.fileType).then(async ({ data: { result } }) => {
          const base64Data = base64toBuffer(file.base64);
          fileUrl = await uploadPictureToS3Bucket(result.url, base64Data, file.fileType).then(() => result.fileName);
        });
      }
      await setLostAssets({
        assets: rowList,
        isMissing: false,
        reason,
        ...(fileUrl && { supportingDoc: fileUrl }),
      })
        .then(() => {
          setTimeout(() => {
            updateDetails();
            closeModal();
            resetSelectedRows();
            setLoader(false);
            successMessage(SUCCESS_LOST_MESSAGE);
          }, 3000);
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
    },
    [rowList]
  );

  return (
    <>
      <div className="filters-wrapper with-buttons">
        {status === allowedStatus && (
          <CustomButton
            hideRoles={hideRoles}
            type="primary"
            size="small"
            text="Mark as Lost"
            onClick={openModal}
            disabled={!selectedRowKeys.length}
          />
        )}
      </div>
      <Spin spinning={isLoading}>
        <NGITable
          columns={routineAssetsColumns()}
          data={{
            result: { items: assets },
          }}
          rowSelection={userHasOneOfRoles(hideRoles) || status !== allowedStatus ? null : rowSelection}
          rowKey={record => record.id}
        />
      </Spin>
      {showModal && <MarkLostModal loading={isLoading} onCancel={closeModal} onConfirm={handleSubmit} />}
    </>
  );
};

export default AssetsTab;

import cylinderIcon from "../../assets/images/svg/cylinder-icon.svg";
import stoveIcon from "../../assets/images/svg/stove-icon.svg";
import meterIcon from "../../assets/images/svg/meter-icon.svg";
import batteryIcon from "../../assets/images/svg/battery-icon.svg";
import pipeIcon from "../../assets/images/svg/pipe-icon.svg";
import simIcon from "../../assets/images/svg/sim-cards.svg";

export const assetsCountMapping = [
  {
    name: "Cylinders",
    desc: "Please enter all cylinders counts",
    icon: cylinderIcon,
    items: ["Full", "Empty", "Ready For Filling"],
  },
  {
    name: "Stoves",
    desc: "Please enter all stoves counts",
    icon: stoveIcon,
    items: ["New", "Refurbished", "Damaged"],
  },
  {
    name: "PX Meters",
    desc: "Please enter all meters counts",
    icon: meterIcon,
    items: [
      "Ready for Market",
      "Healthy",
      "Faulty",
      "Awaiting Testing From Market",
      "Verified",
      "Awaiting Configuration",
    ],
  },
  {
    name: "P4 Meters",
    desc: "Please enter all meters counts",
    icon: meterIcon,
    items: [
      "Ready for Market",
      "Healthy",
      "Faulty",
      "Awaiting Testing From Market",
      "Verified",
      "Awaiting Configuration",
    ],
  },
  {
    name: "PX Batteries",
    desc: "Please enter all batteries counts",
    icon: batteryIcon,
    items: ["Empty", "Full"],
  },
  {
    name: "P4 Batteries",
    desc: "Please enter all batteries counts",
    icon: batteryIcon,
    items: ["Empty", "Full"],
  },
  {
    name: "Cards",
    desc: "Please enter all cards counts",
    icon: simIcon,
    items: ["General count"],
  },
  {
    name: "Roll Pipes",
    desc: "Please enter all roll pipes counts",
    icon: pipeIcon,
    items: ["General count"],
  },
];

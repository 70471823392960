import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { createTemplate } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import NGIInput from "components/Common/inputs/NGIInput";
import Title from "antd/lib/typography/Title";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { VALIDATION_RULES } from "./constants";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const NewTemplateModal = ({ isOpen, onClose, update }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);

  const onSubmit = useCallback(values => {
    setLoader(true);
    createTemplate({
      ...values,
      status: "active",
      batteryLevel: Number(values.batteryLevel),
      capacitorVoltage: `${values.capacitorVoltage}v`,
      batteryChangeLevel: Number(values.batteryChangeLevel),
    })
      .then(() => {
        setTimeout(() => {
          setLoader(false);
          successMessage("Template was created successfully");
          onClose();
          update();
        }, 500);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, []);

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          New Template
          <span>Creating a new battery template</span>
        </div>
      }
      open={isOpen}
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm
          form={form}
          name="new-template-modal"
          layout="inline"
          onFinish={onSubmit}
          className={classes.formContainer}
        >
          <div className={classes.modalContent}>
            <Row>
              <Col span={12}>
                <Title level={5}>Template Name*</Title>
                <NGIFormItem name="name" rules={VALIDATION_RULES}>
                  <NGIInput placeholder="Enter Template Name" />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Supplier*</Title>
                <NGIFormItem name="supplier" rules={VALIDATION_RULES}>
                  <NGIInput placeholder="Enter Supplier" />
                </NGIFormItem>
              </Col>
            </Row>
            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Battery Capacity*</Title>
                <NGIFormItem name="batteryCapacity" rules={VALIDATION_RULES}>
                  <NGIInput
                    placeholder="Enter capacity, 0 - 19,000mAh"
                    suffix="mAh"
                    type="number"
                    step="any"
                    min={0}
                    max={19000}
                  />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Battery Capacitor*</Title>
                <NGIFormItem name="capacitorVoltage" rules={VALIDATION_RULES}>
                  <NGIInput
                    placeholder="Enter voltage, 0-3.6v"
                    suffix={"v"}
                    type="number"
                    step="any"
                    min={0}
                    max={3.6}
                  />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Battery Level*</Title>
                <NGIFormItem name="batteryLevel" rules={VALIDATION_RULES}>
                  <NGIInput
                    placeholder="Enter battery level, 0 - 100%"
                    suffix="%"
                    type="number"
                    step="any"
                    min={0}
                    max={100}
                  />
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Battery Change Level*</Title>
                <NGIFormItem name="batteryChangeLevel" rules={VALIDATION_RULES}>
                  <NGIInput
                    placeholder="Enter change level,  400 - 19,000mAh"
                    suffix="mAh"
                    type="number"
                    step="any"
                    min={400}
                    max={19000}
                  />
                </NGIFormItem>
              </Col>
            </Row>

            <Row className={classes.formItem}>
              <Col span={12}>
                <Title level={5}>Battery Model Number*</Title>
                <NGIFormItem name="batteryModelNumber" rules={VALIDATION_RULES}>
                  <NGIInput placeholder="Enter Battery Model Number" />
                </NGIFormItem>
              </Col>
            </Row>
          </div>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="CREATE" size="small" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default NewTemplateModal;

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select } from "antd";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    "& .ant-select-arrow": {
      color: "#444444",
      marginTop: "-8px",
    },
    "& .ant-select-clear": {
      marginTop: "-8px",
      lineHeight: 0,
    },
    "&.ant-select-disabled:not(.ant-select-borderless)": {
      "&>.ant-select-selector": {
        border: "2px solid #d9d9d9!important",
      },
    },
    "&.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
      border: "2px solid #444444",
      borderRadius: 30,
      padding: "7px 20px",
      minHeight: 50,
      display: "flex",
      alignItems: "center",
    },
    "&.ant-select-single.ant-select-show-arrow .ant-select-selection-search": {
      display: "flex",
      alignItems: "center",
    },
    "&.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input": {
      padding: "0 8px",
    },
  },
  small: {
    "&.ant-select:not(.ant-select-customize-input) .ant-select-selector": {
      height: 40,
      minHeight: 40,
    },
    "& .ant-select-arrow": {
      marginTop: "-5px",
    },
  },
  fullWidth: {
    width: "100%",
  },
});

const NGISelect = ({ allowClear = false, size, fullWidth = false, children, ...props }) => {
  const classes = useStyles();
  return (
    <Select
      {...props}
      className={classnames(
        classes.root,
        size === "small" && classes.small,
        props.className,
        fullWidth && classes.fullWidth
      )}
      allowClear={allowClear}
    >
      {children}
    </Select>
  );
};
export default NGISelect;

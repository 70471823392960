import React, { useCallback, useState } from "react";
import { Col, Form, Modal, Row, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import Title from "antd/lib/typography/Title";
import NGIForm from "components/Common/form/NGIForm";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { Option } from "antd/es/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { assignUserToVehicle } from "utils/api";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
});

const AddVehicleModal = ({ onClose, vehicleList, agentList, update }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);

  const onSubmit = useCallback(
    values => {
      const { assignedTo, licencePlate } = values;
      const foundUser = agentList.find(item => item.email === assignedTo);
      setLoader(true);
      assignUserToVehicle(licencePlate, {
        assignedTo: foundUser.email,
        assignedToName: foundUser.name,
      })
        .then(() => {
          setTimeout(() => {
            successMessage("User was assigned to the vehicle");
            setLoader(false);
            onClose();
            update();
          }, 1000);
        })
        .catch(e => {
          defaultNgiErrorMethod(e);
          setLoader(false);
        });
    },
    [agentList]
  );

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Add Vehicle
          <span>Please select vehicle and link an employee</span>
        </div>
      }
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm
          form={form}
          name="new-template-modal"
          layout="inline"
          onFinish={onSubmit}
          className={classes.formContainer}
        >
          <div className={classes.modalContent}>
            <Row>
              <Col span={12}>
                <Title level={5}>Vehicle</Title>
                <NGIFormItem name="licencePlate" rules={[{ required: true, message: "Please enter value" }]}>
                  <NGISelect showSearch className={classes.select} placeholder="Select value">
                    {vehicleList?.map(item => (
                      <Option key={item.licencePlate} value={item.licencePlate}>
                        {item.licencePlate}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
              <Col span={12}>
                <Title level={5}>Linked Employee</Title>
                <NGIFormItem name="assignedTo" rules={[{ required: true, message: "Please select value" }]}>
                  <NGISelect showSearch className={classes.select} placeholder="Select value">
                    {agentList?.map(item => (
                      <Option key={item.id} value={item.email}>
                        {item.name || item.email}
                      </Option>
                    ))}
                  </NGISelect>
                </NGIFormItem>
              </Col>
            </Row>
          </div>
          <Space>
            <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
            <CustomButton htmlType="submit" type="primary" text="ADD VEHICLE" size="small" />
          </Space>
        </NGIForm>
      </Spin>
    </Modal>
  );
};

export default AddVehicleModal;

import { UPDATE_STATUS } from "./actionTypes";

const INIT_STATE = {
  depotStatus: "Closed",
};

const Utils = (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATUS:
      return {
        ...state,
        depotStatus: action.payload,
      };
    default:
      return state;
  }
};

export default Utils;

import { customDateFormatWithTime, statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import React from "react";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import duplicate from "assets/images/svg/duplicate-white.svg";
import close from "assets/images/svg/close-white.svg";
import { STATUS_COLOR_MAP } from "./constants";

export const sensorCalibrationListColumns = (onDuplicate, onArchive) => [
  {
    title: "Name",
    dataIndex: "name",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    title: "Air",
    dataIndex: "air",
    render: textFormatter,
  },
  {
    title: "Propane",
    dataIndex: "propane",
    render: textFormatter,
  },
  {
    title: "Butane",
    dataIndex: "butane",
    render: textFormatter,
  },
  {
    title: "Lookup table",
    dataIndex: "lutName",
    render: textFormatter,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: cellContent => textFormatter(cellContent && customDateFormatWithTime(cellContent)),
  },
  {
    title: "Archived At",
    dataIndex: "archivedAt",
    render: cellContent => textFormatter(cellContent && customDateFormatWithTime(cellContent)),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroupContainer>
        <ButtonGroup
          tooltipTitle="Duplicate"
          icon={duplicate}
          handleClick={() => {
            onDuplicate(row);
          }}
        />
        {row.status === "Active" && (
          <ButtonGroup
            tooltipTitle="Archive Template"
            icon={close}
            handleClick={() => {
              onArchive(row.id);
            }}
          />
        )}
      </ButtonGroupContainer>
    ),
  },
];

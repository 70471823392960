import React, { useCallback, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import classnames from "classnames";

const useStyles = makeStyles({
  root: {
    display: "inline-block",
    "&>  .btn:active": {
      borderColor: "transparent",
    },
  },
  menuItem: {
    padding: 15,

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f5f5f5",
    },
  },
  menuContainer: {
    "div+div": {
      borderBottom: "1px solid red",
    },
  },
  left: {
    left: "-100px !important",
  },
  disabled: {
    opacity: 0.4,
    pointerEvents: "none",
  },
  menuIcon: {
    marginRight: 10,
    height: 16,
  },
});

const NGIDropdownButton = ({ toggleEl, position, menuItems = [], hideRoles }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(e => {
    e?.stopPropagation();
    setIsOpen(prevState => !prevState);
  }, []);
  if (!userHasAdminRole()) {
    if (userHasOneOfRoles(hideRoles)) return null;
  }
  return (
    <Dropdown className={classes.root} isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="btn" tag="span">
        {toggleEl}
      </DropdownToggle>

      {!!menuItems.length && (
        <DropdownMenu className={classnames(classes.menuContainer, classes[position])}>
          {menuItems.map(item => {
            if (item?.roles && !userHasOneOfRoles(item?.roles)) {
              return null;
            }
            if (item?.hideRoles && userHasOneOfRoles(item?.hideRoles)) {
              return null;
            }
            return (
              <div
                key={item.label}
                className={classnames(classes.menuItem, item?.disabled && classes.disabled)}
                onClick={e => {
                  e?.stopPropagation();
                  setIsOpen(false);
                  item?.callback();
                }}
              >
                {item?.icon ? <img className={classes.menuIcon} src={item?.icon} alt={`${item.label}-img`} /> : ""}
                {item.label}
              </div>
            );
          })}
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default NGIDropdownButton;

import React, { useCallback, useEffect, useState } from "react";
import { Form, Select, Input, DatePicker } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE } from "utils/constants";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { getEndOfTheDay, getStartOfTheDay } from "components/Common/formatter";
import { dateFormat, LB_STATUSES, searchOptions } from "pages/stock-management/loadingBays/constants";
import NGISelect from "components/Common/inputs/NGISelect";

const { Option } = Select;
const { RangePicker } = DatePicker;

const useStyles = makeStyles({
  root: {
    width: "250px!important",
  },
});
const LoadingBaysSearch = ({ handleSearch }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [selectedFilter, setSelectedFilter] = useState("");
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.LB);

  useEffect(() => {
    if (!!filters) {
      const property = Object.keys(filters)[0];
      const value = filters[property];
      if (filters?.createdAtFrom) {
        form.setFieldsValue({
          type: "date",
          date: [moment(filters?.createdAtFrom), moment(filters?.createdAtTo)],
          value: null,
        });
        setSelectedFilter("date");
      }
      if (searchOptions.find(item => item.value === property)) {
        form.setFieldsValue({
          type: property,
          value: value,
        });
        setSelectedFilter(property);
      }
    } else {
      form.setFieldsValue({
        type: null,
        value: null,
      });
    }
  }, []);

  const onSearch = useCallback(values => {
    const { pageSize } = getFilters();
    if (values?.type === "date") {
      const filter = { pageSize };
      filter.createdAtFrom = getStartOfTheDay(moment(values?.date[0]));
      filter.createdAtTo = getEndOfTheDay(moment(values?.date[1]));
      handleSearch(filter);
    } else {
      !(values.value && !values.type) &&
        handleSearch(values.type ? { [values.type]: values.value?.trim(), pageSize } : {});
    }
  }, []);

  const onChangeFilter = useCallback(value => {
    setSelectedFilter(value);
    form.setFieldsValue({
      value: null,
    });
  }, []);

  return (
    <div className="custom-filter">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onSearch}>
        <Form.Item name="type">
          <Select
            onChange={onChangeFilter}
            style={{ width: "100%" }}
            tokenSeparators={[","]}
            placeholder="Select type"
            allowClear
          >
            {searchOptions.map(item => (
              <Option key={item.value} value={item.value}>
                {item.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <>
          {selectedFilter === "status" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value">
                <Select showSearch style={{ width: "100%" }} tokenSeparators={[","]} placeholder={"Select value"}>
                  {Object.keys(LB_STATUSES).map(item => (
                    <Option key={item} value={LB_STATUSES[item]}>
                      {LB_STATUSES[item]}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          {selectedFilter === "date" && (
            <Form.Item
              name="date"
              rules={[{ required: true, message: "Please select date range" }]}
              className={classes.root}
            >
              <RangePicker inputReadOnly={true} format={dateFormat} size={"default"} />
            </Form.Item>
          )}

          {selectedFilter === "location" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
                <Input placeholder="Enter Depot Name or Trip Id" allowClear title="Enter Depot Name or Trip Id" />
              </Form.Item>
            </>
          )}
          {selectedFilter === "assetGroup" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="value" rules={[{ required: true, message: "Please enter value" }]}>
                <NGISelect showSearch size="default" placeholder="Select type">
                  {["Cylinder", "Meter"].map(
                    item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ),
                    this
                  )}
                </NGISelect>
              </Form.Item>
            </>
          )}
        </>

        <CustomButton type="primary" htmlType="submit" size="small" text="Search" />
      </Form>
    </div>
  );
};

export default LoadingBaysSearch;

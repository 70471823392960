import React, { useCallback, useState } from "react";
import { Modal, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import NGITextArea from "components/Common/inputs/NGITextArea";

const useStyles = makeStyles({
  root: {
    width: "400px!important",

    "& .ant-modal-header": {
      padding: "24px 24px 0 !important",
    },

    "& .ant-modal-body": {
      padding: "10px 24px !important",
    },
  },
  description: {
    whiteSpace: "pre-wrap",
    padding: "0 10px",
  },
  comment: {
    marginBottom: 15,
  },
});

const ConfirmationModal = ({
  onConfirm,
  onCancel,
  title = "Confirmation",
  description = "Are you sure?",
  cancelBtnTitle = "No",
  confirmBtnTitle = "Yes",
  withComment = false,
  isCommentRequired = false,
  loading = false,
  commentLength,
}) => {
  const classes = useStyles();
  const [comment, setComment] = useState("");

  const onCommentChange = useCallback(e => setComment(e.target.value), []);

  const handleConfirm = useCallback(() => {
    onConfirm(comment);
    onCancel();
  }, [comment]);

  return (
    <Modal className={classes.root} open={true} closable={false} title={title} onCancel={onCancel} footer={[]}>
      <Spin spinning={loading}>
        <p className={classes.description}>{description}</p>
        {withComment && (
          <NGITextArea
            className={classes.comment}
            rows={5}
            maxlength={commentLength}
            placeholder="Please add your reason"
            onChange={onCommentChange}
          />
        )}
        <Space>
          <CustomButton onClick={onCancel} size="small" type="primary" color="outlined" text={cancelBtnTitle} />
          <CustomButton
            disabled={isCommentRequired && !comment}
            onClick={handleConfirm}
            size="small"
            type="primary"
            text={confirmBtnTitle}
          />
        </Space>
      </Spin>
    </Modal>
  );
};

export default ConfirmationModal;

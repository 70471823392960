import moment from "moment";
import { customDateFormatWithTime } from "components/Common/formatter";
import { getFlatDataArray } from "pages/meter-management/maintenanceReports/utils";
import * as xlsx from "xlsx";
import React from "react";
import { AUTH_USER, USER_ROLES } from "utils/constants";
import CustomLink from "components/Common/buttons/CustomLink";

export const formatPhoneNumber = phone => {
  if (phone === null || phone === undefined) return;
  return phone.startsWith("254") ? `+${phone}` : phone.replace("0", "+254");
};

export const formatString = text => {
  if (text === null) return;
  return text
    .toLowerCase()
    .split(/ /g)
    .map(word => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
    .join(" ");
};

export const getTrimmedObjectValues = obj => {
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof obj[key] === "string") {
      obj[key] = value?.trim();
    }
  });
  return obj;
};

export const handleFocusChange = event => {
  if (event.key.toLowerCase() === "enter") {
    const form = event.target.form;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    event.preventDefault();
  }
};

export const disableEnterMinus = e => {
  if (e.key === "-") {
    e.preventDefault();
  }
};
export const disablePaste = e => {
  e.preventDefault();
  return false;
};

export const generateXLS = (data, fileName, extension, isArray = false) => {
  const normalizedData = isArray ? data : getFlatDataArray(data);
  const ws = isArray ? xlsx.utils.aoa_to_sheet(data) : xlsx.utils.json_to_sheet(normalizedData);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFile(wb, `${fileName}${!!extension ? `.${extension}` : ""}`);
};

export const generateXLSFromBase64 = (data, fileName, extension) => {
  const buffer = Buffer.from(data, "base64");
  const wb = xlsx.read(buffer, { type: "buffer" });
  const ws = wb.SheetNames;
  xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
  xlsx.writeFile(wb, `${fileName}${!!extension ? `.${extension}` : ""}`);
};

export const formatName = text => {
  if (text === null) return;
  return text
    .split(/(?=[A-Z])/)
    .map((word, item) => {
      if (item === 0) return `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`;
      return `${word.toLowerCase()}`;
    })
    .join(" ");
};

export const squareKmFormatter = value => <span>{Number(value).toFixed(2)} km&#178;</span>;

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const calculateDiff = (a, b) => getPercentage(a - b, a + b);

export const getPercentage = (count, total) => {
  if (count === 0 && total === 0) return 0;
  return Math.round((parseInt(count) / parseInt(total)) * 100 * 10) / 10;
};

export const getFullName = obj => formatString(`${obj.first_name} ${obj.last_name}`);

export const getFormattedKeyName = key => {
  if (key === "removableIccid") return "Removable Sim Card ICCID";
  if (key === "iccid") return "ICCID";
  if (key === "ASM") return "ASM";
  const formattedKey = key.replace(/([A-Z])/g, " $1").trim();
  return formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};

export const getFormattedDataByName = (name, value) => {
  switch (name) {
    case "customerId":
      return (
        <CustomLink underlined target="_blank" to={`/customers/customer/${value}`}>
          {value}
        </CustomLink>
      );
    case "maintainedBy":
    case "createdBy":
    case "updatedBy":
    case "assigneeId":
      return (
        <CustomLink underlined target="_blank" to={`/user/${value}`}>
          {value}
        </CustomLink>
      );
    case "serial":
      return (
        <CustomLink underlined target="_blank" to={`/warehouse/assets/${value}?t=serialNumber`}>
          {value}
        </CustomLink>
      );
    case "calibratedAt":
    case "collectedAt":
    case "updatedAt":
    case "createdAt":
    case "maintainedAt":
    case "plannedVisitDatetime":
    case "transactionDateTime":
      return moment(value).format("YYYY-MM-DD HH:mm:ss");
    case "document":
      return `${value?.type}: ${value?.number}`;
    case "location":
      if (value.hasOwnProperty("district")) {
        return `${value.district} ${value.street}`;
      }
      if (value.hasOwnProperty("lat")) {
        return `${value?.lat}, ${value?.lon}`;
      }
      return "-";
    case "isConfigured":
    case "hasCertificate":
      return value ? "YES" : "NO";
    case "inputData":
      return typeof value === "object"
        ? Object.keys(value).map(key => {
            const itemValue = key.includes("Date") ? customDateFormatWithTime(value[key]) : value[key];

            if (key === "customerId" || key === "meterChangeDate") return null;
            return (
              <div key={key}>
                {getFormattedKeyName(key)}:<span style={{ fontWeight: "normal" }}> {itemValue} </span>
              </div>
            );
          })
        : value;
    case "depots":
      return value.map(item =>
        item.toLowerCase() === "all depots" ? (
          <div key={`depot-index-${item}`}>{item}</div>
        ) : (
          <CustomLink key={`depot-index-${item}`} underlined to={`/depots/depot?name=${item}`} target={"_blank"}>
            {`${item} `}
          </CustomLink>
        )
      );
    case "products":
      return (
        <div>
          {value.map(item => (
            <div key={`product-index-${item?.product}`}>{`${item?.product} owned by ${
              item?.isOwnedByCustomer === true ? "Customer" : "Company"
            }`}</div>
          ))}
        </div>
      );
    case "testingData":
    case "packagingData":
    case "factoryAcceptanceTestData":
    case "configurationData":
    case "returnData":
    case "repairData": {
      if (!value) return "-";

      let formattedString = "";
      if (typeof value === "string") return value;

      for (const key in value) {
        if (!!value[key] || value[key].length) {
          formattedString += `${key?.charAt(0)?.toUpperCase() + key.slice(1)}: ${
            JSON.stringify(value[key])?.toLowerCase() || "-"
          }; `;
        }
      }
      return formattedString;
    }

    default:
      return value;
  }
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const uuidv4 = () =>
  ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );

export const base64toBuffer = base64 => new Buffer.from(base64.replace(/^data:image\/\w+;base64,/, ""), "base64");

export const base64toBinary = base64 => new Buffer.from(base64.replace(/^data:application\/pdf;base64,/, ""), "base64");

export const validateEmail = email =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const extractPromisesResult = results =>
  results.map(item => {
    if (item.status === "fulfilled") {
      return item.value;
    }
    return {};
  });

export const covertObjWithNumbers = (values = {}, fields = []) => {
  const newObject = { ...values };
  fields.forEach(item => {
    const numberValue = Number(newObject[item]);
    if (!isNaN(numberValue)) {
      newObject[item] = numberValue;
    }
  });
  return newObject;
};

export const randomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const downloadURI = (uri, name) => {
  const link = document.createElement("a");
  link.download = name;
  link.href = uri;
  link.click();
};

export const dateAgo = date => {
  const startDate = new Date(date);
  const endDate = new Date();

  const diffDate = new Date(new Date() - startDate);

  const yearDiff = diffDate.toISOString().slice(0, 4) - 1970;
  const monthDiff = diffDate.getMonth();
  const dayDiff = diffDate.getDate() - 1;

  const msec = endDate - startDate;
  const mins = Math.floor(msec / 60000);
  const hrs = Math.floor(mins / 60);

  let label = "";
  if (mins <= 30) {
    label += "Just Now";
  } else if (hrs <= 24) {
    label += "Just Today";
  } else {
    if (yearDiff > 0) {
      label += yearDiff > 1 ? `${yearDiff} years, ` : `${yearDiff} year, `;
    }

    if (monthDiff > 0) {
      label += monthDiff > 1 ? `${monthDiff} months, ` : `${monthDiff} month, `;
    }

    if (dayDiff > 0) {
      label += dayDiff > 1 ? `${dayDiff} days ` : `${dayDiff} day `;
    }
  }

  return label;
};

export const userHasOneOfRoles = roles =>
  roles?.some(role =>
    AUTH_USER?.roles
      ?.trim()
      ?.split(",")
      ?.some(r => r === role)
  );

export const userHasAdminRole = () => AUTH_USER?.roles?.includes(USER_ROLES.SYSTEM_FULL_ADMINS);

export const removeSpaces = (text = "") => text.replace(/\s/g, "");

export const printFrame = (frameName, content) => {
  const printFrame = document.getElementById(frameName).contentWindow;
  printFrame.document.open();
  printFrame.document.write(content);
  printFrame.document.close();
  printFrame.focus();
  printFrame.print();
  printFrame.document.write("");
};

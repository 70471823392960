import React, { useCallback, useMemo, useState } from "react";
import { Col, Divider, Form, Modal, Row, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "../../../components/Common/form/NGIFormItem";
import { VALIDATION_RULES } from "../../SMS-management/SMS-bulk/NewBulkSMS/constants";
import NGIInput from "../../../components/Common/inputs/NGIInput";
import NGITextArea from "../../../components/Common/inputs/NGITextArea";
import NGIForm from "../../../components/Common/form/NGIForm";
import { Option } from "antd/lib/mentions";
import NGISelect from "../../../components/Common/inputs/NGISelect";
import { AUTH_USER, USER_ROLES } from "../../../utils/constants";
import { createNotification } from "../../../utils/api";
import { defaultNgiErrorMethod, successMessage } from "../../../components/Common/responses/message";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  actions: {
    padding: 10,
    borderLeft: "1px solid #d5d5d5cc",
  },
  removeBtn: {
    cursor: "pointer",
  },
  userItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    backgroundColor: "#F5F5F5",
    marginBottom: 10,
  },
  userItemEmail: {
    padding: 10,
    fontWeight: 600,
    fontSize: 13,
  },
  addUserBtn: {
    marginTop: 20,
    borderRadius: 25,
    border: "2px dashed #9FD1FF",
    backgroundColor: "#F5F5F5",
    fontWeight: 600,
    padding: 13,
    textAlign: "center",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: "none",
  },
});

const SendNotificationModal = ({ onClose }) => {
  const classes = useStyles();
  const [isLoading, setLoader] = useState(false);
  const [form] = Form.useForm();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const onSave = useCallback(() => form.submit(), [form]);

  const onSubmit = useCallback(values => {
    setLoader(true);

    const { roles, title, message } = values;
    const preparedData = {
      depot: AUTH_USER.depotName,
      title,
      message,
      recipientsRoles: roles.includes("all") ? Object.keys(USER_ROLES).map(item => USER_ROLES[item]) : roles,
    };
    createNotification(preparedData)
      .then(() => {
        onClose();
        setLoader(false);
        successMessage("Notifications were sent successfully");
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const openConfirmationModal = useCallback(e => {
    e.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const onRolesChange = useCallback(
    values => {
      if (values.includes("all") && values[0] !== "all") {
        form.setFieldValue("roles", ["all"]);
      } else {
        form.setFieldValue("roles", values.length > 1 ? values.filter(item => item !== "all") : values);
      }
    },
    [form]
  );

  const rolesToShow = useMemo(
    () => [
      USER_ROLES.TECHNICIANS,
      USER_ROLES.MTSR,
      USER_ROLES.TRAINING,
      USER_ROLES.WAREHOUSE,
      USER_ROLES.LOGISTICS,
      USER_ROLES.LT,
      USER_ROLES.PRODUCT_TECHNICIANS,
      USER_ROLES.DEPOT_CLERK,
      USER_ROLES.TSS,
      USER_ROLES.SYSTEM_FULL_ADMINS,
      USER_ROLES.SECURITY,
    ],
    []
  );
  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Send Notification
          <span>Send bulk notification to the team</span>
        </div>
      }
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <NGIForm name="sendDepotNotification" form={form} onFinish={onSubmit}>
          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>Notification Title</Title>
              <NGIFormItem name="title" rules={VALIDATION_RULES}>
                <NGIInput placeholder="Enter Notification Title" maxLength={50} />
              </NGIFormItem>
            </Col>
          </Row>

          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>Notification Message</Title>
              <NGIFormItem name="message" rules={VALIDATION_RULES}>
                <NGITextArea maxLength={200} rows={4} placeholder="Enter Notification Message" />
              </NGIFormItem>
            </Col>
          </Row>

          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>Recipients</Title>
              <NGIFormItem name="roles" rules={VALIDATION_RULES}>
                <NGISelect
                  onChange={onRolesChange}
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  mode="multiple"
                  placeholder="Select roles"
                  allowClear
                >
                  {rolesToShow.map(item => (
                    <Option key={item} value={item}>
                      {item}
                    </Option>
                  ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          </Row>

          <Divider />
          <br />
          <Space>
            <CustomButton color="outlined" onClick={onClose} text="Cancel" size="small" />
            <CustomButton type="primary" onClick={openConfirmationModal} text="SEND NOTIFICATION" size="small" />
          </Space>
        </NGIForm>
      </Spin>

      {isConfirmationOpen && (
        <ConfirmationModal
          description={`Are you sure you want to send notification?`}
          onCancel={closeConfirmationModal}
          onConfirm={onSave}
        />
      )}
    </Modal>
  );
};

export default SendNotificationModal;

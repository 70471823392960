export const DEFAULT_PAGE_SIZE = 10;
export const GOOGLE_MAPS_API_KEY = "AIzaSyC98Ydz_EyBJpc47rLyjmjveU4i7_pb03A";

export const FILTER_PAGE = {
  METER: "METER",
  AWAITING_TESTING: "AWAITING_TESTING",
  AWAITING_TESTING_CONFIG: "AWAITING_TESTING_CONFIG",
  READY_FOR_REPAIR: "READY_FOR_REPAIR",
  MAINTENANCE_REPORTS: "MAINTENANCE_REPORTS",
  METER_BOX: "METER_BOX",
  SIM_CARDS: "SIM_CARDS",
  SIM_CARD_REPORTS: "SIM_CARD_REPORTS",
  STOVE_REPAIR: "STOVE_REPAIR",
  PRICE: "PRICE",
  TASK: "TASK",
  TASK_CANCELLED: "TASK_CANCELLED",
  TASK_INVESTIGATION: "TASK_INVESTIGATION",
  TASK_REQUESTS: "TASK_REQUESTS",
  CUSTOMER_UPDATE_REQUESTS: "CUSTOMER_UPDATE_REQUESTS",
  VALIDATION_TASK_REQUESTS: "VALIDATION_TASK_REQUESTS",
  TASK_ARCHIVE: "TASK_ARCHIVE",
  REFUND_REQUESTS: "REFUND_REQUESTS",
  OUTGOING_LOGS: "OUTGOING_LOGS",
  ROUTINE_TASK: { Open: "ROUTINE_TASK", Close: "ROUTINE_CLOSED", Suspended: "ROUTINE_SUSPENDED" },
  ASSET: "ASSET",
  CUSTOMERS: "CUSTOMERS",
  USERS: "USERS",
  REPOSSESSED_CUSTOMERS: "REPOSSESSED_CUSTOMERS",
  SUSPENDED_CUSTOMERS: "SUSPENDED_CUSTOMERS",
  LEADS: "LEADS",
  NOT_ASSIGNED_LEADS: "NOT_ASSIGNED_LEADS",
  PROSPECTS: "PROSPECTS",
  COOKING_SESSION: "COOKING_SESSION",
  PACKAGES: "PACKAGES",
  UNPROCESSED_PAYMENTS: "UNPROCESSED_PAYMENTS",
  FAILED_PAYMENTS: "FAILED_PAYMENTS",
  DEPOT: "DEPOT",
  TEAMS: "TEAMS",
  CUSTOMERS_TRANSACTION_BOOK: "CUSTOMERS_TRANSACTION_BOOK",
  CYLINDER_REPAIR: "CYLINDER_REPAIR",
  FROM_MARKET_REPORTS: "FROM_MARKET_REPORTS",
  TO_MARKET_REPORTS: "TO_MARKET_REPORTS",
  ASSET_REQUEST_HISTORY: "ASSET_REQUEST_HISTORY",
  TAM_REQUESTS: "TAM_REQUESTS",
  STO: "STO",
  TRIP: "TRIP",
  LB: "LB",
  SURVEY: "SURVEY",
  VEHICLE: "VEHICLE",
  CAMPAIGN: "CAMPAIGN",
  CONTRACT_VERSIONS: "CONTRACT_VERSIONS",
  MARKETING_INITIATIVES: "MARKETING_INITIATIVES",
  FIRMWARE_UPDATE_PROCESSES: "FIRMWARE_UPDATE_PROCESSES",
  METERS_FIRMWARE_HISTORY: "METERS_FIRMWARE_HISTORY",
  ASSETS_IN_TRUCK: "ASSETS_IN_TRUCK",
  MT_BOXES: "MT_BOXES",
  MT_PALLETS: "MT_PALLETS",
  MT_ORDERS: "MT_ORDERS",
  BATTERY_TEMPLATES: "BATTERY_TEMPLATES",
  BATTERIES: "BATTERIES",
  CALIBRATION_TEMPLATES: "CALIBRATION_TEMPLATES",
  CALIBRATION_REPORTS: "CALIBRATION_REPORTS",
};

export const USER_ROLES = {
  SYSTEM_FULL_ADMINS: "SystemFullAdmins",
  PRODUCT_TECHNICIANS: "ProductTechnicians",
  PRODUCT_TECHNICIAN_LEAD: "ProductTechLeadership",
  SYSTEM_SUPPORT: "SystemSupport",
  WAREHOUSE: "Warehouse",
  OPERATION_MANAGERS: "OperationManagers",
  DEPOT_CLERK: "DepotClerk",
  A_DSM: "ADSM",
  D_SM: "DSM",
  A_SM: "ASM",
  SALES_SUPPORT: "Sales",
  CUSTOMER_CARE: "CustomerCare",
  CUSTOMER_CARE_LEADERSHIP: "CustomerCareLeadership",
  TRAINING: "Training",
  INTERNAL_AUDITOR: "InternalAuditor",
  TECHNICIANS: "Technicians",
  TSS: "TSS",
  MTSR: "MobileTechnicians",
  GENERAL: "General",
  IT_OPERATIONS: "ITOperations",
  IT_OPERATIONS_LEADERSHIP: "ITOperationsLeadership",
  BUSINESS_DEV: "BusinessDev",
  FINANCE: "Finance",
  LOGISTICS: "Logistics",
  SECURITY: "Security",
  METER_ENGINEER: "MeterEngineer",
  METER_ENGINEER_LEADERSHIP: "MeterEngineerLeadership",
  LT: "Riders",
};

export const AUTH_USER = JSON.parse(localStorage.getItem("b2c:authUser"));
export const USER_DEPOT_DATA = JSON.parse(localStorage.getItem("b2c:userDepotData"));
export const USER_CURRENCY = localStorage.getItem("b2c:userCurrency");
export const NATIONAL_ID_LENGTH_MIN = localStorage.getItem("b2c:minNationalIdLength") || 8;
export const NATIONAL_ID_LENGTH_MAX = localStorage.getItem("b2c:maxNationalIdLength") || 9;
export const USER_TENANT_NAME = localStorage.getItem("b2c:userTenantName");
export const USER_TENANT = localStorage.getItem("b2c:userTenant");
export const TENANT_PHONE_MASK = localStorage.getItem("b2c:phonePlaceholder") || "***";

export const USER_LIST_PARAMS = {
  role: [USER_ROLES.TECHNICIANS, USER_ROLES.MTSR].join(),
  depot: AUTH_USER?.depotName,
  pageSize: 500,
};

export const ENGINE_VERSION = {
  V1: "v1",
  V2: "v2",
  V3: "v3",
};

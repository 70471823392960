import { assetsCountMapping } from "pages/depot-dashboard/variables";

export const getMappingObject = () =>
  assetsCountMapping.reduce((a, item) => {
    a[item.name] = item.items.reduce((obj, i) => {
      obj[i] = 0;
      return obj;
    }, {});
    return a;
  }, {});

export const prepareData = (data, status, recipients) => {
  const assets = [];
  Object.keys(data).forEach(item =>
    Object.keys(data[item]).forEach(k => {
      assets.push({
        assetGroup: item,
        assetCondition: k,
        qty: data[item][k],
      });
    })
  );
  return {
    operationalStatus: status === "Opened" ? "Closed" : "Opened",
    assets,
    recipients,
  };
};

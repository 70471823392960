import React, { useCallback, useState } from "react";
import { Card } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import depotIcon from "../../assets/images/svg/depot-icon.svg";
import classnames from "classnames";
import CustomButton from "components/Common/buttons/CustomButton";
import { DepotButton } from "pages/depot-dashboard/DepotButton";
import depotStats from "../../assets/images/svg/depot-stats.svg";
import depotReminder from "../../assets/images/svg/depot-reminder.svg";
import taskRequest from "../../assets/images/svg/task-requests.svg";
import openProspect from "../../assets/images/svg/user-folder.svg";
import redMark from "../../assets/images/svg/red-mark.svg";
import { history } from "store";
import { USER_DEPOT_DATA, USER_ROLES } from "utils/constants";
import { useSelector } from "react-redux";
import { UtilsSelector } from "store/utils/selectors";
import SendNotificationModal from "./sendNotification/SendNotificationModal";
import CloseDepotModal from "pages/depot-dashboard/CloseDepotModal";

const useStyles = makeStyles({
  container: {
    borderRadius: 30,
  },
  depotInfoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 40,
  },
  depotTitle: {
    fontSize: 24,
    fontWeight: 700,
    margin: "10px 0",
  },
  status: {
    padding: "3px 10px",
    fontSize: 11,
    fontWeight: 700,
    borderRadius: 2,
    textTransform: "uppercase",
  },
  Closed: {
    backgroundColor: "#FFDADA",
    color: "#FF0000",
  },
  Opened: {
    backgroundColor: "#D8FFE3",
    color: "#17C347",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid rgba(0, 0, 0, 0.05)",
    padding: "35px 0px",
  },
  buttonsContainer: {
    display: "flex",
    position: "relative",
    flexWrap: "wrap",
    gap: 15,
    padding: "20px 40px",
    paddingTop: 0,
    justifyContent: "center",
  },
  depotClosedContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backdropFilter: "blur(2px)",
  },
  depotClosedMsg: {
    display: "flex",
    borderRadius: 15,
    padding: 40,
    backgroundColor: "#FFF",
    boxShadow: "0px 20px 40px 0px #0000001A",
  },
  closedTitle: {
    fontSize: 18,
    fontWeight: 500,
    marginLeft: 10,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
      display: "flex",
      flexDirection: "column",
    },
  },
});

export const DepotDashboardDetails = () => {
  const classes = useStyles();
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [showCloseModal, setShowCloseModal] = useState(false);
  const { depotStatus } = useSelector(UtilsSelector);
  const openDepot = useCallback(() => history.push("/asset-stock"), []);

  const openSendNotification = useCallback(() => setIsNotificationOpen(true), []);
  const closeSendNotification = useCallback(() => setIsNotificationOpen(false), []);

  const openCloseModal = useCallback(() => setShowCloseModal(true), []);
  const hideCloseModal = useCallback(() => setShowCloseModal(false), []);

  return (
    <Card className={classes.container}>
      <div className={classes.depotInfoContainer}>
        <img src={depotIcon} alt="depot" />
        <div className={classes.depotTitle}>{USER_DEPOT_DATA?.name}</div>
        <div className={classnames(classes.status, classes[depotStatus])}>{depotStatus}</div>
      </div>
      <div className={classes.buttonsContainer}>
        <DepotButton
          name="Live Depot Statistics"
          decs="Keep track on all customers, tasks and other live data from here."
          link="/stats"
          icon={depotStats}
          badgeCount={0}
        />
        <DepotButton
          name="Task Requests"
          decs="Keep track on all customers, tasks and other live data from here."
          link="/operations/task-requests"
          icon={taskRequest}
          badgeCount={0}
        />
        <DepotButton
          name="Open Prospects"
          decs="Keep track on all customers, tasks and other live data from here."
          link="/operations/prospects"
          icon={openProspect}
          badgeCount={0}
        />
        <DepotButton
          name="Send Notifications"
          decs="Keep track on all customers, tasks and other live data from here."
          onClick={openSendNotification}
          icon={depotReminder}
          badgeCount={0}
        />

        {depotStatus === "Closed" && (
          <div className={classes.depotClosedContainer}>
            <div className={classes.depotClosedMsg}>
              <img src={redMark} alt="alert" />
              <div className={classes.closedTitle}>
                Please open depot to start
                <span>Please open depot to start operations and see live data statistics</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.actionContainer}>
        {depotStatus === "Closed" ? (
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            type="primary"
            onClick={openDepot}
            text="OPEN DEPOT"
            size="small"
          />
        ) : (
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            type="error"
            onClick={openCloseModal}
            text="CLOSE DEPOT"
            size="small"
          />
        )}
      </div>

      {showCloseModal && <CloseDepotModal onClose={hideCloseModal} />}

      {isNotificationOpen && <SendNotificationModal onClose={closeSendNotification} />}
    </Card>
  );
};

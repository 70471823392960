import React, { useCallback, useState } from "react";
import { Col, Modal, Row, Space, Spin } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { openDepot } from "utils/api";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import closeIcon from "assets/images/svg/closeSmall.svg";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { AUTH_USER, USER_DEPOT_DATA, USER_ROLES } from "utils/constants";
import classnames from "classnames";
import { prepareData } from "../../utils";
import { useHistory } from "react-router";
import { updateStatusSuccess } from "store/utils/actions";
import { useDispatch, useSelector } from "react-redux";
import { UtilsSelector } from "store/utils/selectors";
import { Option } from "antd/lib/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { Container } from "reactstrap";

const useStyles = makeStyles({
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  modalContent: {
    padding: "30px 0",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 24,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    marginTop: 15,
  },
  actions: {
    padding: 10,
    borderLeft: "1px solid #d5d5d5cc",
  },
  removeBtn: {
    cursor: "pointer",
  },
  userItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: 5,
    backgroundColor: "#F5F5F5",
    marginBottom: 10,
  },
  userItemEmail: {
    padding: 10,
    fontWeight: 600,
    fontSize: 13,
  },
  addUserBtn: {
    marginTop: 20,
    borderRadius: 25,
    border: "2px dashed #9FD1FF",
    backgroundColor: "#F5F5F5",
    fontWeight: 600,
    padding: 13,
    textAlign: "center",

    "&:hover": {
      cursor: "pointer",
      opacity: 0.6,
    },
  },
  disabled: {
    opacity: 0.6,
    pointerEvents: "none",
  },
  select: {
    width: "100%",
  },
  flexEnd: {
    display: "flex",
    justifyContent: "end",
  },
  usersContainer: {
    marginTop: 20,
  },
});

const OpenDepotModal = ({ onClose, data, setIsCancel, agentList }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { depotStatus } = useSelector(UtilsSelector);
  const [userList, setUserList] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [showNewUser, setShowNewUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const onSubmit = useCallback(() => {
    setLoader(true);
    const preparedData = prepareData(data.countingTab, depotStatus, userList);
    openDepot(USER_DEPOT_DATA?.id, preparedData)
      .then(({ data }) => {
        const { operationalStatus } = data.result;
        dispatch(updateStatusSuccess(operationalStatus));

        setTimeout(() => {
          successMessage(`Depot ${operationalStatus} successfully`);
          setLoader(false);
          setIsCancel(true);
          history.push("/dashboard");
        }, 1000);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, [userList, depotStatus]);

  const handleDelete = useCallback(
    () => setUserList(prevState => prevState.filter(item => item !== activeUser)),
    [activeUser]
  );

  const openConfirmationModal = useCallback(e => {
    const email = e.currentTarget.dataset.email;
    setActiveUser(email);
  }, []);

  const onAddNewUser = useCallback(() => setShowNewUser(true), []);
  const handleSelectUser = useCallback(user => setSelectedUser(user), []);
  const handleAddUser = useCallback(() => {
    const foundUser = userList.find(item => item === selectedUser);
    if (!foundUser) {
      setUserList(prevState => [...prevState, selectedUser]);
    } else {
      warnMessage("User already added");
    }
    setShowNewUser(false);
    setSelectedUser(null);
  }, [selectedUser, userList]);

  const closeConfirmationModal = useCallback(() => setActiveUser(null), []);

  return (
    <Modal
      title={
        <div className={classes.titleWithDescription}>
          Depot Open Report
          <span>To start operations please select people to share your report with</span>
        </div>
      }
      open
      onCancel={onClose}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <div className={classes.modalContent}>
          <div className={classes.addedUsers}>
            <div className={classnames(classes.userItem, classes.disabled)}>
              <div className={classes.userItemEmail}>{AUTH_USER.email}</div>
            </div>
            {userList.map(item => (
              <div key={item} className={classes.userItem}>
                <div className={classes.userItemEmail}>{item}</div>
                <div className={classes.actions}>
                  <img
                    className={classes.removeBtn}
                    data-email={item}
                    onClick={openConfirmationModal}
                    src={closeIcon}
                    alt="remove"
                  />
                </div>
              </div>
            ))}
          </div>
          {showNewUser && (
            <Container className={classes.usersContainer}>
              <Row className="align-items-center">
                <Col span={20}>
                  <NGISelect
                    showSearch
                    placeholder="Select user"
                    value={selectedUser}
                    className={classes.select}
                    onChange={handleSelectUser}
                  >
                    {agentList?.map(item => (
                      <Option key={item?.email} value={item?.email}>
                        {item?.email}
                      </Option>
                    ))}
                  </NGISelect>
                </Col>
                <Col span={4} className={classes.flexEnd}>
                  <CustomButton
                    disabled={!selectedUser}
                    type="primary"
                    onClick={handleAddUser}
                    text="Add"
                    size="small"
                  />
                </Col>
              </Row>
            </Container>
          )}

          {!showNewUser && (
            <div className={classes.addUserBtn} onClick={onAddNewUser}>
              + Add New Recipient
            </div>
          )}
        </div>
        <Space>
          <CustomButton onClick={onClose} type="primary" size="small" color="outlined" text="Cancel" />
          <CustomButton
            roles={[
              USER_ROLES.SALES_SUPPORT,
              USER_ROLES.OPERATION_MANAGERS,
              USER_ROLES.A_SM,
              USER_ROLES.TSS,
              USER_ROLES.SYSTEM_FULL_ADMINS,
              USER_ROLES.IT_OPERATIONS_LEADERSHIP,
              USER_ROLES.FINANCE,
            ]}
            onClick={onSubmit}
            type="primary"
            text="SEND REPORT"
            size="small"
          />
        </Space>
      </Spin>

      {activeUser && (
        <ConfirmationModal
          description={`Are you sure you want to remove the ${activeUser} from the report?`}
          onCancel={closeConfirmationModal}
          onConfirm={handleDelete}
        />
      )}
    </Modal>
  );
};

export default OpenDepotModal;

import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Col, Container } from "reactstrap";
import { otherAssetListColumns, assetListCardColumns, assetListCylinderColumns } from "./assetListColumns";
import Filters from "components/Common/filters/warehouse/assetFilter";
import {
  resetAssetsValidation,
  retrieveAllAssets,
  retrieveAssetUploadUrl,
  retrieveDictionaries,
  setFoundAssets,
  setLostAssets,
  uploadPictureToS3Bucket,
} from "utils/api";
import { successMessage, defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import AsserSearch from "components/Common/filters/warehouse/assetSearchFilter";
import { getDictionary } from "store/dictionaries/actions";
import { DICTIONARY_TYPES } from "store/dictionaries/constants";
import AddRfidCardsModal from "pages/warehouse/assets/AddRfidCardsModal";
import NGITooltip from "components/Common/utils/NGITooltip";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { AUTH_USER, FILTER_PAGE, USER_ROLES } from "utils/constants";
import moment from "moment";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { base64toBuffer, generateXLS, userHasOneOfRoles } from "utils/helpers/functions";
import NGIDropdownButton from "components/Common/buttons/NGIDropdownButton";
import AddQRCodesModal from "./AddQRCodesModal";
import ButtonGroup from "components/Common/buttons/ButtonGroup";
import ButtonGroupContainer from "components/Common/buttons/ButtonGroupContainer";
import addIcon from "assets/images/svg/plus-circle-white.svg";
import exportIcon from "assets/images/svg/export-white.svg";
import resetIcon from "assets/images/svg/Wrench.svg";
import lost from "assets/images/svg/lost-white.svg";
import found from "assets/images/svg/found-white.svg";
import MarkLostModal from "./MarkLostModal";
import { SUCCESS_LOST_MESSAGE } from "../../operations/routineDetails/constants";

const allowedStates = ["Lost"];

const ALLOW_FOR_TYPES = ["Cylinder", "Meter", "Stove"];

const RetrieveAssets = () => {
  const dispatch = useDispatch();
  const { setFilters, filters } = useStoreFilters(FILTER_PAGE.ASSET);
  const [isLoading, setLoader] = useState(false);
  const [assetList, setAssetList] = useState([]);
  const [group, setGroup] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [openAddRfidModal, setOpenAddRfidModal] = useState(false);
  const [openAddQRModal, setOpenAddQRModal] = useState(false);
  const [assetLocationList, setAssetLocationList] = useState([]);
  const [isLostState, setLostState] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [showLostModal, setShowLostModal] = useState(false);
  const [rowList, setRowList] = useState([]);
  const hideRoles = [
    USER_ROLES.INTERNAL_AUDITOR,
    USER_ROLES.A_DSM,
    USER_ROLES.A_SM,
    USER_ROLES.D_SM,
    USER_ROLES.OPERATION_MANAGERS,
    USER_ROLES.DEPOT_CLERK,
    USER_ROLES.GENERAL,
    USER_ROLES.IT_OPERATIONS,
    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
    USER_ROLES.FINANCE,
    USER_ROLES.TRAINING,
  ];

  useEffect(() => {
    handleOnFilter(!isEmpty(filters) ? filters : { state: "Available" });
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: rows => {
      setSelectedRowKeys(rows);
    },
    renderCell: (checked, record, index, node) => {
      if (userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && !ALLOW_FOR_TYPES.includes(record.typeGroup)) {
        return <NGITooltip title="Available only for Cylinder, Meter and Stove">{node}</NGITooltip>;
      }
      if (!userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && !allowedStates.includes(record.state)) {
        return <NGITooltip title="Incorrect status for marking as Lost">{node}</NGITooltip>;
      }
      return node;
    },
    getCheckboxProps: record => {
      const checkboxProps = {};
      if (userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && !ALLOW_FOR_TYPES.includes(record.typeGroup)) {
        checkboxProps.disabled = true;
      }
      if (!userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && !allowedStates.includes(record.state)) {
        checkboxProps.disabled = true;
      }
      return checkboxProps;
    },
  };

  const otherColumns = [...otherAssetListColumns];
  const cylinderColumns = [...assetListCylinderColumns];
  const cardColumns = [...assetListCardColumns];

  const handleTaskState = () => {
    retrieveDictionaries("assetState").then(res => {
      const arr = res.data.result.items;
      if (arr.length > 0) {
        setStateList(arr);
      } else {
        warnMessage("No asset states are available");
      }
    });
  };

  const handleAssetTypes = () => {
    retrieveDictionaries("assetTypeGroup").then(res => {
      const arr = res.data.result.items;
      if (arr.length > 0) {
        setAssetTypeList(arr);
      } else {
        warnMessage("No asset types are available");
      }
    });
  };

  const handleAssetLocation = () => {
    retrieveDictionaries("assetLocation").then(res => {
      const arr = res.data.result.items;
      if (arr.length > 0) {
        setAssetLocationList(arr);
      } else {
        warnMessage("No asset locations are available");
      }
    });
  };

  useEffect(() => {
    handleTaskState();
    handleAssetTypes();
    handleAssetLocation();
    dispatch(getDictionary(DICTIONARY_TYPES.BATTERY_CONDITION));
    dispatch(getDictionary(DICTIONARY_TYPES.CYLINDER_CONDITION));
    dispatch(getDictionary(DICTIONARY_TYPES.METER_CONDITION));
    dispatch(getDictionary(DICTIONARY_TYPES.STOVE_CONDITION));
    dispatch(getDictionary(DICTIONARY_TYPES.LOST_ASSET_REASONS));
  }, []);
  const handleOnFilter = filterOptions => {
    setLoader(true);
    setFilters(filterOptions);

    retrieveAllAssets({ ...filterOptions })
      .then(res => {
        const {
          result: { items, meta },
        } = res?.data;
        setGroup(filterOptions.group);
        const sortedArray = res.data.result.items.sort((a, b) => b.createdAt - a.createdAt);

        if (items?.length === 0) {
          warnMessage(`${filterOptions.group} not found`);
          setLoader(false);
        }

        setAssetList({ result: { meta, items: sortedArray } } || null);
        setLoader(false);
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  };

  const onOpenAddRfidModal = useCallback(() => setOpenAddRfidModal(true), []);
  const onCloseAddRfidModal = useCallback(() => setOpenAddRfidModal(false), []);

  const onOpenAddQRModal = useCallback(() => setOpenAddQRModal(true), []);
  const onCloseAddQRModal = useCallback(() => setOpenAddQRModal(false), []);

  const onOpenResetModal = useCallback(() => setShowResetModal(true), []);
  const onCloseResetModal = useCallback(() => setShowResetModal(false), []);

  const onOpenLostModal = useCallback(() => setShowLostModal(true), []);
  const onCloseLostModal = useCallback(() => setShowLostModal(false), []);

  const handleReset = useCallback(async () => {
    setLoader(true);
    resetAssetsValidation({
      requestedBy: AUTH_USER?.email,
      assetIds: selectedRowKeys,
    })
      .then(() => {
        setTimeout(() => {
          handleOnFilter(!isEmpty(filters) ? filters : { state: "Available" });
          successMessage("The assets are successfully reset. isValidated flag is 'false'.");
          setLoader(false);
          onCloseResetModal();
          resetSelectedRows();
        }, 500);
      })
      .catch(err => {
        setLoader(false);
        onCloseResetModal();
        defaultNgiErrorMethod(err);
      });
  }, [selectedRowKeys]);

  const resetSelectedRows = useCallback(() => setSelectedRowKeys([]), []);

  const openModal = useCallback(() => {
    setRowList(selectedRowKeys);
    setShowModal(true);
  }, [selectedRowKeys]);

  const closeModal = useCallback(() => {
    setRowList([]);
    setShowModal(false);
  }, [selectedRowKeys]);

  const handleSubmit = useCallback(async () => {
    setLoader(true);
    const normalizedData = rowList.map(item => ({ id: item }));
    await setFoundAssets({ assets: normalizedData })
      .then(() => {
        handleOnFilter(!isEmpty(filters) ? filters : { state: "Available" });
        successMessage("Selected assets were marked as found");
        setLoader(false);
        closeModal();
        resetSelectedRows();
      })
      .catch(err => {
        setLoader(false);
        defaultNgiErrorMethod(err);
      });
  }, [rowList, filters]);

  const handleLostSubmit = useCallback(
    async (reason, file) => {
      let fileUrl = null;
      setLoader(true);
      if (file) {
        await retrieveAssetUploadUrl("lostAssetDoc", file.fileType).then(async ({ data: { result } }) => {
          const base64Data = base64toBuffer(file.base64);
          fileUrl = await uploadPictureToS3Bucket(result.url, base64Data, file.fileType).then(() => result.fileName);
        });
      }
      await setLostAssets({
        assets: selectedRowKeys,
        isMissing: false,
        reason,
        ...(fileUrl && { supportingDoc: fileUrl }),
      })
        .then(() => {
          setTimeout(() => {
            onCloseLostModal();
            handleOnFilter(!isEmpty(filters) ? filters : { state: "Available" });
            resetSelectedRows();
            setLoader(false);
            successMessage(SUCCESS_LOST_MESSAGE);
          }, 3000);
        })
        .catch(err => {
          setLoader(false);
          defaultNgiErrorMethod(err);
        });
    },
    [selectedRowKeys, filters]
  );

  const getReportName = useCallback(() => {
    const { endCursor, pageSize, ...options } = filters;
    return `${Object.keys(options)
      .map(key => `${options[key]}`)
      .join("-")}-${moment().format("YYYY-MM-DD HH:mm")}`;
  }, [filters]);

  const onExport = useCallback(() => generateXLS(assetList?.result?.items, getReportName(), "xlsx"), [assetList]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <ButtonGroupContainer color={"primary"}>
                <NGIDropdownButton
                  hideRoles={[
                    USER_ROLES.TRAINING,
                    USER_ROLES.INTERNAL_AUDITOR,
                    USER_ROLES.A_DSM,
                    USER_ROLES.D_SM,
                    USER_ROLES.OPERATION_MANAGERS,
                    USER_ROLES.DEPOT_CLERK,
                    USER_ROLES.GENERAL,
                    USER_ROLES.IT_OPERATIONS,
                    USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                    USER_ROLES.FINANCE,
                  ]}
                  toggleEl={<ButtonGroup icon={addIcon} color={"primary"} tooltipTitle="New" />}
                  menuItems={[
                    {
                      label: "Generate QR codes",
                      callback: onOpenAddQRModal,
                    },
                    {
                      label: "Generate RFID Cards",
                      callback: onOpenAddRfidModal,
                    },
                  ]}
                />

                <ButtonGroup
                  hideRoles={[USER_ROLES.IT_OPERATIONS, USER_ROLES.IT_OPERATIONS_LEADERSHIP, USER_ROLES.GENERAL]}
                  color={"primary"}
                  handleClick={onExport}
                  tooltipTitle="Export"
                  icon={exportIcon}
                  disabled={!assetList?.result?.items?.length}
                />
                {isLostState && !!selectedRowKeys.length && (
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.TRAINING,
                      USER_ROLES.INTERNAL_AUDITOR,
                      USER_ROLES.A_DSM,
                      USER_ROLES.A_SM,
                      USER_ROLES.D_SM,
                      USER_ROLES.OPERATION_MANAGERS,
                      USER_ROLES.DEPOT_CLERK,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.GENERAL,
                      USER_ROLES.FINANCE,
                    ]}
                    color={"primary"}
                    handleClick={openModal}
                    tooltipTitle="Mark as Found"
                    icon={found}
                  />
                )}

                {userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS]) && !!selectedRowKeys.length && (
                  <ButtonGroup color={"primary"} handleClick={onOpenResetModal} tooltipTitle="Reset" icon={resetIcon} />
                )}
                {!!selectedRowKeys.length && (
                  <ButtonGroup
                    hideRoles={[
                      USER_ROLES.TRAINING,
                      USER_ROLES.INTERNAL_AUDITOR,
                      USER_ROLES.A_DSM,
                      USER_ROLES.A_SM,
                      USER_ROLES.D_SM,
                      USER_ROLES.OPERATION_MANAGERS,
                      USER_ROLES.DEPOT_CLERK,
                      USER_ROLES.IT_OPERATIONS,
                      USER_ROLES.IT_OPERATIONS_LEADERSHIP,
                      USER_ROLES.GENERAL,
                      USER_ROLES.FINANCE,
                    ]}
                    color={"primary"}
                    handleClick={onOpenLostModal}
                    tooltipTitle="Mark as Lost"
                    icon={lost}
                  />
                )}
              </ButtonGroupContainer>
            </div>
            <Filters
              onFilter={handleOnFilter}
              isLoading={isLoading}
              showState={stateList}
              showAssetType={assetTypeList}
              setLostState={setLostState}
            />
            <AsserSearch onFilter={handleOnFilter} isLoading={isLoading} showLocation={assetLocationList} />
          </div>
          <Col>
            <NGITable
              filtersType={FILTER_PAGE.ASSET}
              columns={group === "Cylinder" ? cylinderColumns : group === "Card" ? cardColumns : otherColumns}
              data={assetList}
              isLoading={isLoading}
              updateList={handleOnFilter}
              rowSelection={
                userHasOneOfRoles([USER_ROLES.SYSTEM_FULL_ADMINS])
                  ? rowSelection
                  : isLostState && !userHasOneOfRoles(hideRoles)
                  ? rowSelection
                  : null
              }
              rowKey={record => record.id}
            />
          </Col>
        </Container>
      </div>

      {openAddRfidModal && <AddRfidCardsModal onCancel={onCloseAddRfidModal} />}
      {openAddQRModal && <AddQRCodesModal isOpen={openAddQRModal} onCancel={onCloseAddQRModal} />}

      {showModal && (
        <ConfirmationModal
          loading={isLoading}
          onCancel={closeModal}
          onConfirm={handleSubmit}
          description={"Are you sure to mark these assets as Found?"}
        />
      )}
      {showLostModal && <MarkLostModal loading={isLoading} onCancel={onCloseLostModal} onConfirm={handleLostSubmit} />}
      {showResetModal && (
        <ConfirmationModal
          loading={isLoading}
          onCancel={onCloseResetModal}
          onConfirm={handleReset}
          description="Are you sure you want to reset the 'Is Validated' flag?"
        />
      )}
    </>
  );
};

export default RetrieveAssets;

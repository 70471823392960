export const searchOptions = [
  {
    value: "lutName",
    title: "LUT type",
  },
  {
    value: "date",
    title: "Creation date",
  },
  {
    value: "status",
    title: "Status",
  },
];

export const STATUS_COLOR_MAP = {
  Active: {
    color: "#17C347",
    backgroundColor: "#D8FFE3",
  },
  Archived: {
    color: "#FF0000",
    backgroundColor: "#FFE2E2",
  },
};

import React, { useCallback, useEffect, useState } from "react";
import { Col, Collapse, Divider, Form, Modal, Row, Slider, Space, Spin } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import CustomButton from "components/Common/buttons/CustomButton";
import Title from "antd/lib/typography/Title";
import NGIFormItem from "components/Common/form/NGIFormItem";
import { defaultNgiErrorMethod, successMessage } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import NGIInput from "components/Common/inputs/NGIInput";
import { VALIDATION_RULES } from "pages/SMS-management/SMS-bulk/NewBulkSMS/constants";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";
import { createCalibrationTemplate } from "utils/api";
import { Option } from "antd/lib/mentions";
import NGISelect from "components/Common/inputs/NGISelect";
import { disableEnterMinus, disablePaste } from "utils/helpers/functions";

const { Panel } = Collapse;

const useStyles = makeStyles({
  modal: {
    maxWidth: "900px!important",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span:first-of-type": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  formItem: {
    display: "flex",

    "& .ant-col + .ant-col": {
      paddingLeft: 10,
    },
  },
  withSlider: {
    "& .ant-form-item": {
      margin: 0,
    },
  },
  assetsContainer: {
    width: "100%",
  },
  disabled: {
    pointerEvents: "none",
    opacity: 0.6,
  },
  select: {
    width: "100%",
  },
  success: {
    borderColor: "green",
    fontWeight: 600,
    fontSize: 16,
    "&>input": {
      color: "green",
    },
    "&>span": {
      color: "green",
    },
  },
  error: {
    borderColor: "red",
    fontWeight: 600,
    fontSize: 16,
    "&>input": {
      color: "red",
    },
    "&>span": {
      color: "red",
    },
  },
  collapseList: {
    backgroundColor: "#FFF",
  },
  xyList: {
    display: "flex",
    gap: 10,
    flexWrap: "wrap",
  },
  xyContainer: {
    border: "1px solid #0000000D",
    display: "flex",
    borderRadius: 5,
  },
  index: {
    backgroundColor: "#0000000F",
    textAlign: "center",
    color: "#00000033",
    fontWeight: 600,
    padding: 7,
    width: 42,
    borderRadius: "4px 0 0 4px",
  },
  value: {
    borderLeft: "1px solid #0000000D",
    padding: 7,
    width: 100,

    "&>span": {
      fontWeight: 100,
    },
  },
});

const NewTemplateModal = ({ onClose, updateList, data }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [sum, setSum] = useState(0);
  const [selectedTable, setSelectedTable] = useState(null);

  useEffect(() => {
    if (data) {
      const { air, butane, propane, lutName } = data;
      const sum = (parseInt(air) || 0) + (parseInt(propane) || 0) + (parseInt(butane) || 0);
      setSum(sum);
      onTableChange(lutName);
      form.setFieldsValue(data);
    }
  }, []);

  const {
    list: { sensorCalibrationLookupTables },
  } = useSelector(DictionariesSelector);

  const onTableChange = useCallback(
    value => {
      const selected = sensorCalibrationLookupTables?.find(item => item.name === value);
      setSelectedTable(selected);
    },
    [sensorCalibrationLookupTables]
  );

  const onSave = useCallback(async values => {
    try {
      setIsLoading(true);
      const { name, air, butane, propane, lutName } = values;
      const preparedData = {
        name,
        lutName,
        air: parseInt(air),
        butane: parseInt(butane),
        propane: parseInt(propane),
      };
      createCalibrationTemplate(preparedData)
        .then(() => {
          onClose();
          updateList();
          successMessage("Created successfully");
          setIsLoading(false);
        })
        .catch(err => {
          setIsLoading(false);
          defaultNgiErrorMethod(err);
        });
    } catch (e) {
      setIsLoading(false);
      defaultNgiErrorMethod(e);
    }
  }, []);

  const onSubmit = useCallback(() => {
    form.submit();
    closeConfirmationModal();
  }, [form]);

  const openConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(true);
  }, []);
  const closeConfirmationModal = useCallback(e => {
    e?.preventDefault();
    setIsConfirmationOpen(false);
  }, []);

  const openConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(true), []);
  const closeConfirmCloseModal = useCallback(() => setIsConfirmCloseOpen(false), []);

  const formatter = value => `${value}%`;

  const onFormChange = useCallback(() => {
    const { name, air, propane, butane, lutName } = form.getFieldsValue();
    const sum = (parseInt(air) || 0) + (parseInt(propane) || 0) + (parseInt(butane) || 0);
    if (name && sum === 100 && lutName) setDisabled(false);
    else setDisabled(true);
    setSum(sum);
  }, [form]);

  return (
    <Modal
      title={<div className={classes.infoContainer}>Sensor Calibration Template</div>}
      className={classes.modal}
      open
      onCancel={openConfirmCloseModal}
      closable={false}
      footer={[]}
    >
      <Spin spinning={isLoading}>
        <Form onFieldsChange={onFormChange} name="newBatteries" form={form} onFinish={onSave}>
          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>Template Name*</Title>
              <NGIFormItem name="name" rules={VALIDATION_RULES}>
                <NGIInput placeholder="Enter Template Name" />
              </NGIFormItem>
            </Col>
          </Row>

          <Row className={classnames(classes.formItem, classes.withSlider)}>
            <Col span={7}>
              <Title level={5}>Air*</Title>
              <NGIFormItem name="air">
                <NGIInput
                  min={0}
                  onPaste={disablePaste}
                  onKeyDown={disableEnterMinus}
                  type="number"
                  placeholder="Enter value"
                  suffix={"%"}
                />
              </NGIFormItem>
              <NGIFormItem name="air">
                <Slider min={1} max={100} tooltip={{ formatter }} />
              </NGIFormItem>
            </Col>
            <Col span={7}>
              <Title level={5}>Propane*</Title>
              <NGIFormItem name="propane">
                <NGIInput
                  min={0}
                  onPaste={disablePaste}
                  onKeyDown={disableEnterMinus}
                  type="number"
                  placeholder="Enter value"
                  suffix={"%"}
                />
              </NGIFormItem>
              <NGIFormItem name="propane">
                <Slider min={1} max={100} tooltip={{ formatter }} />
              </NGIFormItem>
            </Col>
            <Col span={7}>
              <Title level={5}>Butane*</Title>
              <NGIFormItem name="butane">
                <NGIInput
                  min={0}
                  onPaste={disablePaste}
                  onKeyDown={disableEnterMinus}
                  type="number"
                  placeholder="Enter value"
                  suffix={"%"}
                />
              </NGIFormItem>
              <NGIFormItem name="butane">
                <Slider min={1} max={100} tooltip={{ formatter }} />
              </NGIFormItem>
            </Col>
            <Col span={3}>
              <Title level={5}>Sum</Title>
              <NGIInput className={sum === 100 ? classes.success : classes.error} value={sum} suffix={"%"} />
            </Col>
          </Row>
          <Row className={classes.formItem}>
            <Col span={24}>
              <Title level={5}>Lookup Table*</Title>
              <NGIFormItem name="lutName" rules={VALIDATION_RULES}>
                <NGISelect onChange={onTableChange} showSearch placeholder={"Select value"}>
                  {sensorCalibrationLookupTables
                    ?.filter(item => item.isActive)
                    ?.map(item => (
                      <Option key={item.name} value={item.name}>
                        {item.name}
                      </Option>
                    ))}
                </NGISelect>
              </NGIFormItem>
            </Col>
          </Row>

          {selectedTable && (
            <Collapse lassName={classes.collapseList} expandIconPosition={"end"}>
              <Panel key="table" header={`Lookup Table - ${selectedTable?.name ?? ""}`}>
                <div className={classes.xyList}>
                  {selectedTable?.lut?.map((item, index) => (
                    <div key={`lut-${item}`} className={classes.xyContainer}>
                      <div className={classes.index}>{index + 1}</div>
                      <div className={classes.value}>
                        <span>X</span> <b>{item[0]}</b>
                      </div>
                      <div className={classes.value}>
                        <span>Y</span> <b>{item[1]}</b>
                      </div>
                    </div>
                  ))}
                </div>
              </Panel>
            </Collapse>
          )}
          <Divider />
          <br />
          <Space>
            <CustomButton color="outlined" onClick={onClose} text="Cancel" size="small" />
            <CustomButton
              type="primary"
              htmlType="submit"
              onClick={openConfirmationModal}
              text="CREATE"
              size="small"
              disabled={disabled}
            />
          </Space>
        </Form>
      </Spin>
      {isConfirmCloseOpen && (
        <ConfirmationModal
          description="The registration process is still In Progress. Are you sure you want to stop the process?"
          onCancel={closeConfirmCloseModal}
          onConfirm={onClose}
        />
      )}
      {isConfirmationOpen && (
        <ConfirmationModal
          description="Are you sure you want to add template?"
          onCancel={closeConfirmationModal}
          onConfirm={onSubmit}
          loading={isLoading}
        />
      )}
    </Modal>
  );
};

export default NewTemplateModal;

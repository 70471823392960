import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classname from "classnames";
import arrow from "../../assets/images/svg/arrow.svg";
import { menuItems } from "components/VerticalLayout/variables";
import useIsMobile from "customHooks/useIsMobile";
import useStoreFilters from "customHooks/useStoreFilters";
import { userHasAdminRole, userHasOneOfRoles } from "utils/helpers/functions";
import { USER_ROLES } from "utils/constants";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { UtilsSelector } from "../../store/utils/selectors";
import useDepotHasOC from "../../customHooks/useDepotHasOC";

const SidebarContent = ({
  currentPage,
  activeItem,
  setActiveItem,
  activeSubItem,
  setActiveSubItem,
  handleHideMenu,
}) => {
  const { clearFilters } = useStoreFilters();
  const isMobile = useIsMobile();
  const { depotStatus } = useSelector(UtilsSelector);
  const hasFeature = useDepotHasOC();

  const [activeSections, setActiveSections] = useState([USER_ROLES.WAREHOUSE]);

  const handleClick = useCallback((item, needToHide = false) => {
    setActiveItem(prevState => {
      if (prevState === item) return null;
      return item;
    });
    clearFilters();
    handleHide(needToHide);
  }, []);

  const handleSubItemClick = useCallback((subItem, needToHide = false) => {
    setActiveSubItem(prevState => {
      if (prevState === subItem) return null;
      return subItem;
    });
    clearFilters();
    handleHide(needToHide);
  }, []);

  const handleSectionClick = useCallback(
    (item, needToHide = false) => {
      if (!activeSections.includes(item)) {
        setActiveSections(prevState => [...prevState, item]);
      } else {
        setActiveSections(prevState => [...prevState.filter(section => section !== item)]);
      }
      handleHide(needToHide);
    },
    [activeSections]
  );

  const handleCollapseAllSections = useCallback((sections = []) => setActiveSections(sections), [activeSections]);

  const handleHide = useCallback((needToHide = false) => needToHide && isMobile && handleHideMenu(), [isMobile]);

  const isActiveSection = useCallback(item => item.items?.find(i => i.link === currentPage), [currentPage]);

  const findActiveSection = useCallback(
    () => menuItems.find(item => isActiveSection(item) || item.items?.find(i => isActiveSection(i))),
    [menuItems, isActiveSection]
  );

  useEffect(() => {
    if (currentPage === "/dashboard") {
      handleCollapseAllSections([USER_ROLES.WAREHOUSE]);
    }
    const activeSection = findActiveSection();
    if (activeSection) {
      setActiveSections(prevState => [...prevState, activeSection.name]);
    }
  }, [currentPage]);

  return (
    <div id="sidebar-menu" data-testid="sidebar-menu">
      {menuItems.length && (
        <ul className="level-1">
          {menuItems.map(item => {
            if (item?.hideForClosedDepot && hasFeature && depotStatus === "Closed") {
              return null;
            }
            if (userHasOneOfRoles(item.roles) || !item.roles || userHasAdminRole()) {
              return (
                <li
                  key={item.name}
                  className={classname(
                    "level-1-item",
                    (isActiveSection(item) ||
                      item.items?.find(i => isActiveSection(i)) ||
                      activeSections.includes(item.name)) &&
                      "expand-item"
                  )}
                >
                  {item.link ? (
                    <Link to={item.link} onClick={() => handleClick(item.name)} className={"main-title"}>
                      {!!item.icon && <img className="menu-icon" src={item.icon} alt={item.name} />}
                      <span className="main-title-text" title={item.name}>
                        {item.name}
                      </span>
                    </Link>
                  ) : (
                    <div className="main-title" onClick={() => handleSectionClick(item.name)}>
                      {!!item.icon && <img className="menu-icon" src={item.icon} alt={item.name} />}
                      <span className="main-title-text" title={item.name}>
                        {item.name}
                      </span>
                    </div>
                  )}

                  {!!item.items.length && (
                    <ul className="level-2">
                      {item.items.map(item => {
                        if (
                          (userHasOneOfRoles(item.roles) || !item.roles || userHasAdminRole()) &&
                          (!item.hideRoles || !userHasOneOfRoles(item.hideRoles))
                        ) {
                          return (
                            <li
                              key={item.name}
                              className={classname(
                                "level-2-item",
                                (activeItem === item.name || item.items?.find(i => i.link === currentPage)) &&
                                  "expand-item"
                              )}
                            >
                              {item.link ? (
                                <Link
                                  onClick={() => handleClick(item.name, true)}
                                  to={item.link}
                                  className={classname("title-2", currentPage === item.link && "active")}
                                >
                                  {!!item.icon && <img className="menu-icon" src={item.icon} alt={item.name} />}
                                  <span className="title-text-2">{item.name}</span>
                                </Link>
                              ) : (
                                <div className="title-2" onClick={() => handleClick(item.name)}>
                                  {!!item.icon && <img className="menu-icon" src={item.icon} alt={item.name} />}
                                  <span className="title-text-2">{item.name}</span>
                                  <img src={arrow} className="arrow" alt="arrow" />
                                </div>
                              )}

                              {!!item.items.length && (
                                <ul className="level-3">
                                  {item.items.map(item => {
                                    if (userHasOneOfRoles(item.roles) || !item.roles || userHasAdminRole()) {
                                      return (
                                        <li
                                          key={item.name}
                                          className={classnames(
                                            "level-3-item",
                                            (activeSubItem === item.name ||
                                              item.items?.find(i => i.link === currentPage)) &&
                                              "expand-item"
                                          )}
                                        >
                                          {item.link ? (
                                            <Link
                                              to={item.link}
                                              onClick={() => handleHide(true)}
                                              className={classname("title-3", currentPage === item.link && "active")}
                                            >
                                              {!!item.icon && (
                                                <img className="menu-icon" src={item.icon} alt={item.name} />
                                              )}
                                              <span className="title-text-3">{item.name}</span>
                                            </Link>
                                          ) : (
                                            <div className="title-3" onClick={() => handleSubItemClick(item.name)}>
                                              {!!item.icon && (
                                                <img className="menu-icon" src={item.icon} alt={item.name} />
                                              )}
                                              <span className="title-text-3">{item.name}</span>
                                              <img src={arrow} className="arrow" alt="arrow" />
                                            </div>
                                          )}

                                          {!!item.items?.length && (
                                            <ul className="level-4">
                                              {item.items.map(item => {
                                                if (
                                                  userHasOneOfRoles(item.roles) ||
                                                  !item.roles ||
                                                  userHasAdminRole()
                                                ) {
                                                  return (
                                                    <li key={item.name} className="level-4-item">
                                                      {item.link ? (
                                                        <Link
                                                          to={item.link}
                                                          onClick={() => handleHide(true)}
                                                          className={classname(
                                                            "title-4",
                                                            currentPage === item.link && "active"
                                                          )}
                                                        >
                                                          {!!item.icon && (
                                                            <img
                                                              className="menu-icon"
                                                              src={item.icon}
                                                              alt={item.name}
                                                            />
                                                          )}
                                                          <span className="title-text-4">{item.name}</span>
                                                        </Link>
                                                      ) : (
                                                        <div className="title-4">
                                                          {!!item.icon && (
                                                            <img
                                                              className="menu-icon"
                                                              src={item.icon}
                                                              alt={item.name}
                                                            />
                                                          )}
                                                          <span className="title-text-4">{item.name}</span>
                                                        </div>
                                                      )}
                                                    </li>
                                                  );
                                                }
                                                return null;
                                              })}
                                            </ul>
                                          )}
                                        </li>
                                      );
                                    }
                                    return null;
                                  })}
                                </ul>
                              )}
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  )}
                </li>
              );
            }
            return null;
          })}
        </ul>
      )}
    </div>
  );
};

export default SidebarContent;

import { statusFormatterWithMap, textFormatter } from "components/Common/formatter";
import { STATUS_COLOR_MAP } from "./constants";
import offIcon from "../../../assets/images/svg/battery-off.svg";
import ButtonGroup from "../../../components/Common/buttons/ButtonGroup";
import React from "react";

export const batteryTemplateListColumns = deactivateTemplate => [
  {
    title: "Template Name",
    dataIndex: "name",
    render: textFormatter,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: value => statusFormatterWithMap(value, STATUS_COLOR_MAP),
  },
  {
    title: "Battery Capacity",
    dataIndex: "batteryCapacity",
    render: value => `${value} mAh`,
  },
  {
    title: "Capacitor",
    dataIndex: "capacitorVoltage",
    render: textFormatter,
  },
  {
    title: "Battery Level",
    dataIndex: "batteryLevel",
    render: value => `${value}%`,
  },
  {
    title: "Battery Model",
    dataIndex: "batteryModelNumber",
    render: textFormatter,
  },
  {
    title: "Battery Change Level",
    dataIndex: "batteryChangeLevel",
    render: value => `${value}mAh`,
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroup
        tooltipTitle="Activate/Deactivate Template"
        icon={offIcon}
        handleClick={() => {
          deactivateTemplate(row);
        }}
      />
    ),
  },
];

import React, { useCallback, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Col, Row, Spin } from "antd";
import Header from "pages/user-management/userDetails/Header";
import { useParams } from "react-router";
import { getUserByEmail, retrieveUserLeaves } from "utils/api";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import { makeStyles } from "@material-ui/core/styles";
import Card from "antd/es/card/Card";
import infoIconGrey from "assets/images/svg/InfoGrey.svg";
import leavesIcon from "assets/images/svg/leavesIcon.svg";
import UserCard from "pages/user-management/userDetails/UserCard";
import General from "pages/user-management/userDetails/Tabs/General";
import LeavesBar from "pages/user-management/userDetails/LeavesBar";
import Leaves from "pages/user-management/userDetails/Tabs/Leaves";
import NotFoundContainer from "components/Common/NotFoundContainer";

const useStyles = makeStyles({
  root: {
    width: "100%",
    marginTop: 30,
  },
  container: {
    marginTop: 10,
    borderRadius: 5,
    padding: 15,
  },
  row: {
    marginTop: 20,
  },
  breadcrumbs: {
    textTransform: "capitalize",
    "&> a": {
      textDecoration: "underline !important",
      color: "black",
    },
  },
});

const UserDetails = () => {
  const { email } = useParams();
  const [user, setUser] = useState({});
  const [route, setRoute] = useState("");
  const [leaves, setLeaves] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const classes = useStyles();

  const getUser = useCallback(() => {
    getUserByEmail(email)
      .then(res => {
        setUser(res?.data?.result);
      })
      .catch(error => {
        setLoader(false);
        if (error.response?.data?.errors[0]?.code === "NOT_FOUND") {
          setIsNotFound(true);
        }
      });
  }, [email]);

  const getUserLeaves = useCallback(() => {
    retrieveUserLeaves(email)
      .then(res => {
        setLeaves(res?.data?.result?.items);
      })
      .catch(err => {
        defaultNgiErrorMethod(err);
      });
  }, [email]);

  const onCardClick = useCallback(e => {
    const route = e.currentTarget.dataset.route;
    setRoute(route);
  }, []);

  const handleBack = useCallback(e => {
    e.preventDefault();
    setRoute("");
  }, []);

  useEffect(() => {
    onUpdateData();
  }, []);

  const onUpdateData = useCallback(() => {
    getUser();
    getUserLeaves();
  }, [getUser, getUserLeaves]);

  return (
    <div className="page-content">
      <Container fluid>
        <NotFoundContainer isNotFound={isNotFound}>
          <Spin spinning={isLoading}>
            <>
              <Header user={user} updateUserData={onUpdateData} />
              <LeavesBar leaves={leaves} updateUser={onUpdateData} />
              {!route && (
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align={"stretch"} className={classes.row}>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <UserCard
                      route={"general"}
                      icon={infoIconGrey}
                      title={"General Information"}
                      subtitle={"View employee general information and more"}
                      onCardClick={onCardClick}
                    />
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={4}>
                    <UserCard
                      route={"leaves"}
                      icon={leavesIcon}
                      title={"Leave"}
                      subtitle={"History of vacations and other types of leave taken"}
                      onCardClick={onCardClick}
                    />
                  </Col>
                </Row>
              )}
              {!!route && (
                <div className={classes.root}>
                  <span className={classes.breadcrumbs}>
                    <a href="#" onClick={handleBack}>{`Back`}</a>
                    {` > ${route}`}
                  </span>
                  <Card className={classes.container}>
                    {route === "general" && <General user={user} />}
                    {route === "leaves" && <Leaves leaves={leaves} setLoader={setLoader} updateUser={onUpdateData} />}
                  </Card>
                </div>
              )}
            </>
          </Spin>
        </NotFoundContainer>
      </Container>
    </div>
  );
};

export default UserDetails;

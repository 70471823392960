import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { calibrationReportsListColumns } from "./CalibrationReportsListColumns";
import CalibrationReportsSearch from "./CalibrationReportsSearch";
import { retrieveCalibrationReports } from "utils/api";
import { isEmpty } from "lodash";
import ReportResultModal from "./ReportResultModal";
import NewCalibrationModal from "../reports/NewCalibration/NewCalibrationModal";
import CustomButton from "components/Common/buttons/CustomButton";

const CalibrationReportsList = () => {
  const [isLoading, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [list, setList] = useState(null);
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.CALIBRATION_REPORTS);

  const closeCreateTemplate = useCallback(() => {
    setIsOpen(false);
    handleSearch();
  }, []);
  const openCreateTemplate = useCallback(() => setIsOpen(true), []);

  const onViewReport = useCallback(item => setActiveItem(item), []);

  const onCloseReport = useCallback(() => setActiveItem(null), []);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Reports were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = useCallback((filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    retrieveCalibrationReports(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  }, []);

  const columns = useMemo(() => calibrationReportsListColumns(onViewReport), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                roles={[
                  USER_ROLES.PRODUCT_TECHNICIAN_LEAD,
                  USER_ROLES.METER_ENGINEER,
                  USER_ROLES.METER_ENGINEER_LEADERSHIP,
                ]}
                onClick={openCreateTemplate}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="NEW CALIBRATION"
              />
            </div>

            <CalibrationReportsSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.CALIBRATION_REPORTS}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
        {activeItem && <ReportResultModal stats={activeItem} onClose={onCloseReport} />}
        {isOpen && <NewCalibrationModal onClose={closeCreateTemplate} />}
      </div>
    </>
  );
};

export default CalibrationReportsList;

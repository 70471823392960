import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Spin, Steps } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import LBInformation from "pages/stock-management/loadingBays/newLoadingBay/tabs/LBInformation";
import SuccessScreen from "pages/stock-management/loadingBays/newLoadingBay/tabs/SuccessScreen";
import {
  attachDetachLoadBayAssets,
  createLoadingBay,
  retrieveLoadingBayAssets,
  retrieveLoadingBayDetails,
  updateLoadingBay,
} from "utils/api";
import AssetsList from "pages/stock-management/loadingBays/newLoadingBay/tabs/AssetsList";
import classnames from "classnames";
import { extractPromisesResult } from "utils/helpers/functions";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 0px !important",
    },
  },
  successModal: {
    width: "500px!important",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 30,
    paddingBottom: 30,
    padding: "0 50px",
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 50px",
  },
  stepsContainer: {
    padding: "20px 50px",
    backgroundColor: "#0000000D",
    border: "1px solid #0000000D",
  },
  withMargin: {
    marginRight: 10,
  },
  typeTitle: {
    textTransform: "capitalize",
  },
});

const NewLBModal = ({ onClose, update, id }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isDraftSaveOpen, setIsDraftSaveOpen] = useState(false);
  const [lbInfo, setLbInfo] = useState(null);
  const [current, setCurrent] = useState(0);
  const [addedList, setAddedList] = useState([]);
  const [statusList, setStatusList] = useState({});
  const [saveType, setSaveType] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const closeSaveModal = useCallback(() => setSaveType(null), []);

  const openSaveDraftModal = useCallback(() => setIsDraftSaveOpen(true), []);
  const closeSaveDraftModal = useCallback(() => setIsDraftSaveOpen(false), []);

  useEffect(() => {
    if (id) {
      setLoader(true);
      Promise.allSettled([retrieveLoadingBayDetails(id), retrieveLoadingBayAssets(id, { pageSize: 3000 })]).then(
        data => {
          const [details, assets] = extractPromisesResult(data);
          setLbInfo(details.data.result);
          if (details.data.result.status !== "Draft") {
            setCurrent(1);
          }
          const assetsList = assets.data.result.items?.map(item => item.qrCode);
          const assetsStat = assetsList?.reduce((a, item) => {
            a[item] = {
              loaded: true,
              error: "",
            };
            return a;
          }, {});
          setAddedList(assetsList);
          setStatusList(assetsStat);
          setIsEdit(true);
          setLoader(false);
        }
      );
    }
  }, []);

  const onSuccessClose = useCallback(() => {
    update();
    onClose();
  }, []);

  const onSaveDraft = useCallback(
    async (isSilent = false) => {
      const { group, condition } = form.getFieldsValue();
      if (current === 1) {
        setShowSuccess(true);
        closeSaveDraftModal();
        return;
      }
      if (condition && group) {
        !isSilent && closeSaveDraftModal();

        setLoader(true);
        const request = isEdit ? updateLoadingBay : createLoadingBay;
        await request({ group, condition }, lbInfo?.id)
          .then(res => {
            setLbInfo({ ...res.data.result, ...lbInfo, group });
            !isSilent && setShowSuccess(true);
            setLoader(false);
          })
          .catch(err => {
            setLoader(false);
            defaultNgiErrorMethod(err);
          });
      } else {
        closeSaveDraftModal();
        form.submit();
      }
    },
    [form, isEdit, lbInfo, current]
  );

  const tabs = useMemo(
    () => [
      {
        title: "Loading Bay Information",
        key: "lbInformation",
        children: <LBInformation form={form} data={lbInfo} isEdit={isEdit} />,
      },
      {
        title: "List of Assets",
        key: "assetsList",
        children: (
          <AssetsList
            data={lbInfo}
            addedList={addedList}
            setAddedList={setAddedList}
            statusList={statusList}
            setStatusList={setStatusList}
          />
        ),
      },
    ],
    [form, lbInfo, addedList, setAddedList, statusList, setStatusList, isEdit]
  );

  const onNextStep = useCallback(async () => {
    if (isEdit && lbInfo.status !== "Draft") {
      setCurrent(prevState => prevState + 1);
    } else {
      const { group, condition } = form.getFieldsValue();
      if (condition && group) {
        await onSaveDraft(true);
        setCurrent(prevState => prevState + 1);
      } else {
        form.submit();
      }
    }
  }, [form, onSaveDraft]);

  const onCreate = useCallback(() => {
    closeSaveModal();
    if (saveType === "prepared") {
      setLoader(true);
      attachDetachLoadBayAssets(lbInfo?.id, {
        isPrepared: saveType === "prepared",
      })
        .then(() => {
          setLoader(false);
          setShowSuccess(true);
        })
        .catch(error => {
          setLoader(false);
          defaultNgiErrorMethod(error);
        });
    } else {
      setShowSuccess(true);
    }
  }, [lbInfo, saveType]);

  const onSaveClick = useCallback(e => {
    const type = e.currentTarget.dataset.type;
    setSaveType(type);
  }, []);

  const hasError = useMemo(() => Object.keys(statusList).find(item => statusList[item].error.length), [statusList]);

  return (
    <Modal
      title={
        !showSuccess && (
          <div className={classes.titleWithDescription}>
            {isEdit ? "Edit" : "Create"} Loading Bay
            <span>Please fill all mandatory fields</span>
          </div>
        )
      }
      open
      onCancel={openConfirmationModal}
      closable={false}
      className={classnames(classes.modal, showSuccess && classes.successModal)}
      footer={[]}
    >
      {showSuccess ? (
        <SuccessScreen onClose={onSuccessClose} data={lbInfo} count={addedList.length} />
      ) : (
        <Spin spinning={isLoading}>
          <div className={classes.stepsContainer}>
            <Steps current={current} items={tabs} />
          </div>
          <div className={classes.container}>{tabs[current].children}</div>
          <div className={classes.actions}>
            <CustomButton onClick={openConfirmationModal} type="primary" size="small" color="outlined" text="Cancel" />

            <div>
              {(current === 0 || !addedList.length) && (
                <CustomButton
                  className={classes.withMargin}
                  size="small"
                  text="Save as Draft"
                  color="outlined"
                  onClick={openSaveDraftModal}
                  disabled={isEdit && lbInfo?.status !== "Draft"}
                />
              )}
              {current === 0 && <CustomButton size="small" text="NEXT STEP" type="primary" onClick={onNextStep} />}

              {current === 1 && !!addedList.length && (
                <>
                  <CustomButton
                    data-type="preparing"
                    onClick={onSaveClick}
                    className={classes.withMargin}
                    disabled={hasError}
                    size="small"
                    text="Save as Preparing"
                    color="outlined"
                  />
                  <CustomButton
                    data-type="prepared"
                    onClick={onSaveClick}
                    disabled={hasError}
                    size="small"
                    text="Save as Prepared"
                    type="primary"
                  />
                </>
              )}
            </div>
          </div>
        </Spin>
      )}

      {isConfirmationOpen && (
        <ConfirmationModal
          description={
            current === 0
              ? "The Loading Bay creation is in progress. Are you sure you want to discard the changes?"
              : "The changes will be saved in the system in 'Draft' or 'Preparing' status. Are you sure you want to close the creation popup?"
          }
          onCancel={closeConfirmationModal}
          onConfirm={onSuccessClose}
        />
      )}
      {!!saveType && (
        <ConfirmationModal
          description={
            <span>
              Are you sure you want to save as "<b className={classes.typeTitle}>{saveType}</b>"?
            </span>
          }
          onCancel={closeSaveModal}
          onConfirm={onCreate}
        />
      )}
      {isDraftSaveOpen && (
        <ConfirmationModal
          description="Are you sure you want to save Loading Bay as 'Draft'?"
          onCancel={closeSaveDraftModal}
          onConfirm={onSaveDraft}
        />
      )}
    </Modal>
  );
};

export default NewLBModal;

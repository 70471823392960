export const DICTIONARY_TYPES = {
  DEPOT: "depot",
  CONDITIONS: "meterCondition",
  SPARE_PART_CATEGORY: "sparePartCategory",
  SPARE_PART_SHIPPING_METHOD: "sparePartShippingMethod",
  BATTERY_MODEL: "batteryModel",
  COUNTRY: "country",
  COUNTY: "county",
  REGION: "region",
  DISTRICT: "district",
  UPLOAD_TEMPLATE: "meterUploadTemplate",
  BATTERY_CONDITION: "batteryCondition",
  CYLINDER_CONDITION: "cylinderCondition",
  METER_CONDITION: "meterCondition",
  STOVE_CONDITION: "stoveCondition",
  TASK_CATEGORY: "taskCategory",
  CUSTOMER_TYPE: "customerType",
  OCCUPATION: "occupation",
  DOCUMENT_TYPE: "documentType",
  METER_TYPE: "meterType",
  METER_FAULTY_REASONS_V2: "meterFaultyReasonV2",
  METER_REPAIR_INPUT_FIELDS: "meterRepairInputFields",
  TAM_REASONS: "TAMReasons",
  AGENT_ROLES: "agentRoles",
  ASSET_TYPE_GROUP: "assetTypeGroup",
  PRICE_SEGMENT_NAMES: "priceSegmentNames",
  CAMPAIGN_TYPE: "campaignType",
  PAYMENT_SETTINGS: "paymentSettings",
  DEFINE_AMOUNT_TYPE: "defineAmountType",
  CAMPAIGN_RECOVERY_TYPE: "campaignRecoveryType",
  CAMPAIGN_STATUS: "campaignStatus",
  REPOSSESSION_REASONS_V2: "repossessionTaskReasonV2",
  INSPECTION_TASK_REASONS: "inspectionTaskReasons",
  METER_INSPECTION_TASK_REASONS: "meterInspectionTaskReasons",
  METER_CHANGE_REASONS_V2: "meterChangeCompleteTaskReasonsV2",
  METER_CHANGE_DECLINE_REASONS: "meterChangeDeclineTaskReasons",
  MANUALLY_TRIGGERED_COMMAND: "manuallyTriggeredCommand",
  SIM_CARD_TYPE: "simCardType",
  FIRMWARE_STATUS: "firmwareStatus",
  TASK_CANCEL_REASON: "taskCancelReason",
  METER_FIRMWARE_FILE_TYPE: "meterFirmwareFileType",
  LEAD_SOURCES: "leadSources",
  TEAM_TYPE: "teamType",
  STO_GROUP_CONDITIONS: "stoAssetGroupConditions",
  TRUCK_LICENSE_PLATES: "truckLicensePlates",
  TENANT: "tenant",
  LOST_ASSET_REASONS: "lostAssetReason",
  SENSOR_CALIBRATION_LOOKUP_TABLES: "sensorCalibrationLookupTables",
};

export const BALANCE_BOOK_TRANSITION_MODE = "Stove Balance Payment Type";

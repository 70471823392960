import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import success from "../../../assets/images/svg/Task Completed.svg";
import failed from "../../../assets/images/svg/Scorecard.svg";
import ButtonGroup from "../../../components/Common/buttons/ButtonGroup";
import ButtonGroupContainer from "../../../components/Common/buttons/ButtonGroupContainer";

export const batteryReportsListColumns = onReportDownload => [
  {
    title: "Report ID",
    dataIndex: "id",
    render: textFormatter,
  },
  {
    title: "Template",
    dataIndex: "template",
    render: textFormatter,
  },
  {
    title: "MGas Batch",
    dataIndex: "mGasBatchNumber",
    render: textFormatter,
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    render: textFormatter,
  },
  {
    title: "Supplier Batch",
    dataIndex: "supplierBatchNumber",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "Created At",
    dataIndex: "createdAt",
    render: customDateFormatWithTime,
  },
  {
    title: "Created By",
    dataIndex: "createdBy",
    render: textFormatter,
  },

  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroupContainer>
        {row.registeredItemsReportUrl && (
          <ButtonGroup
            tooltipTitle="Download Registered *.CSV"
            icon={success}
            handleClick={() => {
              onReportDownload(row.registeredItemsReportUrl);
            }}
          />
        )}

        {row.failedItemsReportUrl && (
          <ButtonGroup
            tooltipTitle="Download Failed *.CSV"
            icon={failed}
            handleClick={() => {
              onReportDownload(row.failedItemsReportUrl);
            }}
          />
        )}
      </ButtonGroupContainer>
    ),
  },
];

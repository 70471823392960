import { customDateFormatWithTime, textFormatter } from "components/Common/formatter";
import ButtonGroup from "../../../components/Common/buttons/ButtonGroup";
import React from "react";
import ButtonGroupContainer from "../../../components/Common/buttons/ButtonGroupContainer";
import lost from "assets/images/svg/lost-white.svg";
import found from "assets/images/svg/found-white.svg";
import faulty from "assets/images/svg/faulty-white.svg";
import { USER_ROLES } from "../../../utils/constants";

export const batteryListColumns = onMarkAs => [
  {
    title: "Barcode",
    dataIndex: "barcode",
    render: textFormatter,
  },
  {
    title: "Template",
    dataIndex: "template",
    render: textFormatter,
  },
  {
    title: "MGas Batch",
    dataIndex: "mGasBatchNumber",
    render: textFormatter,
  },
  {
    title: "Supplier",
    dataIndex: "supplier",
    render: textFormatter,
  },
  {
    title: "Supplier Batch",
    dataIndex: "supplierBatchNumber",
    render: textFormatter,
  },
  {
    title: "Depot",
    dataIndex: "depot",
    render: textFormatter,
  },
  {
    title: "State",
    dataIndex: "state",
    render: textFormatter,
  },
  {
    title: "Condition",
    dataIndex: "condition",
    render: textFormatter,
  },
  {
    title: "Registered Date",
    dataIndex: "registeredAt",
    render: cellContent => textFormatter(customDateFormatWithTime(cellContent)),
  },
  {
    dataIndex: "",
    title: "",
    width: "4%",
    render: row => (
      <ButtonGroupContainer>
        {row.state === "Available" && (
          <ButtonGroup
            tooltipTitle="Mark as Lost"
            icon={lost}
            roles={[USER_ROLES.METER_ENGINEER_LEADERSHIP, USER_ROLES.METER_ENGINEER]}
            handleClick={() => {
              onMarkAs(row.id, "lost");
            }}
          />
        )}

        {row.state === "Lost" && (
          <ButtonGroup
            tooltipTitle="Mark as Found"
            icon={found}
            roles={[USER_ROLES.METER_ENGINEER_LEADERSHIP, USER_ROLES.METER_ENGINEER]}
            handleClick={() => {
              onMarkAs(row.id, "found");
            }}
          />
        )}
        <ButtonGroup
          tooltipTitle="Mark as Faulty"
          icon={faulty}
          roles={[USER_ROLES.METER_ENGINEER_LEADERSHIP, USER_ROLES.METER_ENGINEER]}
          handleClick={() => {
            onMarkAs(row.id, "faulty");
          }}
        />
      </ButtonGroupContainer>
    ),
  },
];

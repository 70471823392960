import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Modal, Spin, Steps } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import { defaultNgiErrorMethod } from "components/Common/responses/message";
import ConfirmationModal from "components/Common/modals/ConfirmationModal";
import { createMTOrder, updateMTOrder } from "utils/api";

import classnames from "classnames";
import ShipmentRecipient from "./tabs/ShipmentRecipient";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "../../../../store/dictionaries/selectors";
import AssetsList from "./tabs/AssetsList";
import CarrierInfo from "./tabs/CarrierInfo";
import moment from "moment/moment";
import SuccessScreen from "./tabs/SuccessScreen";

const useStyles = makeStyles({
  modal: {
    "& .ant-modal-content .ant-modal-body": {
      padding: "24px 0px !important",
    },
  },
  successModal: {
    width: "500px!important",
  },
  titleWithDescription: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    fontWeight: 600,

    "&>span": {
      fontSize: 13,
      fontWeight: 300,
    },
  },
  container: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
    borderLeft: "none",
    borderRight: "none",
    marginBottom: 30,
    paddingBottom: 30,
    padding: "0 50px",
  },
  tabs: {
    "&.ant-tabs > .ant-tabs-nav, .ant-tabs > div > .ant-tabs-nav": {
      padding: "0px!important",
      margin: 0,
    },
  },
  actions: {
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 50px",
  },
  stepsContainer: {
    padding: "20px 50px",
    backgroundColor: "#0000000D",
    border: "1px solid #0000000D",
  },
  withMargin: {
    marginRight: 10,
  },
  typeTitle: {
    textTransform: "capitalize",
  },
});

const NewOrderModal = ({ data, step, onClose, update }) => {
  const classes = useStyles();
  const [form] = Form.useForm();
  const [isLoading, setLoader] = useState(false);
  const [sendOpen, setSendOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [shipmentInfo, setShipmentInfo] = useState(data || null);
  const [current, setCurrent] = useState(step || 0);
  const [addedList, setAddedList] = useState(data?.items || []);
  const [saveType, setSaveType] = useState(null);
  const [statusList, setStatusList] = useState({});
  const [disabledNext, setDisabledNext] = useState(true);

  const {
    list: { tenant },
  } = useSelector(DictionariesSelector);

  const openConfirmationModal = useCallback(() => setIsConfirmationOpen(true), []);
  const closeConfirmationModal = useCallback(() => setIsConfirmationOpen(false), []);
  const closeSaveModal = useCallback(() => setSaveType(null), []);
  const closeSendModal = useCallback(() => setSendOpen(false), []);

  const openSaveOpenModal = useCallback(() => setIsSaveOpen(true), []);
  const closeSaveOpenModal = useCallback(() => setIsSaveOpen(false), []);

  const onSuccessClose = useCallback(() => {
    update();
    onClose();
  }, []);

  const onSaveOpen = useCallback(
    async (isSilent = false) => {
      if (current === 0) {
        const { toTenant, toDepot, consigneeName } = form.getFieldsValue();
        if (toTenant && toDepot && consigneeName) {
          !isSilent && closeSaveOpenModal();

          setLoader(true);
          await createMTOrder({ toTenant: tenant[toTenant][0], toDepot, consigneeName }, shipmentInfo?.id)
            .then(res => {
              setShipmentInfo({ ...res.data.result, ...shipmentInfo });
              !isSilent && setShowSuccess(true);
              isSilent && setCurrent(prevState => prevState + 1);
              setLoader(false);
            })
            .catch(err => {
              setLoader(false);
              defaultNgiErrorMethod(err);
            });
        } else {
          closeSaveOpenModal();
          form.submit();
        }
      }
      if (current === 1) {
        if (addedList.length && shipmentInfo) {
          setLoader(true);
          updateMTOrder({ items: addedList }, shipmentInfo.id)
            .then(() => {
              !isSilent && setShowSuccess(true);
              isSilent && setCurrent(prevState => prevState + 1);

              setLoader(false);
            })
            .catch(error => {
              const list = {};
              error?.response?.data?.errors.forEach(item => {
                item?.items?.forEach(sub => {
                  if (!list[sub]) {
                    list[sub] = { error: item?.desc };
                  } else {
                    list[sub] = { error: `${list[sub].error}. ${item?.desc}` };
                  }
                });
              });

              setStatusList(list);
              setLoader(false);
              defaultNgiErrorMethod(error);
            });
        }
      }
      if (current === 2) {
        const { carrierName, expectedArrivalDate, trackingNumber } = form.getFieldsValue();
        setLoader(true);
        updateMTOrder(
          {
            carrierName,
            expectedArrivalDate: moment(expectedArrivalDate).format("YYYY-MM-DD"),
            trackingNumber,
          },
          shipmentInfo.id,
          false
        )
          .then(() => {
            setShowSuccess(true);
            setLoader(false);
          })
          .catch(err => {
            setLoader(false);
            defaultNgiErrorMethod(err);
          });
      }
    },
    [form, shipmentInfo, current, tenant, addedList]
  );

  const validateForm = useCallback(() => {
    if (current === 0) {
      const { toTenant, toDepot, consigneeName } = form.getFieldsValue();
      if (toTenant && toDepot && consigneeName) {
        setDisabledNext(false);
      } else {
        setDisabledNext(true);
      }
      return;
    }
    setDisabledNext(false);
  }, [form, current]);

  const tabs = useMemo(
    () => [
      {
        title: "Shipment Recipient",
        key: "shipmentRecipient",
        children: <ShipmentRecipient validateForm={validateForm} form={form} data={shipmentInfo} />,
      },
      {
        title: "List of Assets",
        key: "assetsList",
        children: (
          <AssetsList
            validateForm={validateForm}
            data={shipmentInfo}
            addedList={addedList}
            setAddedList={setAddedList}
            statusList={statusList}
          />
        ),
      },
      {
        title: "Carrier Info",
        key: "carrierInfo",
        children: <CarrierInfo validateForm={validateForm} form={form} />,
      },
    ],
    [form, shipmentInfo, addedList, setAddedList, statusList, validateForm]
  );

  const onNextStep = useCallback(async () => {
    setDisabledNext(true);
    if (current === 0) {
      const { toTenant, toDepot, consigneeName } = form.getFieldsValue();
      if (toTenant && toDepot && consigneeName) {
        await onSaveOpen(true);
      } else {
        form.submit();
      }
    }
    if (current === 1) {
      if (addedList.length) {
        await onSaveOpen(true);
      }
    }
  }, [form, onSaveOpen, addedList]);

  const sendShipment = useCallback(async () => {
    const { carrierName, expectedArrivalDate, trackingNumber } = form.getFieldsValue();
    if (carrierName && expectedArrivalDate && trackingNumber) {
      await onSaveOpen(true);
    } else {
      form.submit();
    }
    closeSendModal();
  }, [form, onSaveOpen]);

  const onCreate = useCallback(() => {
    closeSaveModal();
    setLoader(true);
    updateMTOrder({ items: addedList }, shipmentInfo?.id)
      .then(() => {
        setLoader(false);
        setShowSuccess(true);
      })
      .catch(error => {
        setLoader(false);
        defaultNgiErrorMethod(error);
      });
  }, [shipmentInfo, addedList]);

  const onSaveClick = useCallback(e => {
    const type = e.currentTarget.dataset.type;
    setSaveType(type);
  }, []);

  const openSend = useCallback(() => setSendOpen(true), []);

  useEffect(() => {
    if (data && !step) {
      if (data.status === "Open") {
        setCurrent(1);
      }
      if (data.status === "Packed") {
        setCurrent(2);
      }
    }
    if (step) {
      setCurrent(step);
    }
  }, [data]);

  return (
    <Modal
      title={
        !showSuccess && (
          <div className={classes.titleWithDescription}>
            Create Shipment
            <span>Please fill all mandatory fields</span>
          </div>
        )
      }
      open
      onCancel={openConfirmationModal}
      closable={false}
      className={classnames(classes.modal, showSuccess && classes.successModal)}
      footer={[]}
    >
      {showSuccess ? (
        <SuccessScreen onClose={onSuccessClose} data={shipmentInfo} count={addedList.length} />
      ) : (
        <Spin spinning={isLoading}>
          <div className={classes.stepsContainer}>
            <Steps current={current} items={tabs} />
          </div>
          <div className={classes.container}>{tabs[current].children}</div>
          <div className={classes.actions}>
            <CustomButton onClick={openConfirmationModal} type="primary" size="small" color="outlined" text="Cancel" />

            <div>
              {current === 0 && (
                <CustomButton
                  className={classes.withMargin}
                  size="small"
                  text="Save as Open"
                  color="outlined"
                  disabled={disabledNext}
                  onClick={openSaveOpenModal}
                />
              )}
              {current === 1 && (
                <>
                  <CustomButton
                    data-type="packed"
                    onClick={onSaveClick}
                    className={classes.withMargin}
                    disabled={!addedList.length}
                    size="small"
                    text="SAVE AS PACKED"
                    color="outlined"
                  />
                </>
              )}
              {current === 2 && (
                <>
                  <CustomButton
                    onClick={openSend}
                    className={classes.withMargin}
                    size="small"
                    text="SEND SHIPMENT"
                    color="outlined"
                  />
                </>
              )}
              {current === 0 && (
                <CustomButton
                  disabled={disabledNext}
                  size="small"
                  text="NEXT STEP"
                  type="primary"
                  onClick={onNextStep}
                />
              )}
              {current === 1 && (
                <CustomButton
                  disabled={!addedList.length}
                  size="small"
                  text="NEXT STEP"
                  type="primary"
                  onClick={onNextStep}
                />
              )}
            </div>
          </div>
        </Spin>
      )}

      {isConfirmationOpen && (
        <ConfirmationModal
          description={
            current === 0
              ? "The Order creation is in progress. Are you sure you want to discard the changes?"
              : "The changes will be saved in the system in 'Packed' status. Are you sure you want to close the creation popup?"
          }
          onCancel={closeConfirmationModal}
          onConfirm={onSuccessClose}
        />
      )}
      {!!saveType && (
        <ConfirmationModal
          description={
            <span>
              Are you sure you want to save as "<b className={classes.typeTitle}>{saveType}</b>"?
            </span>
          }
          onCancel={closeSaveModal}
          onConfirm={onCreate}
        />
      )}
      {sendOpen && (
        <ConfirmationModal
          description="Are you sure you want to send shipment?"
          onCancel={closeSendModal}
          onConfirm={sendShipment}
        />
      )}
      {isSaveOpen && (
        <ConfirmationModal
          description="Are you sure you want to save order as 'Open'?"
          onCancel={closeSaveOpenModal}
          onConfirm={onSaveOpen}
        />
      )}
    </Modal>
  );
};

export default NewOrderModal;

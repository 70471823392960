import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Container } from "reactstrap";
import { defaultNgiErrorMethod, successMessage, warnMessage } from "components/Common/responses/message";
import NGITable from "components/Common/NGITable";
import { isEmpty } from "lodash";
import useStoreFilters from "customHooks/useStoreFilters";
import { FILTER_PAGE, USER_ROLES } from "utils/constants";
import { batteryTemplateListColumns } from "./BatteryTemplateListColumns";
import BatteryTemplateSearch from "./BatteryTemplateSearch";
import { changeStatusTemplate, fetchTemplatesList } from "../../../utils/api";
import CustomButton from "../../../components/Common/buttons/CustomButton";
import ConfirmationModal from "../../../components/Common/modals/ConfirmationModal";
import { SWITCH_STATUS_MAP } from "./constants";
import NewTemplateModal from "./NewTemplateModal";

const BatteryTemplateList = () => {
  const [isLoading, setLoader] = useState(false);
  const [newTemplateOpen, setNewTemplateOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const [list, setList] = useState({});
  const { filters, setFilters } = useStoreFilters(FILTER_PAGE.BATTERY_TEMPLATES);

  const closeModal = useCallback(() => setActiveItem(null), []);
  const openModal = useCallback(data => setActiveItem(data), []);

  const closeNewTemplateModal = useCallback(() => setNewTemplateOpen(false), []);
  const openNewTemplateModal = useCallback(() => setNewTemplateOpen(true), []);

  const onDeactivate = useCallback(() => {
    const { id, status } = activeItem;
    closeModal();
    setLoader(true);
    changeStatusTemplate(id, SWITCH_STATUS_MAP[status])
      .then(() => {
        setLoader(false);
        successMessage(
          `Battery Template was ${SWITCH_STATUS_MAP[status] === "inactive" ? "deactivated" : "activated"} successfully`
        );
        handleSearch(filters);
      })
      .catch(e => {
        defaultNgiErrorMethod(e);
        setLoader(false);
      });
  }, [activeItem, filters]);

  useEffect(() => {
    !isEmpty(filters) ? handleSearch(filters) : handleSearch();
  }, []);

  const onListLoaded = useCallback(res => {
    const {
      result: { items },
    } = res?.data;
    if (items?.length === 0) {
      warnMessage("Templates were not found");
    }
    setList(res?.data || null);
    setLoader(false);
  }, []);

  const handleSearch = (filterOptions = {}) => {
    setLoader(true);
    const filters = { ...filterOptions };
    setFilters(filters);
    fetchTemplatesList(filters)
      .then(res => onListLoaded(res))
      .catch(err => {
        setLoader(false);
        setList(null);
        defaultNgiErrorMethod(err);
      });
  };

  const columns = useMemo(() => batteryTemplateListColumns(openModal), []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <div className="filters-wrapper">
            <div className="filter-buttons">
              <CustomButton
                roles={[USER_ROLES.METER_ENGINEER, USER_ROLES.METER_ENGINEER_LEADERSHIP]}
                onClick={openNewTemplateModal}
                icon={<i className="bx bx-plus-circle" />}
                type="primary"
                text="Add Template"
              />
            </div>

            <BatteryTemplateSearch handleSearch={handleSearch} />
          </div>
          <Col lg={12}>
            <NGITable
              filtersType={FILTER_PAGE.BATTERY_TEMPLATES}
              rowSelection={null}
              rowKey={record => record.id}
              isLoading={isLoading}
              columns={columns}
              data={list}
              updateList={handleSearch}
            />
          </Col>
        </Container>
        {activeItem && (
          <ConfirmationModal
            title={`Confirm ${SWITCH_STATUS_MAP[activeItem.status] === "inactive" ? "Deactivation" : "Activation"}`}
            description={
              SWITCH_STATUS_MAP[activeItem.status] === "inactive"
                ? "Are you sure you want to make this template inactive? It will no longer be used, but you can activate it again at any time."
                : "Are you sure you want to make this template active?"
            }
            onCancel={closeModal}
            onConfirm={onDeactivate}
            cancelBtnTitle="Cancel"
            confirmBtnTitle="Confirm"
          />
        )}
        {newTemplateOpen && (
          <NewTemplateModal isOpen={newTemplateOpen} onClose={closeNewTemplateModal} update={handleSearch} />
        )}
      </div>
    </>
  );
};

export default BatteryTemplateList;

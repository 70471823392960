import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Form, Select } from "antd";
import CustomButton from "components/Common/buttons/CustomButton";
import useStoreFilters from "customHooks/useStoreFilters";
import { isEmpty } from "lodash";
import { FILTER_PAGE } from "utils/constants";
import { useSelector } from "react-redux";
import { DictionariesSelector } from "store/dictionaries/selectors";

const { Option } = Select;

const Filters = ({ onFilter, showState, showAssetType, setLostState }) => {
  const [form] = Form.useForm();
  const { filters, getFilters } = useStoreFilters(FILTER_PAGE.ASSET);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [filterOption, setFilterOption] = useState(null);
  const {
    list: { batteryCondition, cylinderCondition, meterCondition, stoveCondition },
  } = useSelector(DictionariesSelector);

  useEffect(() => {
    if (!isEmpty(filters)) {
      form.setFieldsValue({
        ...filters,
      });
      setSelectedGroup(filters?.group);
      setFilterOption(filters?._filterOption);
    } else {
      form.setFieldsValue({
        state: null,
        group: null,
      });
    }
  }, []);

  const onFinish = values => {
    const { pageSize } = getFilters();
    if (values.state === "Lost" || values.state === "Missing") setLostState(true);
    else setLostState(false);
    onFilter({ ...values, pageSize });
  };

  const handleGroupChange = useCallback(value => {
    setSelectedGroup(value);
    setFilterOption(null);
    form.setFieldsValue({
      state: null,
      condition: null,
      _filterOption: null,
    });
  }, []);

  const handleFilterOptionChange = useCallback(value => {
    setFilterOption(value);
    form.setFieldsValue({
      state: null,
      condition: null,
    });
  }, []);

  const conditionList = useMemo(
    () =>
      ({
        Cylinder: cylinderCondition,
        Meter: meterCondition,
        Stove: stoveCondition,
        Battery: batteryCondition,
      } || {}),
    [cylinderCondition, meterCondition, stoveCondition, batteryCondition]
  );

  return (
    <>
      <div className="custom-filter">
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
          <Form.Item name="group" rules={[{ required: true, message: "Please select group" }]}>
            <Select
              style={{ width: "100%" }}
              tokenSeparators={[","]}
              size="default"
              placeholder="Select group"
              allowClear
              onChange={handleGroupChange}
            >
              {showAssetType.length > 0 &&
                showAssetType.map(item => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          {selectedGroup && (
            <>
              <div className="filter-divider" />
              <Form.Item name="_filterOption">
                <Select
                  size="default"
                  placeholder="Select filter option"
                  allowClear
                  onChange={handleFilterOptionChange}
                >
                  {["State", conditionList[selectedGroup] && "Condition"]
                    .filter(item => item)
                    .map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          )}

          {filterOption === "Condition" && (
            <>
              <div className="filter-divid6er" />
              <Form.Item name="condition" rules={[{ required: true, message: "Please select condition" }]}>
                <Select
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size="default"
                  placeholder="Select condition"
                  allowClear
                >
                  {conditionList &&
                    conditionList[selectedGroup]?.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          )}
          {filterOption === "State" && (
            <>
              <div className="filter-divider" />
              <Form.Item name="state" rules={[{ required: true, message: "Please select state" }]}>
                <Select
                  style={{ width: "100%" }}
                  tokenSeparators={[","]}
                  size="default"
                  placeholder="Select state"
                  allowClear
                >
                  {showState.length > 0 &&
                    showState.map(item => (
                      <Option key={item} value={item}>
                        {item}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </>
          )}

          <CustomButton type="primary" htmlType="submit" text="Filter" size="small" />
        </Form>
      </div>
    </>
  );
};

Filters.propTypes = {
  filterType: PropTypes.string,
  onFilter: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  showState: PropTypes.array,
  showAssetType: PropTypes.array,
};

export default Filters;
